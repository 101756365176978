
<div class="ft-form" *ngIf="!isImporting">
    <div class="content">
        <!-- form title -->
        <div class="ft-form-row">
            <span class="title">{{ "manage_tree.import.lbl_title" | translate}}</span>
        </div>
        <div class="ft-form-row col1-v100">                    
            <div class="ft-form-input">
                <label>{{"lbl_family_name_input" | translate}}</label>
                <!--project list -->
                <input type="text" name="product" list="familyList" (input)="selectProject($event)" [(ngModel)]="familyProjectName" [attr.disabled]="isProjectNameDisabled? true: null"/>
                    <datalist id="familyList">
                        <option *ngFor="let familyProject of familyProjectList"  [ngValue]="familyProject" ng-change="test(familyProject)"> 
                            {{familyProject.projectName}}
                        </option>                    
                    </datalist>  
                <!-- end project list -->             
                <label *ngIf="selectedFamilyProject" class="input-checked-container input-checkbox-large" style="font-weight: normal;color: lightslategrey;font-size: 13px !important;">
                    {{ "manage_tree.import.lbl_title" | translate}}
                    <input class="inputcheck" disabled type="checkbox" checked>
                    <span class="checkmark"></span>
                </label>
            </div>                      
        </div>
        <div class="ft-form-row col1-v100">                    
            <div class="ft-form-input">
                <label>{{"manage_tree.import.lbl_file_type" | translate}}</label>
                <select [(ngModel)]="selectedImportType" (ngModelChange)="fillLastArchive()">
                    <option selected value="" >{{"lbl_select_type_input"|translate}}</option>
                    <option value="GEDCOM">GEDCOM</option>
                </select>
            </div>                      
        </div>
        <div class="ft-form-row col1-v100">                    
            <div class="media-input-container border-outline">
            <div class="upload-container" (dragover)="dragOver($event)" (drop)="filesDropped($event)" >        
                <img src="assets/multimedia-module/Drag.png" class="upload-dropzone-image">
                <span class="label-upload-bold">{{"upload_content.lbl_title" | translate}}</span>
                <span class="label-upload">{{"upload_content.lbl_gedcom" | translate}}</span>        
                <span class="label-uploaded-green">{{uploadFile?.name}}</span>        
                <br>
                <button class="ft-btn ft-btn-active" (click)="clickFileInput()" >{{ "lbl_browse_for_file" | translate}}</button>
                <input #fileInput type="file" [accept] = "acceptMediaExtension"
                (change)="fileUploaded($event);" style="display:none">
            </div>
            <div *ngIf="fileErrorMessage" class="label-error-red" style="margin-bottom: 10px;">
                {{fileErrorMessage}} <br/>
            </div>
            </div>
        </div>

        <!-- <div class="ft-form-input">
            <div class="individual-flag-wrap">
                <label class="input-checked-container input-checkbox-large">{{"lbl_export_with_multimedia" | translate}}
                    <input class="inputcheck" disabled type="checkbox">
                    <span class="checkmark"></span>
                </label>
            </div>
        </div> -->
        <div class="ft-form-row col1-v100" >                    
            <div class="ft-form-input">
                <label>{{logTitle}}</label>
                <app-archive-log-list [item]="selectedFamilyProject?.familyProjectArchiveImport"></app-archive-log-list>  
            </div>                      
        </div>        
    </div>
    <div class="footer">        
        <button [ngClass]="(isFormDirty)? 'ft-btn-inactive active' : 'ft-btn-active-not-selected'" class="ft-btn" [disabled]="!isFormDirty" (click)="import()">{{"manage_tree.import.act_import"|translate}}</button>
        <button class="ft-btn ft-btn-active-not-selected" (click)="cancel()">{{"lbl_cancel" | translate}}</button>
    </div>
</div>

<div class="ft-form" *ngIf="isImporting">
    <div style="display: flex;height: 100%; width: 100%; align-items: center;justify-content: center;flex-direction: column;">
        <div>
            <img src="/assets/family-project/loading.gif"/>
        </div>
        <div *ngIf="isShowImportError" style="text-align: center;">
            <div style="padding: 20px;">{{"manage_tree.import.err_import_error" | translate}}</div>
            <button class="ft-btn ft-btn-inactive active" (click)="continueError()">Continue</button>
        </div>
    </div>    
</div>