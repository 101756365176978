import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  MapReportConfigModel,
  MapThemeList,
} from "src/app/common/models/report/mapreport/map-report-config-model";
import { MapReportService } from "../services";
import { TranslateHandler } from "src/app/common/helpers/translate-handler";
import { MessageDialogService } from "src/app/components/common/message-dialog/services/message-dialog.service";
import { DialogService } from "src/app/services/UI/dialog.service";
import { ProjectRefService } from "src/app/services/project-ref.service";
import { Subscription } from "rxjs";
import { ReportPublishOptions } from "src/app/common/enums/enums";
@Component({
  selector: "app-map-report-tool-bar",
  templateUrl: "./map-report-tool-bar.component.html",
  styleUrls: ["./map-report-tool-bar.component.scss"],
})
export class MapReportToolBarComponent implements OnInit, OnDestroy {
  @Input() mapReportConfigData: MapReportConfigModel;

  public themeList: MapThemeList[] = [];
  public translatedThemeList: MapThemeList[] = [];
  public currentThemeId: number;
  private currentThemeIdSubscription: Subscription

  constructor(
    private mapReportService: MapReportService,
    private translateHandler: TranslateHandler,
    protected messageDialogService: MessageDialogService,
    private dialogService     : DialogService,
    private projectref : ProjectRefService
  ) {
    this.currentThemeIdSubscription = this.mapReportService.currentThemeId$.subscribe(data => {
      this.currentThemeId = data;
    })
  }
  ngOnDestroy(): void {
    this.currentThemeIdSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.initToolbar();
  }

  async initToolbar() {
    this.themeList = [...this.mapReportConfigData.mapThemeConfig.mapThemeList];
    this.translatedThemeList = [];
    for (const theme of this.themeList) {
      /** 
        Wait for the translation to complete before pushing the 
        translated theme into the translatedThemeList array
      */
      const translatedName = await this.translateHandler.translate(
        "mapReport.themes." + theme.name
      );
      this.translatedThemeList.push({ ...theme, name: translatedName });
    }
  }

  // Updates the selected theme and notifies the MapReportService
  selectTheme(themeId: number): void {
    if (themeId !== this.currentThemeId) {
      // Finds the theme object with the given ID
      let theme = this.mapReportConfigData.mapThemeConfig.mapThemeList?.find(
        (theme) => theme.id === themeId
      );
      this.mapReportService.changeTheme(theme?.id);
    }
  }

  changeZoomMode(zoomMode: string): void {
    this.mapReportService.setZoomMode(zoomMode)
  }

  showNotImplementedMessage() {//temporally added til report publisher complete
    this.messageDialogService.openWarn("Informajon", "Dette verktøyet er fortsatt under utvikling. Sjekk tilbake noe senere da vi oppgraderer programmet med jevne mellomrom.", null);
  }

  publishMapReport(): void {
    let title =  this.translateHandler.translate("lbl_mapreport").toString().concat(" ", this.translateHandler.translate("lbl_of")," ", this.projectref.getCurrentProject().projectName.toString());
    let popupInput = { popUpTitle : 'Publish Report', reportTitle : title, optionsList : [ReportPublishOptions.Title,ReportPublishOptions.Template] }
    this.dialogService.setHalf().open("ReportPublishOption", popupInput).subscribe(response => {
        if(response.data) {
          this.mapReportService.setPublishData(response.data);
        }
    });
  }
}
