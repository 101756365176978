import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { MembershipService } from 'src/app/services/membership.service';

@Component({
  selector: 'app-expire-message',
  templateUrl: './expire-message.component.html',
  styleUrls: ['./expire-message.component.scss']
})
export class ExpireMessageComponent implements OnInit {

  customerID!: string;
  isInExpireRange: boolean = false;
  panelTitle!: string;
  panelMessage!: string;

  constructor(private authenticationService: AuthenticationService, private translate: TranslateService, private membershipService: MembershipService) {
    this.customerID = this.authenticationService.getUser().CustomerId;
  }

  ngOnInit(): void {
    this.setExpireMessage();
  }


  private setExpireMessage(): void {

    const warningRanges = environment.TRIAL_EXPIRE_WARNING_RANGE;
    const sortedValues = Object.entries(warningRanges).sort((a, b) => a[1] - b[1]);
    const remainingDays = this.membershipService.getRemainingDays();
    const validRangeKey = this.getValidValueByRange(remainingDays, sortedValues);

    if (!validRangeKey) return;
    this.isInExpireRange = true;
    this.setExpireMessageText(validRangeKey, remainingDays);

  }

  redirectToWebShop(): void {
    let url = environment.WEB_SHOP_MEMBERSHIP_URL.replace("{0}", this.authenticationService.getUser().CustomerId );
    window.open(url, "_blank");
  }

  redirectWithExpireMessage(): void {
    this.redirectToWebShop();
  }

  getValidValueByRange(expireDateCount: number, valueRanges: Object): string {
    const entries = Object.entries(valueRanges);
    for (const [key, value] of entries) {
      if (expireDateCount <= value[1]) {
        return value[0];
      }
    }
    return null;
  }

  setExpireMessageText(key: string, dayCount: number): void {
    const dayCountText = dayCount.toString();//Consistent Formatting: The dayCount.toString() is stored in a dayCountText variable to avoid repeating the conversion and ensure consistency.

    this.panelTitle = dayCount === 0
        ? this.translate.instant('trial_expire_message.lbl_exp_today')
        : this.translate.instant(`trial_expire_message.${key}.lbl_title`).replace("{days}", dayCountText);
    this.panelMessage = this.translate.instant('trial_expire_message.' + key + '.lbl_prompt').replace("{days}", dayCount.toString());
  }
}
