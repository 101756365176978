<div class="no-record" style="height: 78px;" *ngIf="items.length == 0 || items == null">{{"lbl_no_media" | translate}}</div>
<div class="scrollable-container scroll-col2" *ngIf="items.length > 0">
    <div *ngIf="items.length > 2 && isLeftView">
        <img src="/assets/images/left_arrow.png" class="arrow-width custom-cursor-pointer" (click)="scrollMMLeft()">
    </div>
    <div *ngIf="!isLeftView">
    </div>
    <div class="scrollable1xN" #mediaScroller style="padding: 50px 0px;">
        <div class="item" *ngFor="let valItem of items; let i = index" #mycard>
            <div class="item-container">
                <div class="item-content">
                    <div>
                        <img [src]="valItem.url" (error)='onItemError($event)' width="45" height="45" style="display: block;">
                    </div>
                    <div class="scroll-caption text-ellipsis-2">
                        <span class="caption-text">{{valItem.caption}}</span>
                    </div>
                </div>
                <div class="item-menu">
                    <app-drop-menu  [menu]="valItem.dropMenu"  (action)="clickedAction($event,i)"></app-drop-menu>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="items.length > 2 && isRightView">
        <img src="/assets/images/right_arrow.png" class="arrow-width custom-cursor-pointer" (click)="scrollMMRight()"> 
    </div>
    <div *ngIf="items.length <= 2 && !isRightView">
    </div>
</div>