<div class="tab-container" #eventListContainer>
  <div style="border: 1px solid #ebebea;width: 100%;height: 100%;border-radius: 10px 0px 0px 10px;background-color: #fefffa; ">
    <div class="tabs" >
      <a [class.active]="getViewMode() == 1" (click)="changeViewMode(1)">{{"lbl_event" | translate}}</a>
      <a [class.active]="getViewMode() == 2" (click)="changeViewMode(2)">+ {{"lbl_add_event" | translate}}</a>
    </div>
    <div class="tab-forms">
      <app-event-list *ngIf="viewMode === 1" [timeStamp]="timeStamp" [eventList]="eventList" [data]="data" (editEvent)="openEditorView($event)" (refreshEventList)="refreshEventList($event)" [eventConfig] = "eventConfig"></app-event-list>
      <app-event-form *ngIf="viewMode === 2" 
        [title]="title" 
        [data]="data" 
        [eventList]="eventList"
        (refreshEventList)="refreshEventList($event)" ></app-event-form>
    </div>
  </div>
</div>