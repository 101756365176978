
import { Injectable } from '@angular/core';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { BookReportParam } from 'src/app/common/models/BookReportParam';
import { FamilyApiService } from 'src/app/services/API/family-api.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AnniversaryGreeting {
    rootPersonId : number;
    errorStatusObject: any;
    constructor(
        private notifierService: NotifierV2Service,
        private familyApiService: FamilyApiService) { 
            this.errorStatusObject = this.initializeErrorObject();
        }
    /**
     * ### Method Summary
     This method retrieves and returns the parameters required for generating the book report.

    - **Returns**: An instance of `BookReportParam` containing the necessary parameters for the report generation.
  
    - **Behavior**:
    - Constructs a new `BookReportParam` object.
    - Sets the `rootPersonId` property of the `bookReportParam` object using the current root member ID obtained from `notifierService.getCurrentRootMemberId()`.
    - Returns the constructed `bookReportParam` object.
     */
    public getReportParam(): BookReportParam {
        var bookReportParam = new BookReportParam();
        bookReportParam.rootPersonId = this.notifierService.getCurrentRootMemberId();
        return bookReportParam;
    }

    initializeErrorObject(): any {
        return {
            type: null,
            url:'',
        };
    }
    public canGenerateReport(): Observable<any> {
        this.rootPersonId = Number(this.notifierService.getCurrentRootMemberId());
        this.errorStatusObject.url = `/report/treeeditor/${this.rootPersonId}/true`;
        return new Observable(observer => {
            this.familyApiService.getRootMemberMarriedStatus(this.rootPersonId).subscribe(
                (response: any) => {
                    this.errorStatusObject.type = response.data;
                    observer.next(this.errorStatusObject);
                    observer.complete();
                },
                (error) => {
                    observer.error(error);
                }
            );
        });
    }

}
