import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, ViewChildren,SimpleChanges } from '@angular/core';
import { MediaItem } from '../models/media-item';
import { DropMenuAction } from 'src/app/common/enums/enums';
import { DropMenuItem } from '../../drop-menu/models/DropMenuItem';
import { DropMenu } from '../../drop-menu/models/DropMenu';

@Component({
  selector: 'app-media-carousel',
  templateUrl: './media-carousel.component.html',
  styleUrls: ['./media-carousel.component.scss']
})
export class MediaCarouselComponent implements OnInit {
  dropMenu: DropMenu;
  isCarouselActivate = true;
  leftView=0;
  rightView=0;
  isLeftView = false;
  isRightView = true;

  constructor() { }

  @Input() items: Array<MediaItem> = null;   
  @Output() action = new EventEmitter<{action: DropMenuAction, reference: any, bounds: any}>();

  @ViewChild('mediaScroller') mediaScroller: ElementRef;  
  @ViewChildren('mycard') mycard ;
  errorImg: string = "assets/nonimage-preview/Large-Icons/Broken-Image.png";

  ngOnInit(): void {
  } 

  scrollMMRight(){
    this.leftView--;
    this.rightView++;
    
    var ele = this.mediaScroller.nativeElement;
    this.scrollRight(ele);
  }

  scrollMMLeft(){
    this.leftView++;
    this.rightView--;

    var ele = this.mediaScroller.nativeElement;    
    this.scrollLeft(ele);
  }

  scrollRight( ele: any ){ 
    var width = ele.getBoundingClientRect().width;
    ele.scrollBy(width/2,0);
    this.setVisible();
  }

  scrollLeft( ele: any ){
    var width = ele.getBoundingClientRect().width;
    ele.scrollBy(-width/2,0);
    this.setVisible();
  }

  clickedAction($event,i){
    $event.bounds =  this.mycard.toArray()[i].nativeElement.getBoundingClientRect();
    if($event.action == DropMenuAction.Download){     
      window.open($event.reference.originalMediaUrl, "_blank");
    }

    this.action.emit($event);
  }  

  onItemError(event: any) {
    event.target.src = this.errorImg;
  }
  setVisible() {
    // Define the valid number of images excluding the two that are already visible
    const validImages = this.items.length - 2;

    // Determine the visibility of the left view
    this.isLeftView = this.leftView < 0;

    // Determine the visibility of the right view
    if (this.rightView === validImages) {
        this.isRightView = false;
    } else if (this.rightView === 0 || validImages > this.rightView) {
        this.isRightView = true;
    }
}

  ngOnChanges(changes: SimpleChanges) {
    this.setVisible();
  }
}
