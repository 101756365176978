
import { Injectable } from '@angular/core';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { BookReportParam } from 'src/app/common/models/BookReportParam';

@Injectable({
    providedIn: 'root'
})
export class BirthdayGreeting {
    constructor(
        private notifierService: NotifierV2Service) { }
    /**
     * ### Method Summary
     This method retrieves and returns the parameters required for generating the book report.

    - **Returns**: An instance of `BookReportParam` containing the necessary parameters for the report generation.
  
    - **Behavior**:
    - Constructs a new `BookReportParam` object.
    - Sets the `rootPersonId` property of the `bookReportParam` object using the current root member ID obtained from `notifierService.getCurrentRootMemberId()`.
    - Returns the constructed `bookReportParam` object.
     */
    public getReportParam(): BookReportParam {
        var bookReportParam = new BookReportParam();
        bookReportParam.rootPersonId = this.notifierService.getCurrentRootMemberId();
        return bookReportParam;
    }
}
