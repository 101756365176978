export class ChartNode {
    public id: number;
    public parentId: number;
    public data: any = {};

    constructor(id: number, parentId: number, data: any) {
        this.id = id;
        this.parentId = parentId;
        this.data = data;
    }
}
