import { Component, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { ChartTreeHelper } from './chart-tree-helper';
import { ChartNode } from '../../../../common/models/report/descendantchart/chart-node';
import { BaseComponent } from '../../BaseReport/base/base.component';
import { ReportFont } from '../../common/reportfont';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { IndividualApiService } from 'src/app/services/API/individual-api.service';
import { ChartTreeNode } from '../../../../common/models/report/descendantchart/chart-tree-node';
import { Connector } from '../../../../common/models/report/descendantchart/connector';
import { ChartGraphics } from './chart-graphics';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { MessageDialogService } from 'src/app/components/common/message-dialog/services/message-dialog.service';
import { ChartBox } from '../../../../common/models/report/descendantchart/chart-box';
import { ChartService } from './chart.service';
import { ChartTitlePosition,EditorMode,NotifierEvents } from 'src/app/common/enums/enums';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { Observable, Subscription } from 'rxjs';
import { ReportOptions } from '../../common/reportOptions/reportOptions';
import { ReportFieldOption } from '../../common/reportOptions/reportFieldOption';
import { EditorReferenceInfo } from 'src/app/common/models/editors/editor-reference-info';
@Component({
  selector: "app-descendant",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.scss"],
})
export class ChartComponent extends BaseComponent implements OnInit, OnDestroy {
  reportOptions: ReportOptions;
  expectedUpdates = [NotifierEvents.RootMemberChanged];
  private changeRootMemberSubscription: Subscription;

  constructor(
    private pedigreeHostElement: ElementRef,
    protected translateHandler: TranslateHandler,
    private loadingService : LoadingService,
    protected notifierService: NotifierV2Service,
    protected individualApiService: IndividualApiService,
    protected messageDalogService: MessageDialogService,
    protected dialogService: DialogService,
    private chartService: ChartService
  ) {
    super(
      pedigreeHostElement,
      dialogService,
      notifierService,
      translateHandler,
      messageDalogService,
      individualApiService
    );
  }

  reportURL;
  chartboxes = [];
  chartConnectors: Connector[] = [];
  font: ReportFont;
  defaultDescendantLevels;
  defaultHeight = 0;

  titleSegments;
  reportTitleX: number;
  reportTitleY: number;
  reportTitleAnchor: string;
  reportTitleAlignmentBaseline: string;
  chartTranslate: string;

  ngOnInit(): void {
    this.reportURL = this.commonUrl;
    this.rootMember = this.notifierService.getCurrentRootMember();
    this.showReport = true;
    this.rootMember = this.notifierService.getCurrentRootMember();
    this.defaultDescendantLevels = this.config.options.generations;
    this.initialzeChartReportData(this.defaultDescendantLevels, null);

    this.changeRootMemberSubscription = this.rootMemberChanges$.subscribe((result: boolean) => {
      if (result) {
        this.initialzeChartReportData(this.defaultDescendantLevels, null);
      }
    });
  }

  notify() {
    this.hideTooltip();
    this.rootMember = this.notifierService.getCurrentRootMember();
    this.initialzeChartReportData(this.defaultDescendantLevels, null);
  }

  initReportOptions() {
    this.reportOptions = new ReportOptions();
    this.reportOptions.reportName = "lbl_descendant";
    this.reportOptions.fieldOptions.push(new ReportFieldOption("title", "title", "input", [], this.reportTitle));
    this.reportOptions.fieldOptions.push(new ReportFieldOption("generations", "algorithm", "combobox", [ "2", "3", "4", "5", "6", "7"], this.defaultDescendantLevels));
  }

  onOptionsValueChanged(changedData: ReportOptions) {
    this.mapConfig(changedData);
    this.defaultDescendantLevels = this.config.options.generations;
    this.initialzeChartReportData(this.defaultDescendantLevels, this.config.options.title);
  }

  ngOnDestroy(): void {
    this.hideTooltip();
    if (this.changeRootMemberSubscription) {
      this.changeRootMemberSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  private reportInit(tree): void {

    this.font = new ReportFont();
    this.font.init(this.config.font);
    ChartTreeHelper.init(this.config.levels);
    ChartTreeHelper.calculateNodePositions(tree);

    let nodeList = [];
    this.createNodeList(nodeList, tree, null, 0);

    this.setupSvg(nodeList);
    this.createPositionDataInSVG(nodeList);
    this.chartConnectors = ChartGraphics.getConnectors(nodeList, this.config);
    this.formatReportTitle();
  }

  private initialzeChartReportData(genarations, customTitle = null) {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.chartService.getDescendantsList(this.rootMember.Id, genarations).subscribe((response) => {
      if (response.data.length < 2) {
        this.showError("lbl_error_heading", "descendant_report.err_not_enough_generation_info", "descendant_report.err_not_enough_generation_prompt").subscribe(() => { this.openTreeEditor(); });
        return;
      }
      let chartNodes: ChartNode[] = [];
      response.data.forEach(element => {
        chartNodes.push(new ChartNode(element.id, element.parentId, { name: element.displayName
          , place: element.birthPlace, period: element.birthToDeathRange, gender: element.gender }));
      });
      let root = chartNodes.find(e => e.parentId == 0);
      let rootTreeNode = new ChartTreeNode<ChartNode>(root, null);
      rootTreeNode.children = this.getChildNodes(chartNodes, rootTreeNode);
      this.reportTitle = customTitle == null ? this.translateHandler.translate("descendant_report.lbl_title", [this.rootMember.DisplayName]) : customTitle;
      this.reportInit(rootTreeNode);
      this.reportZoomToExtend();
      this.initReportOptions();
    }, (error) => {
      this.showError("lbl_error_heading", "descendant_report.err_generating_info", "descendant_report.err_generating_prompt").subscribe(() => { this.openTreeEditor(); });
    }).add(() => {
      this.loadingService.hide(processId);
    });
  }

  private createNodeList(nodeList, node: ChartTreeNode<ChartNode>, parent, previousYPos) {
    let posY;

    // calculate y position
    if (node.y === 0) {
      posY = previousYPos;
    } else {
      posY = previousYPos + this.config.levels[node.y].levelDistance + this.config.levels[node.y - 1].h;
    }

    var newNode = { "id": node.item.id, "x": node.x, "y": posY, "level": node.y, "item": node.item, "parent": parent };
    node.children.forEach((child) => {
      this.createNodeList(nodeList, child, newNode, posY);
    });
    nodeList.push(newNode);
  }

  private getChildNodes(sampleData: ChartNode[], parent: ChartTreeNode<ChartNode>): ChartTreeNode<ChartNode>[] {
    const children = sampleData.filter(e => e.parentId == parent.item.id);
    return children.map(e => {
      const node = new ChartTreeNode<ChartNode>(e, parent);
      node.children = this.getChildNodes(sampleData, node);
      return node;
    });
  }

  private setupSvg(nodeList) {
    var minX, minY;
    var maxX, maxY, maxLevel;
    var xvalues = nodeList.map(i => i.x);
    var yvalues = nodeList.map(i => i.y);
    var levels = nodeList.map(i => i.level);
    minX = Math.min(...xvalues);
    minY = Math.min(...yvalues);
    maxX = Math.max(...xvalues);
    maxY = Math.max(...yvalues);
    maxLevel = Math.max(...levels);

    const titleWidth = this.calculateTitleWidth();
    const chartWidth = maxX + this.config.levels[maxLevel].w + this.config.chartMargin.right + this.config.chartMargin.left;

    // Set SVG dimensions
    this.config.svg.w = Math.max(titleWidth, chartWidth);
    this.config.svg.h = maxY + this.config.levels[maxLevel].h + this.config.chartMargin.top + this.config.chartMargin.bottom;

    // Calculate translation to center content, considering left and right margins
    const contentWidth = maxX + this.config.levels[maxLevel].w - minX;
    const translateX = (this.config.svg.w - contentWidth - this.config.chartMargin.left - this.config.chartMargin.right) / 2 + this.config.chartMargin.left;
    const translateY = this.config.chartMargin.top;

    this.chartTranslate = `translate(${translateX}, ${translateY})`;
  }

  private calculateTitleWidth(): number {
    return Math.min(this.config.reportTitle.maxLength, this.font.getLength(this.config.reportTitle.fontSize, this.reportTitle)) + this.config.chartMargin.right + this.config.chartMargin.left;
  }

  private createPositionDataInSVG(nodeList) {
    this.chartboxes = [];
    nodeList.forEach(node => {

      var parentPos = {};
      if (node.parent) {
        parentPos = { x: node.parent.x, y: node.parent.y };
      }
      var element = new ChartBox(node.level, this.reportURL, node.x, node.y, parentPos, this.font, this.config.levels[node.level]);
      var graphicElement = element.generate(node.item);
      if (graphicElement != null) {
        this.chartboxes.push(graphicElement);
      }
    });
  }

  private formatReportTitle() {

    // Align the title

    //Align the title horizontally
    this.reportTitleX = this.getReportTitleX(this.config.reportTitle.horizontalPosition);
    this.reportTitleAnchor = this.getReportTitleAnchor(this.config.reportTitle.horizontalPosition);

    // Align the title vertically
    this.reportTitleY = this.getReportTitleY(this.config.reportTitle.verticalPosition);
    this.reportTitleAlignmentBaseline = this.getReportTitleAlignmentBaseline(this.config.reportTitle.verticalPosition);

    // Split the title into segments  
    let prevYPosition = this.reportTitleY;
    this.titleSegments = this.font
      .getString(
        this.reportTitle,
        this.config.reportTitle.fontSize,
        this.config.reportTitle.maxLength,
        this.config.reportTitle.maxLines,
        true
      )
      .map((segment) => {
        let element = {
          x: this.reportTitleX,
          y: prevYPosition,
          text: segment,
        };
        prevYPosition += this.config.reportTitle.lineSpacing;
        return element;
      });
  }

  private getReportTitleX(horizontalPosition: string): number {
    switch (horizontalPosition) {
      case ChartTitlePosition.CENTER:
        return this.config.svg.w / 2 + this.config.reportTitle.offsetx;
      case ChartTitlePosition.LEFT:
        return this.config.reportTitle.offsetx;
      case ChartTitlePosition.RIGHT:
        return this.config.svg.w + this.config.reportTitle.offsetx;
      default:
        return this.config.reportTitle.offsetx;
    }
  }

  private getReportTitleY(verticalPosition: string): number {
    switch (verticalPosition) {
      case ChartTitlePosition.MIDDLE:
        return this.config.svg.h / 2 + this.config.reportTitle.offsety;
      case ChartTitlePosition.TOP:
        return this.config.reportTitle.offsety;
      case ChartTitlePosition.BOTTOM:
        return this.config.svg.h + this.config.reportTitle.offsety;
      default:
        return this.config.reportTitle.offsety;
    }
  }

  private getReportTitleAnchor(horizontalPosition: string): string {
    switch (horizontalPosition) {
      case ChartTitlePosition.CENTER:
        return ChartTitlePosition.MIDDLE;
      case ChartTitlePosition.LEFT:
        return ChartTitlePosition.START;
      case ChartTitlePosition.RIGHT:
        return ChartTitlePosition.END;
      default:
        return ChartTitlePosition.START;
    }
  }

  private getReportTitleAlignmentBaseline(verticalPosition: string): string {
    switch (verticalPosition) {
      case ChartTitlePosition.MIDDLE:
        return ChartTitlePosition.MIDDLE;
      case ChartTitlePosition.TOP:
        return ChartTitlePosition.HANGING;
      case ChartTitlePosition.BOTTOM:
        return ChartTitlePosition.BASELINE;
      default:
        return ChartTitlePosition.HANGING;
    }
  }

  private showError(title: string, info: string, prompt: string): Observable<any> {
    return this.messageDialogService.openError(
      this.translateHandler.translate(title),
      this.translateHandler.translate(info),
      prompt == null ? null : this.translateHandler.translate(prompt)
    );
  }

  private openTreeEditor(): void {
    let ref: EditorReferenceInfo = {
      id: this.notifierService.getCurrentRootMemberId(),
      isBreadcrumbUpdate: true
    }
    this.notifierService.openEditor(EditorMode.TreeEditor, ref);
  }

}
