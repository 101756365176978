
<div class="ft-form">
    <div class="content">
        <!-- form title -->
        <div class="ft-form-row">
            <span class="title">{{"lbl_export_project_title" | translate}}</span>
        </div>
        <div class="ft-form-row col1-v100">                    
            <div class="ft-form-input">
                <label>{{"lbl_family_name_input" | translate}}</label>
                <select [disabled]="isProjectListEnabled" [(ngModel)]="selectedFamilyProject" (ngModelChange)="fillLastArchive()">
                    <option [selected]="familyProject.familyProjectId==selectedFamilyProject.familyProjectId" *ngFor="let familyProject of familyProjectList"  [ngValue]="familyProject">
                        {{familyProject.projectName}}
                    </option>
                    
                </select>
            </div>                      
        </div>
        <div class="ft-form-row col1-v100">                    
            <div class="ft-form-input">
                <label>{{"lbl_export_file_type" | translate}}</label>
                <select [(ngModel)]="selectedExportType" (ngModelChange)="fillLastArchive()">
                    <option selected value="" >{{"lbl_select_type_input"|translate}}</option>
                    <option value="GEDCOM">GEDCOM</option>
                </select>
            </div>                      
        </div>
        <div class="ft-form-input">
            <div class="individual-flag-wrap">
                <label class="input-checked-container input-checkbox-large">{{"lbl_export_with_multimedia" | translate}}
                    <input class="inputcheck" disabled type="checkbox">
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
        <div class="ft-form-row col1-v100" style="margin-top:20px;">                    
            <div class="ft-form-input">
                <label>{{logTitle}}</label>
            </div>                      
        </div>
        <div>
            <app-archive-log-list [item]="familyArchiveLog"></app-archive-log-list>  
        </div>
    </div>
    <div class="footer">
        <button class="ft-btn" [ngClass]="(isFormDirty)? 'ft-btn-inactive active' : 'ft-btn-active-not-selected'" [disabled]="!isFormDirty" (click)="export()">{{"lbl_export_file"|translate}}</button>
        <button class="ft-btn ft-btn-active-not-selected" (click)="cancel()">{{"lbl_cancel" | translate}}</button>
    </div>
</div>
