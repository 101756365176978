import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable, AsyncSubject, ReplaySubject } from 'rxjs';
import { SessionDataMapper } from '../common/mappers/SessionDataMapper';
import { ParentMessageType } from '../common/models/ParentMessage';
import { UserModel } from '../common/models/UserModel';
import { ParentCommunicatorService } from './parent-communicator.service';
import { ApiBaseService } from './api-base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SessionData } from '../common/models/auth/session-data';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  constructor(
    protected httpClient: HttpClient,
    private parentCommunicator: ParentCommunicatorService,
    private apiBaseService: ApiBaseService,
    private cookieService: CookieService
  ) {
  }

  private mainDomain: string;
  private email: string;
  private user = new UserModel();

  private isRefreshing = false;
  public refreshingToken: Subject<boolean> = new Subject<boolean>();
  public bearerToken: Subject<string> = new Subject<string>();
  public loggedUser: any;
  public userDataSubject : BehaviorSubject<UserModel> = new BehaviorSubject(new UserModel());
  
  //2021.10.14
  private token : string;

  // Old token
  //eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJOTlNUSEEwMDA0IiwiZW1haWwiOiJzdGVpbi5pbmdlQGVtYmxhLm5vIiwianRpIjoiYTkyZDA2MWEtNjFkZC00NWMxLTkyOTUtNWJlNmI0NTU1MTQ1IiwiT25saW5lSWQiOiJOTlNUSEEwMDA0IiwiRmlsZU5hbWUiOiJJUmVzdG9yZV_DmHlzdGVpbiBIYWFsYW5kIHNpdHQgZmFtaWxpZXRyZV93aXRoX211bHRpbWVkaWFfMjAxOTA5MjQiLCJDdXN0b21lcklkIjoiNmM0OTI2MDQtOTAzNy1lMTExLTllOTUtZjRjZTQ2OTk1MWExIiwiUHJvZHVjdHMiOiJPRU1CTEFWODtPRU1CTEFWOTtPRU1CTEFWMTA7VjEwQkFTSUMiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJVc2VyIiwiZXhwIjoxNjgzOTI4ODAwLCJpc3MiOiJlbWJsYS5ubyIsImF1ZCI6ImVtYmxhLm5vIn0.xJQOQ7aL9DzLf26UqZ5u3jdhe3mZa_jxB_fyeVbDclM
  public init(){
    //TODO: refactor and do the backward compatabillity
    // Backward compatibility - remove old token    
    if( this.getCookieToken() && !this.getJwtTokenObject(this.getCookieToken()).EmailAddress ){
      this.removeCookieToken();
    };
    //Load the local token
    this.token = this.getCookieToken();    
  }

  public getToken(): string {  
    return this.token;
  }

  // Only use this method to set the token/Have to update the local token too
  // public setAuthenticated(token:string){
  //   this.token = token;
  //   this.setCookieToken(token);    // Save for later 
  // }

  //---------------section old token mechanism-----------------------

  public authenticateUser1(encEmail:string): Observable<any> {
    var user = new UserModel();
    user.EncryptedEmail = encEmail;    
    return this.apiBaseService.create<any>('/api/token', null, user);
  }

  // Check if authenticated : user token in local storage
  public isAuthenticated1(encEmail:string) : boolean {
    let jwtToken = this.token;
    if ((jwtToken !== undefined && jwtToken !== null && jwtToken.length > 0)) {
      return this.isTokenValid(encEmail, jwtToken);
    }
    else {
      return false;
    }
  }
  

  /**
   *  Local storage manipulation
   */
  // Get local storage token
  public getCookieToken(): string {    
    return this.cookieService.get('token');
  }

  // Set local storage token
  public setCookieToken(token:string) {   
    this.cookieService.set('token',token); 
  }

  public removeCookieToken() { 
    this.cookieService.delete('token');
    this.cookieService.delete('token','/',environment.DOMAIN);
  }

  //TODO: streamline user object - encapsulation
  public getUser():UserModel{
    let u:UserModel = new UserModel();
    if ( !this.token) return u;

    const tokenObject = this.getJwtTokenObject(this.token);    
    u.FirstName     = tokenObject.FirstName;
    u.LastName      = tokenObject.LastName;
    u.OnlineId      = tokenObject.OnlineId;
    u.CustomerId    = tokenObject.CustomerId;  
    u.IsFirstTime   = tokenObject.V10LastAccessed ? false : true; //TODO: change the name of the property V10LastAccessed
    this.userDataSubject.next(u);
    return u;
  }
  /**
   *  Jwt util fuctions
   */

  // Check if token valid
  private isTokenValid(encEmail:string, token: string) {    
    const tokenObject = this.getJwtTokenObject(token);
    console.log(tokenObject)
    const expiry = tokenObject.exp;
    // is expired ?
    if ((Math.floor((new Date).getTime() / 1000)) > expiry){
      return false;
    };
    // does it belong to the correct user?
    if( encEmail != tokenObject.EncEmail){
      return false;
    }
    return true;
  }

  // Convert token to token Obj
  public getJwtTokenObject(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));    
    return JSON.parse(JSON.parse(jsonPayload)?.CustomerDetails);
  }

  public logout(){
    this.token = "";
    this.removeCookieToken();        
  }

  public getMembershipExpiryDate(): Date{
    return this.getJwtTokenObject(this.token).MembershipExpiryDate;
  }
}


