import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TreeApiService } from 'src/app/services/API/tree-api.service';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  constructor(private treeApiService: TreeApiService) {
  }

  getDescendantsList (memberId, generations): Observable<any> {
    return this.treeApiService.getDescendantList(memberId, generations);
  }

}
