import { Injectable } from '@angular/core';
import { PedigreeBox } from 'src/app/components/reports/pedigreechart/pedigree/pedigree-box';
import { EditorMode } from '../enums/enums';

@Injectable({
    providedIn: 'root'
})

export class AppConstatnts {

    public static routers = [
        { mode:"TreeEditor", url: '/report/treeeditor',translate:'menu.resources.lbl_tree_editor' },
        { mode:"IndividualEditor", url: '/editor/individualeditor',translate:'menu.resources.lbl_member_editor' },
        { mode:"FamilyEditor", url: '/editor/familyeditor',translate:'menu.resources.lbl_family_editor' },
        { mode:"Multimedia", url: '/commomList/multimedia',translate:'menu.resources.lbl_media_list' },
        { mode:"Individuals", url: '/commomList/individuals',translate:'menu.resources.lbl_member_list' },
        { mode:"Place", url: '/commomList/place',translate:'menu.resources.lbl_place_list' },
        { mode:"Source", url: '/commomList/source',translate:'menu.resources.lbl_source_list' },
        { mode:"Pedigree", url: '/reportengine/pedigree',translate:'menu.reports.lbl_pedegree_chart' },
        { mode:"pedigreeviking", url: '/reportengine/pedigreeviking',translate:'menu.reports.lbl_pedegree_chart' },
        { mode:"Descendant", url:'/reportengine/descendant',translate:'menu.reports.lbl_descendant_chart' },
        { mode:"fanchart", url: '/reportengine/fanchart',translate:'menu.reports.lbl_fanchart' },
        { mode:"fanchartjs", url: '/reportengine/fanchartjs',translate:'menu.reports.lbl_fanchart' },
        { mode:"artistictree", url: '/reportengine/artistictree',translate:'menu.reports.lbl_artistictree' },
        { mode:"sunchart", url: '/reportengine/sunchart',translate:'menu.reports.lbl_sun_chart' },
        { mode:"Home", url: '/home',translate:'menu.lbl_home' },
        { mode:"reportmanager", url : '/reportmanager',translate:'menu.reports.lbl_report_manager' },
        { mode:"familyprojectmanager", url:'/familyprojectmanager',translate:'lbl_manage_trees' },
        { mode:"dashboard", url:'/dashboard',translate:'dashboard.lbl_title' },
        { mode:"PersonReport", url:'/bookreportengine/PersonReport',translate:'menu.reports.lbl_person_report' },
        { mode:"EmptyMemberSheet", url:'/bookreportengine/EmptyMemberSheet',translate:'menu.reports.lbl_empty_member_sheet' },
        { mode:"BirthdayGreeting", url:'/bookreportengine/BirthdayGreeting',translate:'menu.reports.lbl_birthday_greeting' },
        { mode:"AnniversaryGreeting", url:'/bookreportengine/AnniversaryGreeting',translate:'menu.reports.lbl_anniversary_greeting' },
        { mode:"mapReport", url:'/mapreport',translate:'menu.reports.lbl_map_report' },
        { mode:"timelinereport", url:'/reportengine/timelinereport',translate:'menu.reports.lbl_timeline_report' },
        { mode:"timelinereportvine", url:'/reportengine/timelinereportvine',translate:'menu.reports.lbl_timeline_report' }

    ]

    public static routerModes = {
        TreeEditor: '/report/treeeditor',
        IndividualEditor: '/editor/individualeditor',
        FamilyEditor: '/editor/familyeditor',
        Multimedia: '/commomList/multimedia',
        Individuals: '/commomList/individuals',
        Place: '/commomList/place',
        Source: '/commomList/source',
        Pedigree: '/reportengine/pedigree',
        pedigreeviking: '/reportengine/pedigreeviking',
        Descendant: '/reportengine/descendant',
        fanchart: '/reportengine/fanchart',
        fanchartjs: '/reportengine/fanchartjs',
        sunchart: '/reportengine/sunchart',
        artistictree: '/reportengine/artistictree',
        Home: '/home',
        reportmanager : '/reportmanager',
        familyprojectmanager: '/familyprojectmanager',
        PersonReport: 'bookreportengine/PersonReport',
        EmptyMemberSheet: 'bookreportengine/EmptyMemberSheet',
        BirthdayGreeting: 'bookreportengine/BirthdayGreeting',
        AnniversaryGreeting: 'bookreportengine/AnniversaryGreeting',
        mapReport: '/mapreport',
        timelinereport: '/reportengine/timelinereport',
        timelinereportvine: '/reportengine/timelinereportvine'
    }

    public static editorURLs = [ {mode:EditorMode.TreeEditor, url:'/report/treeeditor'},
                                 {mode:EditorMode.IndividualEditor, url:'/editor/individualeditor'},
                                 {mode:EditorMode.FamilyEditor, url:'/editor/familyeditor'}]
    
}