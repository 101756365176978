import { ChartTreeNode } from '../../../../common/models/report/descendantchart/chart-tree-node';

export class ChartTreeHelper {

    private static levelConfig;
    static init(levelConfig) {
        this.levelConfig = levelConfig;
    }

    public static calculateNodePositions<T>(rootNode: ChartTreeNode<T>) {
        // initialize node x, y, and mod values
        this.initializeNodes(rootNode, 0);

        // assign initial X and Mod values for nodes
        this.calculateInitialX(rootNode);

        // ensure no node is being drawn off screen
        this.checkAllChildrenOnScreen(rootNode);

        // assign final X values to nodes
        this.calculateFinalPositions(rootNode, 0);
    }

    //#region private methods

    private static initializeNodes<T>(node: ChartTreeNode<T>, depth: number) {
        node.x = -1;
        node.y = depth;
        node.mod = 0;

        node.children.forEach(child => {
            this.initializeNodes(child, depth + 1);
        });
    }

    private static calculateInitialX<T>(node: ChartTreeNode<T>) {
        node.children.forEach(child => {
            this.calculateInitialX(child);
        });

        if (node.isLeaf()) {
            this.handleLeafNode(node);
        } else if (node.children.length == 1) {
            this.handleSingleNode(node);
        } else {
            this.handleMultipleNodes(node);
        }

        if (node.children.length > 0 && !node.isLeftMost()) {
            this.checkForConflicts(node);
        }
    }

    private static handleLeafNode<T>(node: ChartTreeNode<T>) {
        if (!node.isLeftMost()) {
            node.x = node.getPreviousSibling().x + this.levelConfig[node.y].nodeSize + this.levelConfig[node.y].siblingDistance;
        } else {
            node.x = 0;
        }
    }

    private static handleSingleNode<T>(node: ChartTreeNode<T>) {
        if (node.isLeftMost()) {
            node.x = node.children[0].x;
        } else {
            node.x = node.getPreviousSibling().x + this.levelConfig[node.y].nodeSize + this.levelConfig[node.y].siblingDistance;
            node.mod = node.x - node.children[0].x;
        }
    }

    private static handleMultipleNodes<T>(node: ChartTreeNode<T>) {
        const leftChild = node.getLeftMostChild();
        const rightChild = node.getRightMostChild();
        const mid = (leftChild.x + rightChild.x) / 2;

        if (node.isLeftMost()) {
            node.x = mid;
        } else {
            node.x = node.getPreviousSibling().x + this.levelConfig[node.y].nodeSize + this.levelConfig[node.y].siblingDistance;
            node.mod = node.x - mid;
        }
    }

    private static checkForConflicts<T>(node: ChartTreeNode<T>): void {
        const minDistance = this.levelConfig[node.y].treeDistance + this.levelConfig[node.y].nodeSize;
        let shiftValue = 0;

        const nodeContour = [];
        this.getLeftContour(node, 0, nodeContour);

        let sibling = node.getLeftMostSibling();
        while (sibling != null && sibling != node) {
            let siblingContour = [];
            this.getRightContour(sibling, 0, siblingContour);

            let siblingKeys = Array.from(Object.keys(siblingContour)).map(key => parseInt(key));
            let nodeKeys = Array.from(Object.keys(nodeContour)).map(key => parseInt(key));

            for (let level = node.y + 1; level <= Math.min(Math.max(...siblingKeys), Math.max(...nodeKeys)); level++) {
                let distance = nodeContour[level] - siblingContour[level];
                if (distance + shiftValue < minDistance) {
                    shiftValue = minDistance - distance;
                }
            }

            if (shiftValue > 0) {
                node.x += shiftValue;
                node.mod += shiftValue;
                this.centerNodesBetween(node, sibling);
                this.updateContours(node, nodeContour, shiftValue);
                shiftValue = 0;
            }

            sibling = sibling.getNextSibling();
        }
    }

    private static updateContours<T>(node: ChartTreeNode<T>, contour: number[], shiftValue: number) {
        for (let level = node.y; level < contour.length; level++) {
            contour[level] += shiftValue;
        }
    }

    private static centerNodesBetween<T>(leftNode: ChartTreeNode<T>, rightNode: ChartTreeNode<T>): void {
        const leftIndex = leftNode.parent.children.indexOf(leftNode);
        const rightIndex = leftNode.parent.children.indexOf(rightNode);
        const numNodesBetween = rightIndex - leftIndex - 1;

        if (numNodesBetween > 0) {
            const distanceBetweenNodes = (leftNode.x - rightNode.x) / (numNodesBetween + 1);
            let count = 1;
            for (let i = leftIndex + 1; i < rightIndex; i++) {
                const middleNode = leftNode.parent.children[i];
                const desiredX = rightNode.x + (distanceBetweenNodes * count);
                const offset = desiredX - middleNode.x;
                middleNode.x += offset;
                middleNode.mod += offset;
                count++;
            }
            this.checkForConflicts(leftNode);
        }
    }

    private static checkAllChildrenOnScreen<T>(node: ChartTreeNode<T>): void {
        const nodeContour = [];
        this.getLeftContour(node, 0, nodeContour);

        let shiftAmount = 0;
        for (let y in nodeContour) {
            if (nodeContour[y] + shiftAmount < 0) {
                shiftAmount = (nodeContour[y] * -1);
            }
        }

        if (shiftAmount > 0) {
            node.x += shiftAmount;
            node.mod += shiftAmount;
        }
    }

    private static getLeftContour<T>(node: ChartTreeNode<T>, modSum: number, values: number[]) {
        if (values[node.y] === undefined) {
            values[node.y] = node.x + modSum;
        } else {
            values[node.y] = Math.min(values[node.y], node.x + modSum);
        }

        modSum += node.mod;
        node.children.forEach(child => {
            this.getLeftContour(child, modSum, values);
        });
    }

    private static getRightContour<T>(node: ChartTreeNode<T>, modSum: number, values: number[]) {
        if (values[node.y] === undefined) {
            values[node.y] = node.x + modSum;
        } else {
            values[node.y] = Math.max(values[node.y], node.x + modSum);
        }

        modSum += node.mod;
        node.children.forEach(child => {
            this.getRightContour(child, modSum, values);
        });
    }

    private static calculateFinalPositions<T>(node: ChartTreeNode<T>, modSum: number) {
        node.x += modSum;
        modSum += node.mod;

        node.children.forEach(child => {
            this.calculateFinalPositions(child, modSum);
        });
    }
    //#endregion
}
