import { Component, OnDestroy, OnInit } from '@angular/core';
import { DropMenuAction, EditorMode, FamilyArchiveStatus, OriginalSourceType } from 'src/app/common/enums/enums';
import { DropMenu } from '../../common/drop-menu/models/DropMenu';
import { DropMenuItem } from '../../common/drop-menu/models/DropMenuItem';
import { MediaGalleryItem } from '../../common/media-gallery/models/media-gallery-item';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { FamilyProjectBaseModel } from 'src/app/common/models/familyproject/family-project-base-model';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { Router } from '@angular/router';
import { FamilyProjectApiService } from 'src/app/services/API/family-project-api.service';
import { MessageDialogService } from '../../common/message-dialog/services/message-dialog.service';
import { TimeHelper } from 'src/app/common/helpers/time-helper';
import { environment } from 'src/environments/environment';
import { interval, Subscription } from 'rxjs';
import { ProjectRefService } from 'src/app/services/project-ref.service';
import { EditorReferenceInfo } from 'src/app/common/models/editors/editor-reference-info';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { AppConstatnts } from 'src/app/common/constants/app-contstants';
@Component({
  selector: 'app-family-project-main',
  templateUrl: './family-project-main.component.html',
  styleUrls: ['./family-project-main.component.scss']
})
export class FamilyProjectMainComponent implements OnInit, OnDestroy {
  dropMenu: DropMenu;
  familyProjects: FamilyProjectBaseModel[] = [];
  famproj: FamilyProjectBaseModel = new FamilyProjectBaseModel();
  titleoption: any;
  apiUrlCore = environment.API_URL_CORE;

  constructor(private loadingService: LoadingService,
    private dialogService: DialogService,
    private router: Router,
    private familyProjectService: FamilyProjectApiService,
    private translateHandler: TranslateHandler,
    private timeHelper: TimeHelper,
    private messageDialogService: MessageDialogService,
    private projectRefService : ProjectRefService,
    private notifierService       : NotifierV2Service) { }

  mediaListItems: MediaGalleryItem[] = [];
  private familyListSubscription: Subscription;

  ngOnInit(): void {
    this.showFamilyList();
    // Auto refresh function
    this.familyListSubscription = interval(environment.FAMILY_PROJECT_RELOAD_TIMEOUT).subscribe((val) => {
      this.fillFamilyProjectList(null);
    });
  }

  ngOnDestroy(): void {
    if (this.familyListSubscription) {
      this.familyListSubscription.unsubscribe();
    }
  }

  showFamilyList() {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.fillFamilyProjectList(processId);
  }

  fillFamilyProjectList(processId: string) {
    this.familyProjectService.getFamilyProjects().subscribe((response: any) => {
      if (response.data.length != 0) {
        this.familyProjects = response.data;
        this.mediaListItems = this.familyProjects.map(i => ({
          id: 0,
          reference: i.familyProjectId,
          url: '/assets/family-project/' + i.originalSourceType.toLowerCase() + '.svg',
          caption: i.projectName,
          isSelected: i.isDefault,
          isEditEnable: true,
          mediaType: this.getArchiveAction(i).actionType,
          isDisabled: this.getArchiveAction(i).isProcessing,
          type: this.getOriginalSourceType(i.originalSourceType),
          size: this.timeHelper.getDuration(i.lastModifiedDate),

          dropMenu: this.createDropMenu(i)
        }));
      } else {
      }
    }, (err) => {
      throw new CustomError("FamilyProjectMainComponent => getFamilyList() : " + err, 911, false);
    }).add(() => {
      this.loadingService.hide(processId);
    });
  }

  createDropMenu(ref: FamilyProjectBaseModel) {
    let dropMenu = new DropMenu();
    var isDownloadable = this.isDownloadable(ref);
    var isDefault = ref.isDefault;
    var isOptionEnabled = !this.getArchiveAction(ref).isProcessing;
    dropMenu.ref = ref;
    dropMenu.type = (ref == null || ref.isImporting || !isOptionEnabled) ? 0: 1;
    dropMenu.menuItems = [
      <DropMenuItem>{ action: DropMenuAction.ViewFamilyTree, isEnabled: isOptionEnabled, isVisible: true },
      <DropMenuItem>{ action: DropMenuAction.Import, isEnabled: isOptionEnabled, isVisible: true },
      <DropMenuItem>{ action: DropMenuAction.Export, isEnabled: isOptionEnabled, isVisible: true },
      <DropMenuItem>{ action: DropMenuAction.DownloadExportFile, isEnabled: isDownloadable, isVisible: true },
      <DropMenuItem>{ action: DropMenuAction.SetDefaultProject, isEnabled: (isOptionEnabled && !isDefault), isVisible: true },
      <DropMenuItem>{ action: DropMenuAction.ViewProjectDetails, isEnabled: isOptionEnabled, isVisible: true },
      <DropMenuItem>{ action: DropMenuAction.ProjectSettings, isEnabled: isOptionEnabled, isVisible: true },
      <DropMenuItem>{ action: DropMenuAction.Delete, isEnabled: (isOptionEnabled && !isDefault), isVisible: true }
    ];
    return dropMenu;
  }

  dropMenuAction(event: any) {
    if (event.action == DropMenuAction.Delete) {
      this.deleteFamilyProject(event);
    }
    if (event.action == DropMenuAction.Import) {
      this.openAddNewProjectMain(2, [2], event.reference.familyProjectId);
    }
    if (event.action == DropMenuAction.Export) {
      this.openOptionMain(event, 3);
    }
    if (event.action == DropMenuAction.SetDefaultProject) {
      this.setDefaultProject(event);
    }
    if (event.action == DropMenuAction.ViewProjectDetails) {
      this.openOptionMain(event, 1);
    }
    if (event.action == DropMenuAction.ProjectSettings) {
      this.openSettingsMenu(event, 1);
    }
    if (event.action == DropMenuAction.DownloadExportFile) {
      this.downloadLatestGedcomFile(event);
    }
    if (event.action == DropMenuAction.ViewFamilyTree) {
      this.viewFamilyTree(event);
    }
  }

  viewFamilyTree(event:any){
    this.setDefaultProject(event);
    this.projectRefService.load().then(()=>{
      this.openTreeEditor();
  })
  }

  openOptionMain(event: any, viewMode: number) {
    
    let popupInput = {
      viewMode: viewMode,
      FamilyProject: event.reference,
      FamilyProjectList: this.familyProjects,
      isProjectListEnabled: true
    }
    this.dialogService.setHalf().open("FamilyProjectOptionMain", popupInput).subscribe(response => {
      this.router.navigate(['/familyprojectmanager']);
      if (response.data == "success") this.fillFamilyProjectList(null);
    })
  }

  setDefaultProject(event: any) {
    var defaultFamily = {
      DefaultFamilyProjectId: event.reference.familyProjectId
    }
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.familyProjectService.setDefaultFamilyProject(defaultFamily).subscribe((res) => {
      this.fillFamilyProjectList(processId);
      this.projectRefService.load()
    }, (err) => {
      throw new CustomError("FamilyProjectMainComponent => Set default project" + err, 911, false);
    }).add(() => {
      this.loadingService.hide(processId);
    });
  }

  openSettingsMenu(event: any, viewMode: number) {
    let popupInput = {
      FamilyProjectId: (event==null)? '0' : event.reference.familyProjectId,
      ProjectName: (event==null)? "" : event.reference.projectName,
      viewMode: viewMode
    }
    this.dialogService.setHalf().open("PreferenceMain", popupInput).subscribe(res => {
      this.router.navigate(['/familyprojectmanager']);
      if(res?.data=="success" && event.reference.isDefault) this.projectRefService.load()
      console.log(res);
    })
  }
  getArchiveAction(familyProject: FamilyProjectBaseModel) {
    var isProcessing: boolean = false;
    var actionType = 0;

    if (familyProject.familyProjectArchiveImport?.status == FamilyArchiveStatus.Pending || familyProject.familyProjectArchiveImport?.status == FamilyArchiveStatus.Processing) {
      actionType = familyProject.familyProjectArchiveImport?.action;
      isProcessing = true;
      return { isProcessing: isProcessing, actionType: actionType };
    }
    if (familyProject.familyProjectArchiveExport?.status == FamilyArchiveStatus.Pending || familyProject.familyProjectArchiveExport?.status == FamilyArchiveStatus.Processing) {
      actionType = familyProject.familyProjectArchiveExport?.action;
      isProcessing = true;
      return { isProcessing: isProcessing, actionType: actionType };
    }
    return { isProcessing: isProcessing, actionType: actionType };
  }

  isDownloadable(familyProject: FamilyProjectBaseModel) {
    var isDownloadable: boolean = false;
    if (familyProject.familyProjectArchiveExport?.status == FamilyArchiveStatus.Success) {
      isDownloadable = true;
    }
    return isDownloadable;
  }

  getOriginalSourceType(originalSourceType: string) {
    if (originalSourceType == OriginalSourceType.GEDCOM) {
      return this.translateHandler.translate("lbl_GEDCOM");
    } 
    if (originalSourceType == OriginalSourceType.FT9) {
      return this.translateHandler.translate("lbl_version") + " 9";
    } 
    if (originalSourceType = OriginalSourceType.FT10) {
      return this.translateHandler.translate("lbl_web");
    }
  }

  deleteFamilyProject(event: any) {
    var message = this.translateHandler.translate("cnf_delete_familyProject_confirmation_message");
    this.messageDialogService.openDeleteConfirmation(message).subscribe((res: boolean) => {
      if (res) {
        let processId = GuidGenerator.generate();
        this.loadingService.show(processId);
        this.familyProjectService.deleteFamilyProject(event.reference.familyProjectId).subscribe((response) => {
        }, (err) => {
          throw new CustomError("FamilyProjectMainComponent => dropMenuAction.Delete" + err, 911, false);
        }).add(() => {
          this.loadingService.hide(processId);
          this.fillFamilyProjectList(null);
          this.projectRefService.load()
        });
      }
    });
  }

  openAddNewProjectMain(viewMode, showModes, selectedProjectId =null) {
    
    let popupInput = {
      viewMode: viewMode,
      showMode: showModes,
      selectedProjectId : selectedProjectId,
      FamilyProjectList: this.familyProjects,
    }
    this.dialogService.setHalf().open("AddNewProjectMain", popupInput).subscribe(response => {
      this.router.navigate(['/familyprojectmanager']);

      if (response?.data != null){ //data = null when the user cancel the popup without any change
        this.projectRefService.load().then(()=>{
          this.fillFamilyProjectList(null);
          if(response?.data =="success" || response?.data =="import_success" ){ // New tree or import success
            this.openTreeEditor()
          }
        })
      }
});
  }

  downloadLatestGedcomFile(event: any) {
    let Url: string;
    let archiveId = event.reference.familyProjectArchiveExport.familyProjectArchiveId ?? null;
    Url = this.apiUrlCore + "/api/familyProjectArchive/" + archiveId + "/file";
    window.open(Url, "_blank");
  }

  openTreeEditor(){
    let ref:EditorReferenceInfo = {
      id : this.notifierService.getCurrentRootMemberId(),      
      isBreadcrumbUpdate: true
    }
    this.notifierService.openEditor(EditorMode.TreeEditor, ref);
  }
}
