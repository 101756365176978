import { Connector } from 'src/app/common/models/report/descendantchart/connector';
import { ReportUtil } from '../../common/reportutil';
import { ConnectorFactory } from './connector-factory';
import { ReportFont } from '../../common/reportfont';

export class ChartGraphics {

    constructor() {
    }

    public static getBoxImage(baseX, baseY, baseURL, opacity, boxConfig) {
        if (ReportUtil.isEmptyObject(boxConfig)) {
            return null;
        }
        var offsetx = boxConfig.offsetx ? boxConfig.offsetx : 0;
        var offsety = boxConfig.offsety ? boxConfig.offsety : 0;
        var w = boxConfig.w ? boxConfig.w : 0;
        var h = boxConfig.h ? boxConfig.h : 0;
        return { "x": baseX + offsetx, "y": baseY + offsety, "url": baseURL + "/images/" + boxConfig.image, "opacity": opacity, "w": w, "h": h }
    }

    public static getText(baseX, baseY, text, nooflines, font: ReportFont, opacity, textConfig) {

        if (ReportUtil.isEmptyObject(textConfig)) {
            return null;
        }
        if (text == null) {
            return null;
        }
        
        var textWords = font.getString(text, textConfig.fontSize, textConfig.length, nooflines, true);
        var posX = baseX + textConfig.offsetx;
        var posY = baseY + textConfig.offsety;
        var rotateAng = textConfig.rotate;

        var transform = `translate(${posX},${posY}) rotate(${rotateAng})`;
        return { "transform": transform, "style": textConfig.style, "text": textWords[0], "textRemaining": textWords.slice(1), "opacity": opacity };
    }

    public static getCircleMaskImage(baseX, baseY, url, gender, imageConfig, baseURL) {
        if (ReportUtil.isEmptyObject(imageConfig)) {
            return null;
        }
        var imageURL = url;
        if (!imageURL || imageURL == null) {
            imageURL = baseURL + "/images/" + gender + ".png";
        }
        return { "center": { "x": baseX + imageConfig.offsetx, "y": baseY + imageConfig.offsety }, "r": imageConfig.r, "url": imageURL };
    }

    public static getConnectors(nodeList, configs): Connector[] {
        var connectors: Connector[] = [];
        nodeList.forEach(node => {

            // draw line to parent
            if (node.parent != null) {
                let startX = node.x + (configs.levels[node.level].w / 2);
                let startY = node.y;
                let x1 = node.parent.x + (configs.levels[node.parent.level].w / 2);
                let y1 = startY - configs.levels[node.level].levelDistance;
                connectors.push(ConnectorFactory.createConnector(configs.connectorType, startX, startY, [{ x: x1, y: y1 }]));
            }
        });
        return connectors;
    }
}
