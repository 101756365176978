<div class="left-panel-container">
  
  <!-- Left panel content -->
  <div class="left-panel-content" [style.display]="getVisible()" >

    <!-- heading -->
    <div class="left-panel-heading">
      {{"lbl_family_details_of" | translate}} <br/>
      {{title}}
    </div>
    <!-- content controller -->
    <div class="left-panel-scroll border-outline" >

      <!-- married families heading -->
      <div class="family-heading" >
        <div class="label-l-grey-bold" style="cursor: pointer;" (click)="isSpousePanelExpanded = !isSpousePanelExpanded">{{"lbl_relationships" | translate}}</div>
        <img *ngIf="marriedFamilies.length > 0 && isSpousePanelExpanded" src="/assets/images/icons/arrow-down-grey.svg" class="custom-cursor-pointer" (click)="isSpousePanelExpanded = !isSpousePanelExpanded">
        <img *ngIf="marriedFamilies.length > 0 && !isSpousePanelExpanded" src="/assets/images/icons/arrow-up-grey.svg" class="custom-cursor-pointer" (click)="isSpousePanelExpanded = !isSpousePanelExpanded">
      </div>
      
      <!-- married family list -->  
      <div *ngIf="isSpousePanelExpanded">
        <!-- wife 1 family -->
          <div *ngFor="let mfamily of marriedFamilies" style="margin-left: 10px;">  
            <div class="family-container justify-content-between">
              <!-- <img src="/assets/images/defaults/female.svg" class="profile-image"> -->
              <img src="/assets/images/icons/family.svg" (click)="changeRootFamily(mfamily)" >
                  
              <div class="profile-container line-gradient-bottom">
                <span (click)="changeRootMember(mfamily.spouse)" class="text-ellipsis-2" [ngClass]="'label-l-'+mfamily.spouse.gender">{{mfamily.spouse.displayName}}</span>
                <div class="label-s-relationship">({{mfamily.spouse.relationToRootMember}}) </div>  
              </div>
              <div style="position: relative;margin-left: auto;">
                <app-drop-menu [menu]="getSpouseFamilyDropMenu(mfamily)" (action)="setAsDefultfamily(mfamily)"></app-drop-menu>
              </div>
            </div>        
            <!-- child1 -->
            <div *ngFor="let childfamily of mfamily.children" style="margin-left:30px;margin-bottom: 15px;">
              <div class="profile-container-level1" *ngIf="childfamily.individual.show">
                <div class="info-container">
                  <span (click)="changeRootMember(childfamily.individual)" class="text-ellipsis-2" [ngClass]="'label-m-'+childfamily.individual.gender">{{childfamily.individual.displayName}}</span>
                  <div class="label-s-relationship">({{childfamily.individual.relationToRootMember}})</div>
                </div>
              </div>
              <!-- child1 wife -->
              <div>
                <div class="profile-container-level1" *ngIf="childfamily.spouse != undefined" >
                  <img src="/assets/images/icons/family.svg" (click)="changeRootFamily(childfamily)" style="cursor: pointer;">
                  <div id="wife" class="info-container" >                  
                    <span (click)="changeRootMember(childfamily.spouse)" class="text-ellipsis-2" [ngClass]="'label-m-'+childfamily.spouse.gender">{{childfamily.spouse.displayName}}</span>
                    <div class="label-s-relationship">({{childfamily.spouse.relationToRootMember}})</div>
                  </div>
                </div>
                <div *ngFor="let grandChildfamily of childfamily.children" class="info-container" style="padding-left:28px;">
                  <span (click)="changeRootMember(grandChildfamily.individual)" class="text-ellipsis-2" [ngClass]="'label-m-'+grandChildfamily.individual.gender">{{grandChildfamily.individual.displayName}}</span>
                  <div class="label-s-relationship">({{grandChildfamily.individual.relationToRootMember}}) </div>
                </div>  
            </div>
          </div>
        </div>        
      </div>

      <!-- Parents heading -->
      <div class="family-heading heading-top">
        <div class="label-l-grey-bold" style="cursor: pointer;" (click)="isParentPanelExpanded = !isParentPanelExpanded">{{"lbl_parents" | translate}}</div>
        <img *ngIf="parentFamilies.length > 0 && isParentPanelExpanded"  src="/assets/images/icons/arrow-down-grey.svg" class="custom-cursor-pointer" (click)="isParentPanelExpanded = !isParentPanelExpanded">
        <img *ngIf="parentFamilies.length > 0 && !isParentPanelExpanded"  src="/assets/images/icons/arrow-up-grey.svg" class="custom-cursor-pointer" (click)="isParentPanelExpanded = !isParentPanelExpanded">
      </div>
      <!-- parent list -->
      <div *ngIf="isParentPanelExpanded">
        <div *ngFor="let pfamily of parentFamilies" style="margin-left:10px;margin-bottom: 10px;">
          <div class="profile-container-level1" >
            <img src="/assets/images/icons/family.svg" class="profile-image" (click)="changeRootFamily(pfamily)" style="cursor: pointer;">
            <div class="info-container">
              <div (click)="changeRootMember(pfamily.individual)" class="text-ellipsis-2" [ngClass]="'label-l-'+pfamily.individual.gender">{{pfamily.individual.displayName}}</div>
              <div class="label-s-relationship">({{pfamily.individual.relationToRootMember}})</div>
            </div>
            <div style="position: relative;margin-left: auto;" class="custom-dropmenu">
              <app-drop-menu [menu]="getParentFamilyDropMenu(pfamily)"></app-drop-menu>
            </div>
          </div>
          <!-- child1 wife -->
          <div style="margin-left: 30px;" *ngIf="pfamily.spouse != undefined">
            <div id="wife">
              <div (click)="changeRootMember(pfamily.spouse)" class="text-ellipsis-2" [ngClass]="'label-m-'+pfamily.spouse.gender">{{pfamily.spouse.displayName}}</div>
              <div class="label-s-relationship">({{pfamily.spouse.relationToRootMember}})</div>
            </div>
            <div *ngFor="let child of pfamily.children">
              <div (click)="changeRootMember(child.individual)" class="text-ellipsis-2" [ngClass]="'label-s-'+child.individual.gender">{{child.individual.displayName}} 
              <span *ngIf="child.relationshipType > 1">
                ({{child.individual.childRelation}})
              </span>  
              </div>
              <div class="label-s-relationship">({{child.individual.relationToRootMember}})</div>
            </div>  
          </div>                        
        </div> 
      </div>      
    </div>
  </div>
  <!-- Left panel control buttons -->
  <div class="left-panel-control">
    <div class="left-panel-buttons">
      <img class="element-over-hand btn-sider-inner" (click)="toggle()" [hidden]="!isleftPanelVisibale"
        src="assets/tree-editor/left_pane/btn_left_pane_closed.svg" style="display: block;" />
      <img class="element-over-hand btn-sider-outside" (click)="toggle()" [hidden]="isleftPanelVisibale"
        src="assets/tree-editor/left_pane/btn_left_pane_closed.svg" style="display: block;" />
    </div>
  </div>
</div>