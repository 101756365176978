import { StringLimiter } from './../../../common/helpers/string-limiter';
import { Component, OnInit, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { TreeEditorApiService } from '../services/treeeditorapi.service';
import { V10ApiService } from 'src/app/services/v10-api.service';
import { IndividualBaseViewModel } from 'src/app/common/models/IndividualViewModel';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { SessionData } from 'src/app/common/models/SessionData';
import { ParentNodeService } from '../services/parent-node.service';
import { ParentCommunicatorService } from 'src/app/services/parent-communicator.service';
import { ParentMessageType } from 'src/app/common/models/ParentMessage';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RelationShipType, EditorMode } from 'src/app/common/enums/enums';
import { MessageDialogService } from '../../common/message-dialog/services/message-dialog.service';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { IndividualApiService } from 'src/app/services/API/individual-api.service';
import { IndividualRequestModel } from 'src/app/common/models/individual/individual-request-model';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { ComponentBase } from '../../common/base/component-base';
import { RootMember } from '../../../common/models/RootMember';
import { NotifierEvents } from '../../../common/enums/enums';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { ApiBaseService } from 'src/app/services/api-base.service';

@Component({
  selector: 'app-treeeditorview',
  templateUrl: './treeeditorview.component.html',
  styleUrls: ['./treeeditorview.component.scss']
})
export class TreeeditorviewComponent extends ComponentBase implements OnInit {
  fixedLabels: any[] = [];
  repeatTimes = [0, 1, 2, 3];
  limitLength: number = 21;
  expandingWheelX: any[] = [0];
  expandingWheelY: any[] = [0];
  pedegreeData: IndividualBaseViewModel[];
  rootMemberId: number = 1;
  parentObj: SessionData;
  showView: boolean = false;
  expandColor: any = "#f4f9e7";
  subscriptionRootMemberId: any;
  isExpandingWheelOn: boolean = false;
  selectedmemberDetail: any;
  @Input() editedLabelImage: any;
  @Output() public isExpandingWheelActive = new EventEmitter<boolean>();

  //Position control variables for ipad View
  treeEditorXMove: number = 0;
  treeEditorYMove: number = 0;
  switchToIpadView: boolean = false;
  defaultScale: number = 1;
  viewScale: number = 1;
  deviceWidth: number = 0;
  deviceHeight: number = 0;
  tabDevicesWidthLimit = 1025;
  mobileDevicesWidthLimit = 810;
  isMobileDevice: boolean = false;
  individualmanagementSubscription: Subscription;
  pedigreeSubscription: Subscription;
  treeEditorReloadSubscription = new Subscription();
  sessionDataSubcriber = new Subscription();
  numberOfMembersAddedBeforeFresh: number = 0;
  loaderTimeout = environment.RELOAD_FREQUENCE;
  rootMember: any;

  renderLabels: any[] = [];
  timeTag: string;
  @Output() public showMain: EventEmitter<boolean> = new EventEmitter<boolean>();
  componentName = 'TreeEditor';
  expectedUpdates = [ NotifierEvents.RootMemberChanged, 
                      NotifierEvents.RootFamilyUpdated,
                      NotifierEvents.EditorModeChanged
                    ];
  content: any;

  // Detect resolution changes
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.detectDeviceSizes();
    this.setTabView();
  }

  constructor (
    public v10restApi: V10ApiService,
    private treeEditorApiserive: TreeEditorApiService,
    public parentNodeService: ParentNodeService,
    public stringLimit: StringLimiter,
    private parentCommunicatorService: ParentCommunicatorService,
    private loadingService: LoadingService,
    private messageDialogSerivce: MessageDialogService,
    private translateHandler: TranslateHandler,
    private individualApiService: IndividualApiService,
    private apibaseservice : ApiBaseService,
    notifierService: NotifierV2Service
  ) {
    super(notifierService);
    this.fixedLabels = parentNodeService.parentlabelinfo;
    this.parentObj = new SessionData();
  }

  getPedigreeInfo() {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.pedigreeSubscription = this.treeEditorApiserive.getTreePedigree(this.rootMember.Id)
      .subscribe(
        (response: ResponseModel<IndividualBaseViewModel[]>) => {
          this.numberOfMembersAddedBeforeFresh = 0;
          this.pedegreeData = response.data;
          this.generateLabels();          
          //this.stringLimit.update();                 
          this.loadingService.hide(processId);
        },
        error => {
          this.loadingService.hide(processId);
        })
      .add(
        () => {
          this.loadingService.hide(processId);
        });
  }

  ngOnInit(): void {   
    this.numberOfMembersAddedBeforeFresh = 0;
    let selectedMember = this.notifierService.getCurrentRootMemberId(true);

    // Note: This if is used to handle the situation where the angular 9 app is initially loaded and nothing is in the history list.
    // Todo: In the application start (second phase of revamp) this has to be handled in a proper manner.

    if(!selectedMember)
    {
      this.sessionDataSubcriber = this.apibaseservice.sessionDataSubject.subscribe((sessionData) => {          
        if (sessionData) { 
          let dataObj =  JSON.parse(sessionData);   
          this.rootMember = {Id : dataObj.rootMemberId}          
          this.getPedigreeInfo();      
        }
      });
    }
    else {
      this.rootMember = { Id : this.notifierService.getCurrentRootMemberId()};      
      this.getPedigreeInfo();  
    }
      
    this.detectDeviceSizes();
  }

  detectDeviceSizes() {
    var svgMain = document.getElementById('svg-content'); // use host-element -> refactor => this.hostElement.nativeElement
    this.deviceWidth = svgMain.clientWidth;
    this.deviceHeight = svgMain.clientHeight;
  }

  // Expanding wheel functions
  expandWheel(content) {
    // Content is used to close the expand wheel when switching root member
    this.content = content;
    if(this.content.expanded)
      this.content = undefined;

    content.expanded = !content.expanded;
    this.isExpandingWheelOn = !this.isExpandingWheelOn;
    this.isExpandingWheelActive.emit(this.isExpandingWheelOn);
    if (content.expanded) {
      this.expandColor = "#7a7d74";
    } else {
      this.expandColor = "#f4f9e7";
    }
  }

  showFamily(renderLabelIndex) {  
    let immediateFamily = this.renderLabels[renderLabelIndex].parentFamilyId;
    let data = {
      id : immediateFamily,
      editorMode : EditorMode.FamilyEditor,
      individualId : this.renderLabels[renderLabelIndex].parentFamilyId == 0 ? this.renderLabels[renderLabelIndex].id : 0,
      family :{},
      isUpdateHistory: true
    }
    this.notifierService.updateEvent(NotifierEvents.RootFamilyChanged,data)
    this.notifierService.setCurrentEditor("/editor/familyeditor", data, null);
  }

  notify()
  {
    // Content is used to close the expand wheel when switching root member
    if(this.content)
      this.expandWheel(this.content);
    this.rootMember = this.notifierService.getCurrentRootMember();
    // if(this.notifierService.componentName === EditorMode[EditorMode.TreeEditor])
      this.getPedigreeInfo();
  }

  show(data: any)
  {
    if(!this.rootMember)
    {
      this.rootMember = {Id : data.id} as RootMember
    }
    else {
      this.rootMember.Id = data.id;
    }
    this.showMain.emit(true);
  }

  hide()
  {    
    this.showMain.emit(false);
  }

  //Open any editor
  openEditor(id: number, mode: EditorMode) {
    let data = {
      id: id,
      editorMode: mode,
      isUpdateHistory: true
    }
    this.notifierService.setCurrentEditor(`/editor/individualeditor`,  data, null);    
  }

  addParent(memberData) {
    this.selectedmemberDetail = memberData.label;
    var gender = memberData.index % 2 == 0 ? "F" : "M";

    let title = this.translateHandler.translate(memberData.index % 2 == 0 ? 'lbl_mother_of' : 'lbl_father_of', [memberData.label.childDisplayName]);
    let subTitle = this.translateHandler.translate(memberData.index % 2 == 0 ? 'lbl_mothers_name' : 'lbl_fathers_name', [memberData.label.childDisplayName]);

    this.messageDialogSerivce.openIndividualDialog(title, subTitle, memberData.label.childId, 0, 1).subscribe((response: any) => {
      if (response) {
        var individualRequestModel = this.createIndividualRequestModel(gender, response.name, memberData.label.spouseFamilyId, memberData.label.childId, memberData.label.gender);
        this.createVirtualParentNode(individualRequestModel, response);
      }
    },
      (err) => {
        throw new CustomError("TreeeditorviewComponent => addParent() : " + err, 911, false)
      })
      .add();
  }

  createVirtualParentNode(individualRequestModel, response) {
    this.individualApiService.createParent(individualRequestModel).subscribe((member: any) => {
      if (member) {
        if (individualRequestModel.relatedMemberId == this.rootMember.Id) {
          this.rootMember.displayName = this.selectedmemberDetail.childDisplayName;
          this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, this.rootMember);
        }

        // this.numberOfMembersAddedBeforeFresh++;
        // if (this.numberOfMembersAddedBeforeFresh >= this.loaderTimeout) {
        //   this.numberOfMembersAddedBeforeFresh = 0;
          //Reload tree after given no of members added
          this.getPedigreeInfo();
        // }

        // NOTE: The following code is used when show and hide the tree editor
        // Todo: Have to remove this piece of code after confirming the reason and evaluating

        // let childLabelIndex = this.renderLabels.findIndex((obj => obj.id === Number(this.selectedmemberDetail.childId)));
        // this.renderLabels[childLabelIndex].parentFamilyId = member.data.spouseFamilyId;

        // let parentIndexes = this.renderLabels.filter((obj => obj.childId === Number(this.selectedmemberDetail.childId)));
        // parentIndexes.forEach(element => {
        //   element.spouseFamilyId = member.data.spouseFamilyId;
        // });

        // member.data.displayIndex = this.selectedmemberDetail.displayIndex;
        // member.data["fatherDisplayIndex"] = this.getDisplayIndex(this.selectedmemberDetail.displayIndex, 0.0);
        // member.data["motherDisplayIndex"] = this.getDisplayIndex(this.selectedmemberDetail.displayIndex, 0.1);
        // member.data["childId"] = this.selectedmemberDetail.childId;
        // //Adding Member
        // this.getNewRenderObject(member, member.data.displayIndex, false);
        // //Adding Mother
        // this.getNewRenderObject(member, member.data.motherDisplayIndex, true);
        // //Adding Father
        // this.getNewRenderObject(member, member.data.fatherDisplayIndex, true);
      }

      // Navigate to individual editor
      if(response.isMemberNavigate){             
        this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, member.data);
        this.openEditor(member.data.id, EditorMode.IndividualEditor);
      }
    });
  }

  addSibling(index, label) {
    this.expandWheel(label);
    var gender = index == 'Brother' ? "M" : "F";

    let title = this.translateHandler.translate(gender == 'M' ? 'lbl_brother_of' : 'lbl_sister_of', [label.displayName]);
    let subTitle = this.translateHandler.translate(gender == 'M' ? 'lbl_brothers_name' : 'lbl_sisters_name', [label.displayName]);

    this.messageDialogSerivce.openIndividualDialog(title, subTitle, label.childId, 0, 1).subscribe((response: any) => {
      if (response) {
        let individualRequestModel = new IndividualRequestModel();
        individualRequestModel = this.createIndividualRequestModel(gender, response.name, label.parentFamilyId, label.id, label.gender);
        this.individualApiService.createSibling(individualRequestModel).subscribe((sibling: any) => {
          if (label.id == this.rootMember.Id) {
            this.rootMember.displayName = label.displayName
            this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, this.rootMember);
          }
          // Navigate to individual editor
          if(response.isMemberNavigate){            
            this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, sibling.data);
            this.openEditor(sibling.data.id,EditorMode.IndividualEditor);
          }
        });
      }
    },
      (err) => {
        throw new CustomError("TreeeditorviewComponent => addSibling() : " + err, 911, false)
      })
      .add();
  }

  addChild(index, label) {
    this.expandWheel(label);
    var gender = index == 'Son' ? "M" : "F";
    let title = this.translateHandler.translate(gender == 'M' ? 'lbl_son_of' : 'lbl_daughter_of', [label.displayName]);
    let subTitle = this.translateHandler.translate(gender == 'M' ? 'lbl_sons_name' : 'lbl_daughters_name', [label.displayName]);

    this.messageDialogSerivce.openIndividualDialog(title, subTitle, label.spouseFamilyId, label.id, gender == 'M' ? RelationShipType.Son : RelationShipType.Daughter).subscribe((response: any) => {
      if (response) {
        let individualRequestModel = new IndividualRequestModel();
        individualRequestModel = this.createIndividualRequestModel(gender, response.name, response.familyId, label.id, label.gender);
        this.individualApiService.createChild(individualRequestModel).subscribe((child : any) => {
          if (label.id == this.rootMember.Id) {
            this.rootMember.displayName = label.displayName
            this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, this.rootMember);
          }
          // Navigate to individual editor
          if(response.isMemberNavigate){            
            this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, child.data);
            this.openEditor(child.data.id,EditorMode.IndividualEditor);
          }
        });
      }
    },
      (err) => {
        throw new CustomError("TreeeditorviewComponent => addChild() : " + err, 911, false)
      })
      .add();
  }

  addSpouse(label) {
    this.expandWheel(label);
    var gender = label.gender == 'M' ? "F" : "M";
    let title = this.translateHandler.translate('lbl_spouse_of', [label.displayName]);
    let subTitle = this.translateHandler.translate('lbl_spouses_name', [label.displayName]);

    this.messageDialogSerivce.openIndividualDialog(title, subTitle, label.spouseFamilyId, label.id, RelationShipType.Spouse).subscribe((response: any) => {
      if (response) {
        let individualRequestModel = new IndividualRequestModel();
        individualRequestModel = this.createIndividualRequestModel(gender, response.name, response.familyId, label.id, label.gender);
        this.individualApiService.createSpouse(individualRequestModel).subscribe((spouse : any) => {
          if (label.id == this.rootMember.Id) {
            this.rootMember.displayName = label.displayName
            this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, this.rootMember);
          }
           // Navigate to individual editor
           if(response.isMemberNavigate){        
            this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, spouse.data);
            this.openEditor(spouse.data.id,EditorMode.IndividualEditor);
          }
        });
      }
    },
      (err) => {
        throw new CustomError("TreeeditorviewComponent => addSpouse() : " + err, 911, false)
      })
      .add();
  }

  //Create request object for individual create
  createIndividualRequestModel(gender: string, memberName: string, relatedFamilyId: number, relatedMemberId: number, relatedMemberGender: string = null) {
    let individualRequestModel = new IndividualRequestModel();
    individualRequestModel.gender = gender
    individualRequestModel.memberName = memberName;
    individualRequestModel.relatedFamilyId = relatedFamilyId;
    individualRequestModel.relatedMemberId = relatedMemberId;
    individualRequestModel.relatedMemberGender = relatedMemberGender;

    return individualRequestModel;
  }

  getNewRenderObject(member: any, displayIndex: Number, isParent: boolean) {
    let objIndex = this.renderLabels.findIndex((obj => obj.displayIndex === Number(displayIndex)));
    if (objIndex > -1) {
      if (isParent) {
        this.renderLabels[objIndex].childId = member.data.id;
        this.renderLabels[objIndex].status = "disable";
        this.renderLabels[objIndex].parentFamilyId = member.data.parentFamilyId;
        this.renderLabels[objIndex].childDisplayName = member.data.displayName;
      }
      else {
        this.renderLabels[objIndex].displayName = member.data.displayName;
        this.renderLabels[objIndex].firstName = member.data.firstName;
        this.renderLabels[objIndex].lastName = member.data.lastName;
        this.renderLabels[objIndex].gender = member.data.gender;
        this.renderLabels[objIndex].id = member.data.id;
        this.renderLabels[objIndex].profileImage = member.data.profileImage ?? "";
        this.renderLabels[objIndex].status = "active";
        this.renderLabels[objIndex].fatherId = member.data.fatherId;
        this.renderLabels[objIndex].motherId = member.data.motherId;
        this.renderLabels[objIndex].childId = member.data.childId;
        this.renderLabels[objIndex].spouseFamilyID = member.data.spouseFamilyId;
      }
    }
  }

  generateLabels() {
    this.fixedLabels = this.parentNodeService.parentlabelinfo;
    this.renderLabels = [...this.fixedLabels];
    try {
      if (this.pedegreeData.length > 0) {
        let data = this.getDesabledParentElements(this.pedegreeData);
        for (let i = 0; i < this.fixedLabels.length; i++) {
          let showElements = data.find(item => item.displayIndex === this.fixedLabels[i].displayIndex);
          if (showElements) {
            if (!(undefined === (showElements.id)) && showElements.id > 0) {
              this.renderLabels[i] = { ...showElements, ...this.fixedLabels[i] };
              this.renderLabels[i].status = 'active';
            } else {
              this.renderLabels[i].status = 'disable';
              this.renderLabels[i].childId = showElements.childId;
              this.renderLabels[i].parentFamilyId = showElements.parentFamilyId;
              this.renderLabels[i].childDisplayName = showElements.childDisplayName;
              this.renderLabels[i].spouseFamilyId = showElements.spouseFamilyId;
            }
          }
          else {
            this.renderLabels[i].status = 'hide';
          }
        }
        this.showView = true;
      }
    } catch (error) {
      console.error('Tree editor backend data is empty' + error);
      throw error;
    }
  }

  getDesabledParentElements(pedegreeData: IndividualBaseViewModel[]): IndividualBaseViewModel[] {
    let individualBaseViewModelArray: any[] = [];
    for (let i = 0; i < pedegreeData.length; i++) {
      if (pedegreeData[i].fatherId === -1) {
        individualBaseViewModelArray.push({ "displayIndex": this.getDisplayIndex(pedegreeData[i].displayIndex, 0.0), "childId": pedegreeData[i].id, "spouseFamilyId": pedegreeData[i].parentFamilyId, "childDisplayName": pedegreeData[i].displayName });
      }

      if (pedegreeData[i].motherId === -1) {
        individualBaseViewModelArray.push({ "displayIndex": this.getDisplayIndex(pedegreeData[i].displayIndex, 0.1), "childId": pedegreeData[i].id, "spouseFamilyId": pedegreeData[i].parentFamilyId, "childDisplayName": pedegreeData[i].displayName });
      }
    }
    let arrayWithAll = [...pedegreeData, ...individualBaseViewModelArray]
    return arrayWithAll.sort(function (a, b) { return a.displayIndex - b.displayIndex; });
  }

  getDisplayIndex(childDisplayIndex, increment): Number {
    let divideby = 10;
    let newIndex = parseInt(childDisplayIndex) + 1 + (((((childDisplayIndex * 100) % 100) * 2) / 10) / divideby) + (increment / divideby);
    return Number(newIndex.toFixed(2));
  }

  toggleView() {
    this.switchToIpadView = !this.switchToIpadView;
    this.setTabView();
  }

  setTabView() {
    if (this.deviceWidth > this.tabDevicesWidthLimit) {
      this.isMobileDevice = false;
      if (this.switchToIpadView) {
        this.treeEditorXMove = (this.deviceWidth / 10) * 1.5;
        this.treeEditorYMove = 0;
        this.viewScale = 1; // Default size 
      } else {
        this.setDefaultView();
      }
    } if (this.deviceWidth < this.tabDevicesWidthLimit && this.deviceWidth > this.mobileDevicesWidthLimit) {
      this.isMobileDevice = false;
      if (this.switchToIpadView) {
        this.treeEditorXMove = this.deviceWidth / 10 * 1.5;
        this.treeEditorYMove = this.deviceHeight / 10 * -1.6;
        this.viewScale = (this.defaultScale / 100) * 125; // Default size increased up to 125%
      } else {
        this.setDefaultView();
      }
    } if (this.deviceWidth < this.mobileDevicesWidthLimit) {
      this.isMobileDevice = true;
      if (this.switchToIpadView) {
        this.treeEditorXMove = this.deviceWidth / 10 * -0.5;
        this.treeEditorYMove = this.deviceHeight / (-2);
        this.viewScale = (this.defaultScale / 100) * 185; // Default size increased up to 185%
      } else {
        this.setDefaultView();
      }
    }
  }

  // This is the default treeEditor view
  setDefaultView() {
    this.treeEditorXMove = 0;
    this.treeEditorYMove = 0;
    this.viewScale = this.defaultScale;
  }

  showNotImplemented() {
    this.parentCommunicatorService.send(ParentMessageType.ShowInformation, "not_implemented");
  }

  ngOnDestroy(): void {
    if(this.individualmanagementSubscription) {
      this.individualmanagementSubscription.unsubscribe();
    }
    if(this.pedigreeSubscription) {
      this.pedigreeSubscription.unsubscribe();
    }
    if(this.subscriptionRootMemberId) {
      this.subscriptionRootMemberId.unsubscribe();
    }
    if(this.treeEditorReloadSubscription) {
      this.treeEditorReloadSubscription.unsubscribe();
    }

    if(this.sessionDataSubcriber){
      this.sessionDataSubcriber.unsubscribe();
    }

    super.ngOnDestroy();
  }

  refreshTree(reload) {
    if (reload) {
      this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, this.rootMember);
    }
  }
}
