import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { DialogBaseComponent } from '../dialog/dialog-base/dialog-base.component';
import { ReportHelper } from 'src/app/common/helpers/reportManager/report-helper';
import { ReportPublishOptions } from 'src/app/common/enums/enums';

@Component({
  selector: 'app-report-publish-option',
  templateUrl: './report-publish-option.component.html',
  styleUrls: ['./report-publish-option.component.scss']
})
export class ReportPublishOptionComponent extends DialogBaseComponent  implements OnInit {

  templates : Array<any>;
  reportTemplate : any;
  
  reportTitle: string = "";
  optionsList : Array<ReportPublishOptions>
  reportFileName : string = "";
  constructor(dialogService: DialogService,
    ref: ChangeDetectorRef) { 
    super(dialogService, ref);
  }

  ngOnInit(): void {
    this.reportTitle        = this.data?.reportTitle?.trim() ?? "";
    this.optionsList        = this.data?.optionsList;
    this.reportFileName     = this.data?.reportFileName?.trim() ?? "";

    this.templates          = ReportHelper.templates;
    this.reportTemplate   = ReportHelper.templates[0];  
  }

  add(){
    this.dialogSerivce.complete({data : {
      reportTitle : this.reportTitle,
      templateName : this.reportTemplate.reportTemplate,
      reportFileName : this.reportFileName
    }});
  }

  cancel(){
    this.dialogSerivce.complete({data : null});
  }

  setEnablity() {
    // let isEnabledName = (typeof(this.reportTitle) === 'undefined' || this.reportTitle === null || this.reportTitle === '') || this.reportTitle.length > 100 ? true : false;
    let isEnabledName = this.reportTitle.length > 50 ? true : false;
    return (isEnabledName);
  }  

  isOptionVisible(option:string):boolean{
    return this.optionsList.includes(ReportPublishOptions[option])
  }

  isPublishButtonEnabled(): boolean {
    return this.optionsList.every(option => {
      const optionValue = this[`report${ReportPublishOptions[option]}`];
      if (optionValue === undefined) return true;
      return !!optionValue;
    });
  }

}
