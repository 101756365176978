import { ActivatedRouteSnapshot, RouteReuseStrategy } from "@angular/router";
import { BookReportEngineComponent } from "./book-report-engine/book-report-engine.component";

export class NonReusableRouteStrategy  implements RouteReuseStrategy {
    shouldDetach(route: ActivatedRouteSnapshot): boolean { return false; }
    store(route: ActivatedRouteSnapshot, handle: any): void {}
    shouldAttach(route: ActivatedRouteSnapshot): boolean { return false; }
    retrieve(route: ActivatedRouteSnapshot): any { return null; }
  
    shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
        const isBookReportRoute = future.component === BookReportEngineComponent;
        const isSameRouteConfig = future.routeConfig === current.routeConfig;
        return !isBookReportRoute && isSameRouteConfig;
    }
}
