
<ng-template #rt let-r="result" let-t="term">
    <img [src]="'https://upload.wikimedia.org/wikipedia/commons/thumb/' + r['flag']" class="mr-1" style="width: 16px">    
</ng-template>

<!-- Main header -->
<div class="main-header-container">
    <!-- Right menu-->
    <div class="right-menu-container">
        <div class="menu-white"><img src="/assets/images/icons/menu_white.svg" width="22" height="55"></div>
        <div class="logo-container"><img src="/assets/images/logo.png" style="height: 40px;"></div>
        <nav class="nav-container" role="navigation">
            <ol class="main-menu-container">
                <li class="nav-menu-item">
                    <a class="menu-link nav-menu-center" href="#0">{{'menu.lbl_home' | translate}}</a>
                </li>
                <li class="nav-menu-item" >
                    <a [class.disabled]="isDisabled()" class="menu-link nav-menu-center" >{{'menu.reports.lbl_reports' | translate}}<i class="arrow down arrow-white" style="margin-left: 10px;margin-bottom: 3px"></i>
                    </a>
                    <ol class="nav-sub-item" style="z-index: 401;">
                        <li [class.disabled]="isDisabled()" class="nav-menu-item nav-menu-center nav-menu-seperator" (click)="route('reportmanager')">{{'menu.reports.lbl_report_manager' | translate}}</li>
                        <li [class.disabled]="isDisabled()" class="nav-menu-item nav-menu-center" (click)="route('fanchartjs')">{{'menu.reports.lbl_fanchart' | translate}}</li>
                        <li [class.disabled]="isDisabled()" class="nav-menu-item nav-menu-center" (click)="route('fanchart')">{{'menu.reports.lbl_fanchart_leaves' | translate}}</li>
                        <li [class.disabled]="isDisabled()" class="nav-menu-item nav-menu-center" (click)="route('sunchart')">{{'menu.reports.lbl_sun_chart' | translate}}</li>
                        <li [class.disabled]="isDisabled()" class="nav-menu-item nav-menu-center" (click)="route('Pedigree')">{{'menu.reports.lbl_pedegree_chart' | translate}}</li>
                        <li [class.disabled]="isDisabled()" class="nav-menu-item nav-menu-center" (click)="route('Descendant')">{{'menu.reports.lbl_descendant_chart' | translate}}</li>                      
                        <li [class.disabled]="isDisabled()" class="nav-menu-item nav-menu-center" (click)="route('artistictree')">{{'menu.reports.lbl_artistictree' | translate}}</li>     
                        <li [class.disabled]="isDisabled()" class="nav-menu-item nav-menu-center" (click)="route('PersonReport')">{{'menu.reports.lbl_person_report' | translate}}</li>
                        <li [class.disabled]="isDisabled()" class="nav-menu-item nav-menu-center" (click)="route('mapReport')">{{'menu.reports.lbl_map_report' | translate}}</li>
                        <li [class.disabled]="isDisabled()" class="nav-menu-item nav-menu-center" (click)="route('timelinereport')">{{'menu.reports.lbl_timeline_report' | translate}}</li>
                        <li [class.disabled]="isDisabled()" class="nav-menu-item nav-menu-center" (click)="route('timelinereportvine')">{{'menu.reports.lbl_timeline_vine_report' | translate}}</li>
                        <!-- <li [class.disabled]="isDisabled()" class="nav-menu-item nav-menu-center" (click)="route('EmptyMemberSheet')">{{'menu.reports.lbl_empty_member_sheet' | translate}}</li>
                        <li [class.disabled]="isDisabled()" class="nav-menu-item nav-menu-center" (click)="route('BirthdayGreeting')">{{'menu.reports.lbl_birthday_greeting' | translate}}</li> -->
                    </ol>
                </li>
                <li class="nav-menu-item" >
                    <a [class.disabled]="isDisabled()" class="menu-link nav-menu-center">{{'menu.resources.lbl_resources' | translate}}<i class="arrow down arrow-white"  style="margin-left: 10px;margin-bottom: 3px"></i></a>
                    <ol class="nav-sub-item" style="z-index: 401;">
                        <li [class.disabled]="isDisabled()" class="nav-menu-item nav-menu-center" (click)="route('TreeEditor')">{{'menu.resources.lbl_tree_editor' | translate}}</li>
                        <li [class.disabled]="isDisabled()" class="nav-menu-item nav-menu-center" (click)="route('IndividualEditor')">{{'menu.resources.lbl_member_editor' | translate}}</li>
                        <li [class.disabled]="isDisabled()" class="nav-menu-item nav-menu-center nav-menu-seperator" (click)="route('FamilyEditor')">{{'menu.resources.lbl_family_editor' | translate}}</li>
                        <li [class.disabled]="isDisabled()" class="nav-menu-item nav-menu-center" (click)="openPopup('Place')">{{'menu.resources.lbl_place_list' | translate}}</li>
                        <li [class.disabled]="isDisabled()" class="nav-menu-item nav-menu-center" (click)="openPopup('Multimedia')">{{'menu.resources.lbl_media_list' | translate}}</li>
                        <li [class.disabled]="isDisabled()" class="nav-menu-item nav-menu-center" (click)="openPopup('IndividualMain')">{{'menu.resources.lbl_member_list' | translate}}</li>
                        <li [class.disabled]="isDisabled()" class="nav-menu-item nav-menu-center" (click)="openPopup('Source')">{{'menu.resources.lbl_source_list' | translate}}</li>
                    </ol>
                </li>
                <li class="nav-menu-item">
                    <a class="menu-link nav-menu-center">{{'menu.help.lbl_help' | translate}}<i class="arrow down arrow-white"  style="margin-left: 10px;margin-bottom: 3px"></i></a>
                    <ol class="nav-sub-item" style="z-index: 401;">
                        <li class="nav-menu-item nav-menu-center" (click)="openTab(getEnvironment().FT_HELP_URL+getFtHelpRoute(''))" >{{'menu.help.lbl_knowledgebase' | translate}}</li>
                        <li class="nav-menu-item nav-menu-center" (click)="openTab(getEnvironment().EMBLA_NO_URL+'/Support/Support')" >{{'menu.help.lbl_support' | translate}}</li>                        
                        <li class="nav-menu-item nav-menu-center" (click)="openTab(getEnvironment().EMBLA_NO_URL+'/News/List')" >{{'menu.help.lbl_news_articles' | translate}}</li>
                        <li class="nav-menu-item nav-menu-center" (click)="openTab(getEnvironment().EMBLA_NO_URL+'/Content/AboutEmbla')"  >{{'menu.help.lbl_contact_us' | translate}}</li>
                    </ol>
                </li>
            </ol>
        </nav>
    </div>
    <!-- Profile menu -->
    <!-- left menu -->
<div class="left-menu-container">
    <div style="width: 265px;">
        <div class="icon-input-container">
            <input #searchInput class="icon-input" placeholder="{{'menu.lbl_search_placeholder' | translate}}" (keyup.enter)="searchOnKeyUp($event.target.value);" [readonly]="isHideSearchLoading" (input)="selected($event)" type="text" list="SearchResults">
            <datalist id="SearchResults">                
                <option *ngFor="let sr of results" [value]="sr.displayName"></option>
             </datalist>
            <div class="icon-input-wrap">
                <span [hidden]="!isHideSearchLoading" class="align-middle">{{'lbl_loading' | translate}}</span>
                <span class="align-middle spinner-grow text-secondary" role="status" aria-hidden="true"
                    [hidden]="!isHideSearchLoading"></span>
                <img src="/assets/images/icons/search.svg" width="30px" height="30px" [hidden]="isHideSearchLoading">
            </div>
        </div>
    </div>
    <div style="cursor: pointer;" (click)="showShoppingCart()"><img src="/assets/images/icons/shopping-cart.png" style="width:22px;height: 22px;"></div>
    <div>
        <div class="nav-menu-item clear-hover-bg" style="padding: 0 !important;">
            <!-- <a class="menu-link nav-menu-center" style="padding: 0 !important;">
                <img  src="/assets/images/icons/profile-unknown.svg" class="profile-menu-item">
            </a> -->
            <a class="d-flex cycle" id="header-menu-profile" data-bs-toggle="dropdown" aria-expanded="false">{{firstLetterOfName}}</a>
            <!-- <img class="menu-link nav-menu-center" src="unknown.svg" style="width:39px;height: 39px;background-color: #e6eed7;border-radius: 50%;"> -->
            <ol class="nav-sub-item-right">
                <li class="nav-menu-item nav-menu-center nav-menu-seperator custom-cursor-context-menu">
                    <p class="text-ellipsis-1 custom-cursor-context-menu" style="padding-right:20px;margin-block-end:0;margin-block-start: 0;">{{getUsername()}}</p>
                </li>
                <!-- <li class="nav-menu-item nav-menu-center">My profile</li>
                <li class="nav-menu-item nav-menu-center nav-menu-seperator" >My product</li> -->
                <li class="nav-menu-item nav-menu-center" (click)="showPreferences()" >{{'menu.profile.lbl_program_setup' | translate}}</li>
                <li class="nav-menu-item nav-menu-center" (click)="showAboutUs()" >{{'menu.profile.lbl_about_us' | translate}}</li>
                <li class="nav-menu-item nav-menu-center nav-menu-seperator" (click)="logout()">{{'menu.profile.lbl_logout' | translate}}</li>
                <li class="nav-menu-item nav-menu-center custom-cursor-context-menu" style="font-size: 12px;color:#959595;">{{'menu.lbl_version' | translate}} {{getVersion()}}</li>
            </ol>
        </div>
    </div>
</div>
    <!-- Title -->
    <div class="title-container">{{getTitle()}}</div>
</div>