import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookReportEngineComponent } from './book-report-engine/book-report-engine.component';
import { FlipBookContainerComponent } from './flip-book-container/flip-book-container.component';
import { RouteReuseStrategy } from '@angular/router';
import { NonReusableRouteStrategy } from './non-reusable-route-strategy';



@NgModule({
  declarations: [BookReportEngineComponent, FlipBookContainerComponent],
  imports: [
    CommonModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: NonReusableRouteStrategy },
  ],
})
export class BookReportEngineModule { }
