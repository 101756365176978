
<div class="ft-form">
    <div class="content">
        <!-- form title -->
        <div class="ft-form-row">
            <span class="title">{{data.FamilyProject.projectName}}</span>
        </div>

        <div class="ft-form-row col1-v100">
            <div class="ft-form-input">
                <label>{{"lbl_family_name_input"| translate}}</label>
                <input disabled type="text" [value]="data.FamilyProject.projectName">
            </div>                      
        </div>

        <div class="ft-form-row col1-v100">
            <div class="ft-form-input">
                <label>{{"lbl_tree_description" | translate}}</label>
                <textarea type="textarea" rows="3" [(ngModel)] ="formFamilyDescription" (ngModelChange)="checkUnsaved()"></textarea>
            </div>                      
        </div>
        <div class="ft-form-row col1-v100">
            <div class="ft-form-input">
                <label>{{"lbl_tree_summary" | translate}}</label>
                <table class="table-main">
                    <tr>
                        <td class="table-key">{{"lbl_last_update" | translate }}</td>
                        <td class="table-value">{{formattedDate}}</td>
                    </tr>
                    <tr>
                        <td class="table-key">{{"lbl_file_type" | translate}}</td>
                        <td class="table-value">{{data.FamilyProject.originalSourceType}}</td>
                    </tr>
                    <tr>
                        <td class="table-key">{{"lbl_people" | translate}}</td>
                        <td class="table-value">{{familyProjectDetails.people}}</td>
                    </tr>
                    <tr>
                        <td class="table-key">{{"lbl_families" | translate}}</td>
                        <td class="table-value">{{familyProjectDetails.families}}</td>
                    </tr>
                    <tr>
                        <td class="table-key">{{"lbl_places" | translate}}</td>
                        <td class="table-value">{{familyProjectDetails.places}}</td>
                    </tr>
                    <tr>
                        <td class="table-key">{{"lbl_sources" | translate}}</td>
                        <td class="table-value">{{familyProjectDetails.sources}}</td>
                    </tr>
                    <tr>
                        <td class="table-key">{{"lbl_citations" | translate}}</td>
                        <td class="table-value">{{familyProjectDetails.citations}}</td>
                    </tr>
                    <tr>
                        <td class="table-key">{{"lbl_multimedia" | translate}}</td>
                        <td class="table-value">{{familyProjectDetails.multimedias}}</td>
                    </tr>
                    <tr>
                        <td class="table-key">{{"lbl_events" | translate}}</td>
                        <td class="table-value">{{familyProjectDetails.events}}</td>
                    </tr>
                    <tr>
                        <td class="table-key">{{"lbl_family_tree_size" | translate}}</td>
                        <td class="table-value">{{familyProjectDetails.treeSize}}</td>
                    </tr>       
                </table>
            </div>                      
        </div>
        <div *ngIf="data.FamilyProject.originalSourceType=='GEDCOM'" class="ft-form-row col1-v100" style="margin-top: 10px;">
            <button class="ft-btn ft-btn-active-not-selected">{{"lbl_download_original_gedcom" | translate}}</button>
        </div>
    </div>
    <div class="footer">
        <button class="ft-btn" [ngClass]="(hasUnsaved)? 'ft-btn-active' : 'ft-btn-active-not-selected'" [disabled]="!hasUnsaved" (click)="save()">{{"lbl_save" | translate}}</button>
        <button class="ft-btn ft-btn-active-not-selected" (click)="cancel()">{{"lbl_cancel" | translate}}</button>
    </div>
</div>
