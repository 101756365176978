import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import BookReport from 'src/app/common/models/BookReport';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import BookReportConfig, { BookReportPage } from "src/app/common/models/BookReportConfig";
import { LoadingService } from 'src/app/services/UI/loading.service';
import { BookReportEngineService } from '../services/book-report-engine-service.service';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { ActivatedRoute } from '@angular/router';
import { CustomError } from 'src/app/common/helpers/custom-error'
import { GlobalerrorService } from 'src/app/services/globalerror.service';
import { TranslateService } from '@ngx-translate/core';
import { FlipBookContainerComponent } from '../flip-book-container/flip-book-container.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { MessageDialogService } from "src/app/components/common/message-dialog/services/message-dialog.service";
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { BirthdayGreeting } from '../param-initializer/birthdaygreeting';
import BookReportViewModel from 'src/app/common/models/BookReportViewModel';
import { ErrorStatus, PagePosition, ReportPublishOptions, ReportTemplate, ReportType } from 'src/app/common/enums/enums';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { ReportEngineService } from "src/app/components/reportengine/services/report-engine-service.service";
import { ResponseModel } from 'src/app/common/models/responseModel';
import { environment } from 'src/environments/environment';
import { AnniversaryGreeting } from '../param-initializer/anniversarygreeting';
import { FamilyApiService } from 'src/app/services/API/family-api.service';
@Component({
  selector: 'app-bookreportengine',
  templateUrl: './book-report-engine.component.html',
  styleUrls: ['./book-report-engine.component.scss']
})
export class BookReportEngineComponent implements OnInit {

  bookReport: BookReport;
  bookReportConfig: BookReportConfig;
  bookReportViewModel: BookReportViewModel;
  reportName: string = "";
  lang: string = "";
  processId: string;
  isBookLoaded: boolean;
  bookReportUpdateSource: BehaviorSubject<BookReport> = new BehaviorSubject<BookReport>(undefined);
  isForwardEnabled: boolean;
  isBackwardEnabled: boolean;
  isRegenerateEnabled: boolean;
  isZoomInEnabled: boolean;
  isZoomOutEnabled: boolean;
  isFirstContentPageEnabled: boolean;
  isLastContentPageEnabled: boolean;
  reportHelperMapping: any;
  bookReportPages: BookReportPage[] = [];
  appBaseUrl = environment.IFRAME_URL;

  @ViewChild('bookContainer') flipBook!: FlipBookContainerComponent;

  constructor(
    private translate: TranslateService,
    private loadingService: LoadingService,
    private bookReportEngineService: BookReportEngineService,
    private activatedRoute: ActivatedRoute,
    private globalErrorService: GlobalerrorService,
    private messageDialogService: MessageDialogService,
    private translateHandler: TranslateHandler,
    private router: Router,
    private ref: ChangeDetectorRef,
    private notifierService: NotifierV2Service,
    private dialogService: DialogService,
    private mainReportEngineService: ReportEngineService,
    private familyApiService: FamilyApiService,
  ) {
    //TODO Refactor report name init
    this.activatedRoute.params.subscribe(params => {
      this.reportName = params['name'];
      this.lang = this.translate.getDefaultLang();
      this.bookReport = new BookReport("dummy", 0, false, this.reportName, this.lang); //dummy bookReport TODO: need add configuration to get report name.
    });
    this.bookReportViewModel = new BookReportViewModel();
    this.bookReportConfig = new BookReportConfig();
    this.isForwardEnabled = true;
    this.isBackwardEnabled = false;
    this.isRegenerateEnabled = true;
    this.isZoomInEnabled = true;
    this.isZoomOutEnabled = true;
    this.isFirstContentPageEnabled = false;
    this.isLastContentPageEnabled = true;
  }

  ngOnInit(): void {
    this.reportHelperMapping = {
      BirthdayGreeting: BirthdayGreeting,
      AnniversaryGreeting: AnniversaryGreeting,
    };

    this.initReport();
  }

  ngOnDestroy(): void {
    this.bookReportUpdateSource.unsubscribe();
    this.bookReportEngineService.endSession();
  }

  /**
   * @param reportName: A string representing the name of the report.
   * @returns undefined if the report name does not match any supported report.
   * 
   * Retrieves the constructor function for the specified report name from a predefined mapping.
   * Initializes the name and lang properties of the bookReportViewModel.
   * Constructs an instance of the corresponding report class using the retrieved constructor function.
   * Obtains report parameters using the getPersonReportParam() method of the report class.
   * Serializes the report parameters into a JSON string and assigns it to the reportParams property of bookReportViewModel.
   */
  updateReportParam(reportName: string): void {
    this.bookReportViewModel.name = reportName;
    this.bookReportViewModel.lang = this.translate.getDefaultLang();

    const mappedHelperClass = this.reportHelperMapping[reportName];
    if (!mappedHelperClass) {
      return;// throw new Error(`Helper class not found for report: ${reportName}`);
    }

    const reportClassObj = new mappedHelperClass(this.notifierService, this.familyApiService);
    const bookReportParamObj = reportClassObj.getReportParam();

    this.bookReportViewModel.reportParams = JSON.stringify(bookReportParamObj);
    if (reportClassObj.canGenerateReport) {
      reportClassObj.canGenerateReport().subscribe((updatedObject) => {
        this.bookReportViewModel.reportStatus = updatedObject;
      });
    } else {
      this.bookReportViewModel.reportStatus = {
        ...this.bookReportViewModel.reportStatus,
        type: 1,
        url: ''
      };
    }    
  }

  /**
   * ### Method Summary
    - This method initializes the book report based on the report name obtained from the route parameters.

    **Returns**: `void`  
  - **Behavior**:
  - Retrieves the report name from the route parameters.
  - Generates a unique process ID using `GuidGenerator.generate()` for tracking the initialization process.
  - Displays a loading indicator using `loadingService.show()` while fetching the report configuration.
  - Fetches the report configuration using `bookReportEngineService.getConfig()` and subscribes to the result.
  - Merges the fetched configuration with the existing `bookReportConfig`.
  - Initializes report parameters using `initReportParam(reportName: string)`.
  - Initiates the report update source using `bookReportEngineService.start(bookReportViewModel)`.
  - Handles errors, logging them to the console and throwing a custom error if the book report is not found.
  - Displays a global error popup using `globalErrorService.showErrorPopup()` in case of an unhandled error.
   */
  initReport(): void {
    try {
      this.activatedRoute.params.subscribe(params => {
        // Get report name from route parameters
        this.reportName = params['name'];

        if (this.reportName) {
          this.processId = GuidGenerator.generate();
          this.loadingService.show(this.processId);

          this.bookReportEngineService.getConfig(this.reportName).subscribe((config: BookReportConfig) => {
            config.staticPages.forEach((page) => {
              page.pagePosition = PagePosition[page.pagePosition] as unknown as PagePosition;
            })
            Object.assign(this.bookReportConfig, config);
            this.updateReportParam(this.reportName);
            this.updateReportConfig(this.bookReportConfig);
            this.bookReportUpdateSource = this.bookReportEngineService.start(this.bookReportViewModel);
          });
        }
      },
        (error) => {
          console.log(error);
          throw new CustomError("Book report not Found", 1602, false);
        }
      );
    } catch (error) {
      console.log(error);
      this.globalErrorService.showErrorPopup("1611");
    }
  }
  updateReportConfig(bookReportConfig: BookReportConfig): void {
    for (const key in bookReportConfig) {
      if (bookReportConfig.hasOwnProperty(key)) {
        this[key] = bookReportConfig[key];
      }
    }
  }
  
  onFlipBookUILoaded(status: boolean): void {
    if (status) {
      this.bookReportUpdateSource.pipe(catchError(error => {
        if(error instanceof Object){
          const { title, body, footer } = this.translateErrorMessages(error);
        if (error.type == ErrorStatus.Confirmation) {
          this.handleConfirmationError(error, title, body, footer);
        }
        else if (error.type == ErrorStatus.NotAvailable) {
          this.handleNotAvailableError(error, title, body, footer);
        } else {
          this.handleGeneralError();
        }
      }
      throw error;
      })).subscribe((report) => {
        if (report != undefined) {
          this.bookReport = report;
          if (!this.isBookLoaded && report.isCompleted) {
            this.isBookLoaded = true;
            this.loadingService.hide(this.processId);
          }
        }
      });
    }
  }
enableZoomOut(){
  var currentZoomLevel = this.flipBook.getCurrentZoomLevel();
  if(currentZoomLevel = this.bookReportConfig.minZoomLevel){
    
  }
  this.isZoomOutEnabled = true;
  this.ref.detectChanges();
}
  onPageFlipped(currentView: number[]): void {
    //currentView[0] : current Left side page
    //currentView[1] : current Right side page

    //first page
    if (currentView[0] == 0) {
      this.isForwardEnabled = true;
      this.isBackwardEnabled = false;
      this.isFirstContentPageEnabled = true;
      this.isLastContentPageEnabled = false;
    }

    //last page
    if (currentView[1] == 0) {
      this.isForwardEnabled = false;
      this.isBackwardEnabled = true;
      this.isLastContentPageEnabled = true;
      this.isFirstContentPageEnabled = false;
    }

    //if their is any page in right side, forward button is enabled
    if (currentView[0] < currentView[1]) {
      this.isForwardEnabled = true;
    }
    //when current page is greater than 1, backward button is enabled
    if (currentView[0] > 0) {
      this.isBackwardEnabled = true;
    }
    var fountStaticCoverPages = this.bookReportConfig.staticPages.filter(page => page.pagePosition == PagePosition.First).length;
    var backStaticCoverPages = this.bookReportConfig.staticPages.filter(page => page.pagePosition == PagePosition.Last).length;
    var lastPageCount = this.flipBook.getLastPage();
    var firstContentPage = fountStaticCoverPages + 1;
    var finalContentPage = lastPageCount - (backStaticCoverPages + 1);

    //when current page is greater than 1st content page, first content button is enabled
    if (currentView[0] > firstContentPage) {
      this.isFirstContentPageEnabled = true;
    }
    //else current page is less than 1st content page, first content button is disabled
    else if (currentView[0] <= firstContentPage) {
      this.isFirstContentPageEnabled = false;
    }
    //when current page is less than last content page, last content button is enabled
    if (currentView[0] < finalContentPage) {
      this.isLastContentPageEnabled = true;
    }
    //whe current page is greater than last content page, last content button is disabled
    if (currentView[0] >= finalContentPage) {
      this.isLastContentPageEnabled = false;
    }
    //if only single content page in the book, disable both buttons
    if (firstContentPage == finalContentPage) {
      this.isFirstContentPageEnabled = false;
      this.isLastContentPageEnabled = false;
    }
    //Update the UI manually. Because it's not updating immediately after emitting
    this.ref.detectChanges();
  }

  getBookReportPages() {
    return this.flipBook.bookReportPages;
  }

  regenerate(): void {
    this.updateReportParam(this.reportName)

    this.isRegenerateEnabled = false;
    this.isBookLoaded = false;
    if(this.bookReportViewModel.reportStatus.type != ErrorStatus.Available) {
      this.bookReportUpdateSource.error(this.bookReportViewModel.reportStatus);
      return;
    }
    this.bookReportEngineService.createReport(this.bookReportViewModel).pipe(catchError(err => {
      this.loadingService.hide(this.processId);
      this.isRegenerateEnabled = true;
      this.showError(`${this.reportName}.lbl_title`, 'bookReport.info_click_ok_to_redirect_tree_editor', 'bookReport.err_delay_report_genarate').subscribe(() => {
        return;
      });
      throw err;
    })).subscribe(() => {
      const previousUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([previousUrl]);
      })
    });
  }

  private translateErrorMessages(error: any): { title: string, body: string, footer: string } {
    const messageTitle = this.translateHandler.translate(`${this.reportName}.lbl_title`);
    const messageBody = this.translateHandler.translate(`${this.reportName}.err_body.${error.type}`);
    const messageFooter = this.translateHandler.translate(`${this.reportName}.err_footer.${error.type}`);
    return { title: messageTitle, body: messageBody, footer: messageFooter };
  }
  private handleConfirmationError(error: any, messageTitle: string, messageBody: string, messageFooter: string): void {
    this.loadingService.hide(this.processId);
    this.messageDialogService.openInfo(messageTitle, messageBody, messageFooter).subscribe((response: boolean) => {
      if (response) {
        this.bookReportEngineService.createReport(this.bookReportViewModel).pipe(
          catchError(err => {
            this.loadingService.hide(this.processId);
            this.isRegenerateEnabled = true;
            this.showError(`${this.reportName}.lbl_title`, 'bookReport.info_click_ok_to_redirect_tree_editor', 'bookReport.err_delay_report_genarate').subscribe(() => {
              return;
            });
            throw err;
          })).subscribe(() => {
          const previousUrl = this.router.url;
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate([previousUrl]);
            })
        });
      } else {
        this.router.navigateByUrl(error.url);
      }
    });
    throw error;
  }
  private handleNotAvailableError(error: any, messageTitle: string, messageBody: string, messageFooter: string): void {
    this.loadingService.hide(this.processId);
    this.messageDialogService.openError(messageTitle, messageBody, messageFooter).subscribe(() => {
        this.router.navigateByUrl(error.url);
        return;
    });
    throw error;
  }
  private handleGeneralError(): void {
    this.loadingService.hide(this.processId);
    this.showError(`${this.reportName}.lbl_title`, `bookReport.err_load_book_report`, `bookReport.err_generating_book_report_message`).subscribe(() => {
        this.router.navigateByUrl('/dashboard');
        return;
    });
  }
  showError(title: string, info: string, prompt: string): Observable<any> {
    return this.messageDialogService.openError(
      this.translateHandler.translate(title),
      this.translateHandler.translate(info),
      this.translateHandler.translate(prompt));
  }

  publishReport() {
    let popupInput = {
      reportFileName: `${this.translateHandler.translate(this.bookReport.name + ".lbl_title")} ${this.translateHandler.translate("lbl_of")} ${this.notifierService.getCurrentRootMember().DisplayName}`,
      optionsList: [ReportPublishOptions.FileName]
    }

    this.dialogService.setHalf().open("ReportPublishOption", popupInput).subscribe(response => {
      if (response.data) {
        let requestData = {
          content: this.getReportContent(),
          type: ReportType[this.bookReport.name],
          reportName: response.data.reportFileName,
          width: (this.bookReportConfig.bookWidth / 2) + this.bookReportConfig.publishWidthCorrection,
          height: this.bookReportConfig.bookHeight - 1 + this.bookReportConfig.publishHeightCorrection, //TODO: -1 for fixing IFRAME height
          template: ReportTemplate.None
        }
        let processId = GuidGenerator.generate()
        this.loadingService.show(processId);
        this.mainReportEngineService.publishReport(requestData).subscribe((response: ResponseModel<string>) => {
          if (response) {
            this.router.navigate(['/reportmanager'])
          }
        }, (err) => {
          throw new CustomError("BookReportEngine => publishReport() : " + err, 911, false);
        }).add(() => {
          this.loadingService.hide(processId)
        });
      }
    });
  }

  getReportContent() {
    const bookReportPages = this.getBookReportPages();
    const baseHtml = `<html><head>
    <style>
      iframe {
        display: block;
        margin: 0;
        padding: 0;
        border: none;
      }
    </style>
  </head>
  <body>{{iframeList}}</body></html>`;

    let iframeList = '';

    //TODO foreach this.bookReportPages
    //+14 for set equal with publish global border
    bookReportPages.forEach(page => {
      let reportPath = '';
    
      const iframeWidth = `${(this.bookReportConfig.bookWidth / 2) + 14}px`;
      const iframeHeight = `${(this.bookReportConfig.bookHeight) + 14}px`;
      const iframeString = `<iframe width='${iframeWidth}' height='${iframeHeight}' scrolling='no' src='{{reportPath}}'></iframe>`;

      if (page.isDynamicPage) {
        reportPath = `${environment.BOOK_REPORT.dynamicPagePath}//${this.bookReport.id}//${page.path}.html`;
      } else {
        reportPath = `${environment.BOOK_REPORT.templatePath}//${page.path}`;
      }

      iframeList += iframeString.replace('{{reportPath}}', reportPath);
    });

    return baseHtml.replace('{{iframeList}}', iframeList);
  }

}
