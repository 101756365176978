<div class="ft-form">
    <div class="content">
        <!-- form title -->
        <div class="ft-form-row">
            <span class="title">{{'lbl_create_new_family_tree' | translate}}</span>
        </div>
        <div class="ft-form-row col1-v100">
            <div class="ft-form-input">
                <label>{{"lbl_family_name_input"| translate}}</label>
                <input type="text" [(ngModel)]="familyProject.projectName" (keyup)="validateProjectName(familyProject.projectName)"
                    [ngClass]="(isNameInvalid || isAlreadyExistName)? 'ng-invalid' : ''" name="ProjectName"
                    #ProjectName="ngModel" required>
                <small *ngIf="isNameInvalid && !ProjectName.invalid" class="error">{{'err_invalid_projectname' | translate}}</small>
                <small *ngIf="isAlreadyExistName" class="error">{{'err_projectname_already_exist' |
                    translate}}</small>
                <small *ngIf="ProjectName.touched && ProjectName.invalid" class="error">{{'lbl_required' |
                    translate}}</small>
            </div>
        </div>
        <div style="padding-right: 0;">
            <div class="ft-form-row col1-v100" style="margin-top: 10px;">
                <div class="ft-form-input">
                    <label>{{"lbl_enter_first person" | translate}}</label>
                </div>
            </div>
            <div class="ft-form-row col1-v100">
                <div class="ft-form-input">
                    <label>{{'lbl_given_name' | translate}}</label>
                    <input type="text" [ngClass]="(GivenName.touched && GivenName.invalid) ? 'form-error' : ''"
                        [(ngModel)]="familyProject.individualGivenName" (ngModelChange)="validateForm()" name="GivenName"
                        #GivenName="ngModel" required>
                    <small *ngIf="GivenName.touched && GivenName.invalid" class="error">{{'lbl_required' |
                        translate}}</small>
                </div>
            </div>
            <div class="ft-form-row col1-v100">
                <div class="ft-form-input">
                    <label>{{'lbl_last_name' | translate}}</label>
                    <input type="text" [ngClass]="(LastName.touched && LastName.invalid) ? 'form-error' : ''"
                        [(ngModel)]="familyProject.individualLastName" (ngModelChange)="validateForm()" name="LastName"
                        #LastName="ngModel" required>
                    <small *ngIf="LastName.touched && LastName.invalid" class="error">{{'lbl_required' |
                        translate}}</small>
                </div>
            </div>
        </div>
        <div class="ft-form-row col1-v100" style="margin-top: 10px;margin-bottom: 20px;">
            <div class="ft-form-input">
                <label>{{'lbl_gender' | translate}}</label>
                <div style="display: flex;flex-direction: row;gap:10px">
                    <div class="input-radio-container">
                        <label>{{'lbl_male' | translate}}</label>
                        <input type="radio" name="gender" (change)="setGenderAndProfileImage('M')">
                        <span class="radiomark"></span>
                    </div>
                    <div class="input-radio-container">
                        <label>{{'lbl_female' | translate}}</label>
                        <input type="radio" name="gender" (change)="setGenderAndProfileImage('F')">
                        <span class="radiomark"></span>
                    </div>
                    <div class="input-radio-container">
                        <label>{{'lbl_unknown' | translate}}</label>
                        <input type="radio" name="gender" (change)="setGenderAndProfileImage('U')" checked>
                        <span class="radiomark"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <button type="button" [ngClass]="(isFormValid)? 'ft-btn-inactive active' : 'ft-btn-active-not-selected'"
            [disabled]="!isFormValid" class="ft-btn" (click)="addNewFamilyProject()">{{"lbl_save" |
            translate}}</button>
        <button type="button" class="ft-btn ft-btn-active-not-selected" (click)="cancel()">{{"lbl_cancel" |
            translate}}</button>
    </div>
</div>