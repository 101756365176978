
<app-subheader-main></app-subheader-main>
<style>
    
</style>
<div class="info-container height-adjust-95">
<div class="cover-img-container">
    <span>
        <div class="cover-img-text-overlay">    
            <div style="width: 200px"></div>
            <div style="width: auto" class="cover-img-text-container">
                <div class="cover-title-date">{{  getToday() }}</div>
                <div class="label-xxxl-grey-bold text-white">{{ getWelcomeMessage() }}</div> 
            </div>
            <div style="width: 200px;color:#a8a8a8;font-size: 10px;" class="cover-action-container" >Foto av Knut Sørensen</div>   
        </div>        
    </span>
</div>
<div class="editor-container" style="width: 100%; display: flex; flex-direction: column; align-items: center;">
    <div style="width: 960px;">
        
        <div class="border-outline" style="padding: 20px;background-color: white;display: flex; flex-direction:column; gap:20px;align-content: stretch;">        
             <!--  0 - New user -->
             <div *ngIf="getMessageState() == 0" class="info-text-panel border-outline">               
                <div>
                    <span class=".label-xl-grey" style="color: white;font-weight: bold;">Opprett nytt familietre eller importer en Gedcom-fil</span>                    
                </div>
                <div style="display:flex;flex-direction: column;gap:5px">
                    <button class="ft-btn ft-btn-active" (click)="openCreateImportPanel(1)">Opprett nytt</button>
                    <button class="ft-btn ft-btn-active" (click)="openCreateImportPanel(2)">Importer</button>
                </div>
            </div>
            <!-- 1 - continue with existing -->
            <div *ngIf="getMessageState() == 1" class="info-text-panel border-outline">                               
                <div>
                    <span class=".label-xl-grey" style="color: white;font-weight: bold;">Forsette å jobbe med - {{ getCurrentProjectName() }} &nbsp; </span>
                </div>
                <div>
                    <button class="ft-btn ft-btn-active" (click)="openTreeEditor()">Forsette</button>
                </div>
            </div>
            <!-- trial expire message -->
            <app-expire-message></app-expire-message>
           <!-- 2 - still importing  -->
           <div *ngIf="getMessageState() == 2" class="info-text-panel border-outline">               
               <div>
                    <span class="label-l-grey" style="color: white;font-weight: bold;">Programmet vil bruke litt tid for å importere familietreet. Du vil motta en e-post når treet er importert. Hvis det tar veldig lang tid kan du opprette en brukerstøttesak</span>
                </div>
                <div>
                    <a href="http://www.embla.no/Support/Support" target="_blank" ><button class="ft-btn ft-btn-active" (click)="openTreeEditor()">Brukerstøttesak</button></a>
                </div>
            </div>
            <!-- 3 - Invalid  -->
            <div *ngIf="getMessageState() == 3" class="info-text-panel border-outline">               
                <div>
                    <span class="label-l-grey" style="color: white;font-weight: bold;">Det har oppstått et ukjent problem med familietreet. Opprett en brukerstøttesak</span>
                 </div>
                 <div>
                    <a href="http://www.embla.no/Support/Support" target="_blank" ><button class="ft-btn ft-btn-active">Brukerstøttesak</button></a>
                </div>
             </div>
            <!-- 
            <div *ngIf="getProjectCount()!=0 " style="width: 100%" class="border-outline">
                <div style="background-color: #eed7d7;width: 100%; height: 50px;display: flex;flex-direction: column; justify-content: center;padding-left: 20px;" >
                    <span class="label-l-grey-bold">{{'dashboard.err_archive_title' | translate}} - {{getCurrentProjectName()}}</span>
                </div>
                <div style="padding: 25px;display: flex;flex-direction: column;gap: 10px;">
                    <span class="label-l-grey" style="color: #5b5b5b;">Det ser ut som om det er et problem med familietreet ditt. <a style="font-weight: bold; color: #424242;" href="http://www.embla.no/Support/Support" target="_blank" >Klikk her</a> for å opprette en brukerstøttesak</span>
                </div>
            </div>       
            -->

            <!-- Whats new --> 
            <div style="width: 100%" class="border-outline">
                <div style="background-color: #f7f7f7;width: 100%; height: 50px;display: flex;flex-direction: column; justify-content: center;padding-left: 20px;" >
                    <span class="label-l-grey-bold">{{'dashboard.lbl_whatsnew' | translate}}</span>
                </div>
                <div style="padding: 25px;display: flex;flex-direction: column;gap: 10px;">
                    <div style="border-left: 3px solid #b9d353;padding-left: 10px;display: flex;flex-direction: column;">
                        <span class="label-l-grey" style="color: #5b5b5b;">Hjelpesenteret har blitt oppdatert</span>
                        <span class="label-l-grey" style="color: #5b5b5b;">Ystemet er ytterligere forbedret med feilrettinger for brukervennlighet</span>
                        <span class="label-s-grey">03 Oktober 2024</span>
                        <span class="label-xs-grey">V10.2.1</span>
                    </div>  
                    <div style="border-left: 3px solid #b9d353;padding-left: 10px;display: flex;flex-direction: column;">
                        <span class="label-l-grey" style="color: #5b5b5b;">Medlemmer kan nå oppleve den nye produktsiden, sikrere autorisering og en forbedret kunnskapsbase</span>
                            <span class="label-l-grey" style="color: #5b5b5b;">Programmet er ytterligere forbedret med feilrettinger og hastighetsforbedringer </span>       
                        <span class="label-s-grey">27 august 2024</span>
                        <span class="label-xs-grey">V10.2.0</span>
                    </div>  
                    <div style="border-left: 3px solid #b9d353;padding-left: 10px;display: flex;flex-direction: column;">
                        <span class="label-l-grey" style="color: #5b5b5b;">Applikasjonen har blitt ytterligere forbedret for å forbedre brukervennligheten</span>
                        <span class="label-s-grey">25 Juli 2024</span>
                        <span class="label-xs-grey">V10.1.141</span>
                    </div>                             
                </div>
            </div>
            
        </div>    
        <!-- footer -->
        <div style="display: flex;flex-direction: row; height: 70px;padding: 25px;background-color: #f3f3f3;width: 100%;align-items: center;justify-content: space-between;
        font-size: 13px;
        color: #636363;">
            <!-- copyright -->
            <div style="display: flex;flex-direction: column;">
                <div style="font-weight: bold">Copyright &copy; Embla Norsk Familiehistorie AS, Med enerett</div>
                <div style="font-size: 10px;">Org. number : 988 610 976</div>
            </div>
            <!-- icons -->
            <div style="display: flex;flex-direction: row;">
                <div style="font-weight: bold"><a style="text-decoration: none;color: inherit; " href="http://www.embla.no/Content/PrivacyPolicy" target="_blank">Personvernerklæring</a></div>
                <!--
                <div>FB</div>
                <div>TW</div>
                <div>IN</div>
                -->
            </div>
        </div>
    </div>
</div>
<!-- footer -->

</div>