 <svg>
    <!-- Level 1,2,3 labels     -->
    <g *ngIf="i<7 && label.status=='active'" [attr.id]="label.displayIndex" height="500" width="500"
        transform="scale(0.47 0.47)">       

        <g *ngIf="label.displayIndex == 1" transform="translate(50,140) scale(4.8 4.8)">  
            <image x="0" y="0" height="175" width="90" href="assets/tree-editor/level_1/leave_1_top_color.svg" />
        </g> 
        <g *ngIf="label.displayIndex == 1.01" transform="translate(50,-800) scale(4.8 4.8)">  
            <image x="0" y="0" height="175" width="90" href="assets/tree-editor/level_1/leave_1_bottom_color.svg" />
        </g> 
        
        <g *ngIf="label.displayIndex == 2" transform="translate(-465,140) scale(2.1 2.1)">  
            <image x="304" y="0" height="175" width="175" href="assets/tree-editor/level_2/leave_2_top_color.svg" />
        </g> 
        <g *ngIf="label.displayIndex == 2.01" transform="translate(-485,-327) scale(2.1 2.1)">  
            <image x="313" y="0" height="175" width="175" href="assets/tree-editor/level_2/leave_2_bottom_color.svg" />
        </g> 

        <g *ngIf="label.displayIndex == 2.02" transform="translate(-440,140) scale(2.1 2.1)">  
            <image x="304" y="0" height="175" width="175" href="assets/tree-editor/level_3/leave_3_top_color.svg" />
        </g> 
        <g *ngIf="label.displayIndex == 2.03" transform="translate(-455,-327) scale(2.1 2.1)">  
            <image x="313" y="0" height="175" width="175" href="assets/tree-editor/level_3/leave_3_bottom_color.svg" />
        </g> 

            <path class="element-over-hand" (click)="expandWheel(label)" fill="#FDFEFE"    
            d="M61.7,31c0,0,0,119.1,0,119.1c-7.7-7.4-15.3-14.8-23-22.3c-4.8-4.7-9.7-9.3-14.6-14c-2.1-2-4.1-4.2-6.3-6.1 c-1.9-1.7-4-3.4-5.1-5.9c-1-2.2-1.1-4.7-1.1-7.1c-0.1-3.4,
        0-6.8,0.2-10.2c0.1-2,0.2-4.3,1.2-6.1c0.7-1.3,1.8-2.4,2.9-3.4 c1.7-1.5,3.2-3.1,4.9-4.7c5.2-5,10.4-10,15.6-15c8.3-8,16.7-16,25-23.9C61.4,31.2,61.6,31.1,61.7,31z" />


        <!-- Male female color change ofthe label -->
        <!-- male -->
        <path *ngIf="label.gender == 'M'" class="male-color" d="M682.2,19.9C682.2,19.9,682.2,20,682.2,19.9c0,0.1,0,0.2-0.1,0.4c0,0.2-0.1,0.3-0.2,0.4 c-0.2,0.3-0.5,0.6-0.9,0.7c0,0,0,0,0,
                0c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.5,0-0.8,0c-0.5-0.1-1-0.1-1.6-0.2 c-0.6-0.1-1.2-0.1-1.8-0.1c-0.2,0-0.3,0-0.5-0.1c-1,0-1.9,
                0-2.9,0c-0.6,0-1.1-0.1-1.7-0.1l0,0.3c0.1,3.8-0.5,7.4-1.8,10.7 c0.3,4.3,0.5,8.7,0.5,13.1c0,8.2-0.5,16.5-0.9,24.7c-0.8,14.9-1.6,
                29.9-0.2,44.7c1.4,7.1,4,13.9,5.2,21.1c0.7,4.5,1.6,9.1,1.6,13.7 c0,2.3-0.4,4.5-0.7,6.8c0,0.2-0.1,0.4-0.1,0.7c0.4,1.4,0.7,2.7,
                1.1,4.1c0.2-0.2,0.6-0.3,1-0.3c0,0,0.1,0,0.1,0 c0.2-0.1,0.4-0.2,0.7-0.2c0.1,0,0.2,0,0.3,0c0.2-0.1,0.3-0.1,0.5-0.1h0.1c0.1,0,
                0.2,0,0.3,0c0.2,0,0.4-0.1,0.6,0 c0.1,0,0.3,0,0.4,0.1c0.1,0,0.1,0,0.2,0h0c0.4,0,0.8,0.2,1.1,0.5c0.1,0.1,0.2,0.2,0.2,0.3c0,
                0,0.1,0,0.1,0c0.1,0,0.2,0,0.2,0.1 c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,
                0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0l0,0 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c6.1-2,10.5-7.7,10.5-14.5V34.4C692.7,27.7,
                688.3,21.9,682.2,19.9z" />
        <!-- female -->
        <path *ngIf="label.gender == 'F'" class="female-color" d="M682.2,19.9C682.2,19.9,682.2,20,682.2,19.9c0,0.1,0,0.2-0.1,0.4c0,0.2-0.1,0.3-0.2,0.4 c-0.2,0.3-0.5,0.6-0.9,0.7c0,0,0,0,0,
                0c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.5,0-0.8,0c-0.5-0.1-1-0.1-1.6-0.2 c-0.6-0.1-1.2-0.1-1.8-0.1c-0.2,0-0.3,0-0.5-0.1c-1,0-1.9,0-2.9,
                0c-0.6,0-1.1-0.1-1.7-0.1l0,0.3c0.1,3.8-0.5,7.4-1.8,10.7 c0.3,4.3,0.5,8.7,0.5,13.1c0,8.2-0.5,16.5-0.9,24.7c-0.8,14.9-1.6,29.9-0.2,
                44.7c1.4,7.1,4,13.9,5.2,21.1c0.7,4.5,1.6,9.1,1.6,13.7 c0,2.3-0.4,4.5-0.7,6.8c0,0.2-0.1,0.4-0.1,0.7c0.4,1.4,0.7,2.7,1.1,4.1c0.2-0.2,
                0.6-0.3,1-0.3c0,0,0.1,0,0.1,0 c0.2-0.1,0.4-0.2,0.7-0.2c0.1,0,0.2,0,0.3,0c0.2-0.1,0.3-0.1,0.5-0.1h0.1c0.1,0,0.2,0,0.3,0c0.2,0,0.4-0.1,
                0.6,0 c0.1,0,0.3,0,0.4,0.1c0.1,0,0.1,0,0.2,0h0c0.4,0,0.8,0.2,1.1,0.5c0.1,0.1,0.2,0.2,0.2,0.3c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2,0.1 c0,
                0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0l0,0 c0,0,0,0,0,
                0c0,0,0,0,0,0c0,0,0,0,0,0c6.1-2,10.5-7.7,10.5-14.5V34.4C692.7,27.7,688.3,21.9,682.2,19.9z" />

                <!-- Unknown gender -->
        <path *ngIf="label.gender == 'U'" class="unknown-color" d="M682.2,19.9C682.2,19.9,682.2,20,682.2,19.9c0,0.1,0,0.2-0.1,0.4c0,0.2-0.1,0.3-0.2,0.4
        c-0.2,0.3-0.5,0.6-0.9,0.7c0,0,0,0,0,0c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.5,0-0.8,0c-0.5-0.1-1-0.1-1.6-0.2
        c-0.6-0.1-1.2-0.1-1.8-0.1c-0.2,0-0.3,0-0.5-0.1c-1,0-1.9,0-2.9,0c-0.6,0-1.1-0.1-1.7-0.1l0,0.3c0.1,3.8-0.5,7.4-1.8,10.7
        c0.3,4.3,0.5,8.7,0.5,13.1c0,8.2-0.5,16.5-0.9,24.7c-0.8,14.9-1.6,29.9-0.2,44.7c1.4,7.1,4,13.9,5.2,21.1c0.7,4.5,1.6,9.1,1.6,13.7
        c0,2.3-0.4,4.5-0.7,6.8c0,0.2-0.1,0.4-0.1,0.7c0.4,1.4,0.7,2.7,1.1,4.1c0.2-0.2,0.6-0.3,1-0.3c0,0,0.1,0,0.1,0
        c0.2-0.1,0.4-0.2,0.7-0.2c0.1,0,0.2,0,0.3,0c0.2-0.1,0.3-0.1,0.5-0.1h0.1c0.1,0,0.2,0,0.3,0c0.2,0,0.4-0.1,0.6,0
        c0.1,0,0.3,0,0.4,0.1c0.1,0,0.1,0,0.2,0h0c0.4,0,0.8,0.2,1.1,0.5c0.1,0.1,0.2,0.2,0.2,0.3c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2,0.1
        c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0l0,0
        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c6.1-2,10.5-7.7,10.5-14.5V34.4C692.7,27.7,688.3,21.9,682.2,19.9z" />
        <!--  -->

        <path class="label-background" d="M 122.7 4.1 C 170.47263086 4.1 209.2 42.8273691396 209.2 90.6 C 209.2 138.37263086 170.47263086 177.1 122.7 177.1 C 74.9273691396 
                177.1 36.2 138.37263086 36.2 90.6 C 36.2 42.8273691396 74.9273691396 4.1 122.7 4.1 Z" />
        <path class="label-background" d="M 170.2 19.9 H 682.2 V 161.6 H 170.2 V 19.9 Z" />
        <path fill="#DFE8CB" d="M 122.6 11.5 C 166.230495237 11.5 201.6 46.8695047634 201.6 90.5 C 201.6 134.130495237 166.230495237 169.5 122.6 169.5 C 78.9695047634 
                169.5 43.6 134.130495237 43.6 90.5 C 43.6 46.8695047634 78.9695047634 11.5 122.6 11.5 Z" />

        <!-- Male female icon change -->
        <!-- male -->
        <path *ngIf="label.gender == 'M'" class="profile-icon" d="M125,32.8c4.7,0.7,9.1,3.9,11.9,7.7c4,5.2,5.6,12,5.2,18.5c-0.3,5-1.8,10.2-5,14.2c-0.6,0.8-1.3,1.6-2,2.4 c-3.5,3.7-1.9,9,
                3,10.1c1.9,0.4,3.9,0.8,5.9,0.8c10.9,0.2,16.7,10,17,20c0.1,2.2-0.2,4.6-1.2,6.7c-1.1,2.4-2.8,4.1-5.3,4.1 c-10.9,0-21.8,0-32.7,
                0c-5.3,0-10.6,0.1-15.9,0.1c-2.7,0-5.5,0-8.2,0c-2.5,0-5.3,0.4-7.8-0.2c-1-0.2-1.8-0.6-2.6-1.3 c-3-2.5-3.1-7.8-2.8-11.5c0.3-4.1,
                1.7-8.3,4.3-11.6c3-3.8,6.7-5.8,11.4-6.2c2.2-0.2,4.4-0.5,6.5-0.8c2.3-0.3,4.2-1.3,4.9-3.8 c0.7-2.5,
                0.9-4.7-1.1-7c-4.8-5.4-7-11.8-6.6-19c0.1-2.1,0.1-4.2-0.8-6.1c-0.2-0.4,0.7-2.2,0.9-2.6c0.3-0.9,0.7-1.7,1.1-2.6 c0.8-1.6,
                1.7-3.1,2.8-4.5c2.2-2.7,5-4.8,8.2-6.2C118.6,32.9,121.9,32.3,125,32.8z M114.3,79.2c-2.6,7.6-4,8.7-12.3,9.3 c-0.5,0-1,0-1.6,
                0.1c-4.7,0.5-8.8,2.6-11.2,6.7c-2.8,4.8-3.6,10.9-2.5,16.3c0.2,1,0.5,2.1,1.3,2.8c1.2,1,2.7,1,4.2,1 c1.9,0,3.8,0,5.7,0c5.3,0,
                10.5,0,15.8,0c9.2,0,18.4,0,27.6,0c3.7,0,7.4,0,11,0c2.9,0,5.2-0.9,5.8-4c2.1-10.3-2.6-22.3-14.2-22.9 c-6.2-0.3-12.2-0.9-13-8.9c0,
                0.1-4.3,1-4.5,1.1c-2.6,0.4-5.3,0.5-7.9-0.2C118.2,80.3,114.3,79.2,114.3,79.2z M139,50 c-3.3,0.5-6.3-0.1-9.2-1.7c-1.7-0.9-3.2-0.4-4.7,
                0.6c-3.4,2.3-7.1,3.9-11.2,4.7c-2.8,0.5-5.2-1-8.1-1c-1.1,8.9,0.5,16.8,7.3,22.9 c4.5,4,11.4,4.7,16.7,1.8C138.4,72.7,142.2,60.3,139,
                50z M138.2,47.7c-0.9-6.5-8.2-12.7-14.8-13.1c-8.2-0.5-15.8,5.4-18.1,14.3 c2.1,2.3,4.9,3.1,8,2.8c3.8-0.3,7.4-1.8,10.4-4c2.7-2,5.1-2.4,
                8-0.5C133.7,48.5,135.9,48.7,138.2,47.7z" />
        <!-- female -->
        <path *ngIf="label.gender == 'F'" class="profile-icon" stroke-Color="#DFE8CB" stroke-Width="0.45"
            stroke-MiterLimit="10" d="M94.4,89.8c-4.7-2.7-4.7-3.1-1.8-7.1 c3.2-4.5,2.9-9.7,3.1-14.8c0.3-5.7,0-11.3,2-16.8c4-10.9,12.9-16.8,25.7-16.6c10.3,0.2,18.4,6.6,21.7,
                17.3 c1.1,3.4,1.7,6.9,1.6,10.5c-0.2,4.3-0.2,8.7,0.6,13c0.6,3.3,1.3,6.6,3.8,9.2c1.5,1.5,1.2,3-0.7,4c-0.8,0.5-1.7,0.8-2.8,1.3 c8.8,5.5,9.8,
                13.8,8.7,22.9c-0.4,3.2-2.4,4.7-5.6,4.7c-19.6,0-39.2,0-58.8,0c-3.7,0-5.7-2-5.9-5.7c-0.3-5.7-0.6-11.4,3-16.4 C90.5,93.2,92.4,91.5,94.4,
                89.8z M144.4,64.8c0.2-3.6,0.1-7.6-1.1-11.4c-2.1-6.6-5.3-12.3-12.1-15c-12.3-4.8-26.6-0.4-31.6,14.2 c-1.9,5.5-1.2,11.1-1.6,16.6c-0.4,
                5.5-0.3,11-4.1,15.6c-1.2,1.4,0,2,1.2,2.5c4.3,1.8,8.7,1.4,13.1,0.2c3.1-0.8,4.1-6.4,1.7-8.6 c-3.7-3.5-5.8-7.9-6.5-12.9c-0.1-0.9-0.8-2.1,
                0.7-2.3c1.3-0.2,1.1,1.1,1.3,1.8c0.5,2.2,1.1,4.3,2.1,6.3c2.7,5.4,6.7,9.2,13.1,9.6 c7.1,0.4,11.8-3.2,14.4-9.5c2.7-6.5,
                3.6-13-1.3-19.1c-1-1.2-2-2.2-3.6-0.9c-6.2,5.2-14,6.1-21.5,7.9c-2,0.5-4,0.5-6,0.4 c-0.8,0-1.9,0.1-1.9-1.2c0.1-1.3,1.2-1,2-1c6.3,0,
                12.1-1.9,18.1-3.6c2.8-0.8,5.3-2.1,7.6-4c2.5-2.1,3.9-2,6.3,0.3 c1.3,1.2,2.4,2.5,3,4.2c3.4,9,1.5,17-4.8,24.1c-2.2,2.5-1.2,7.6,2,
                8.6c4.5,1.4,9,0.9,13.4-0.5c0.9-0.3,1.5-0.9,0.7-1.9 C144.4,79.1,144.5,72.1,144.4,64.8z M121,115.1c9.6,0,19.3,0,29,0c3.1,0,4.2-1,
                4.4-4.1c0.1-2.2,0.1-4.4,0-6.7 C154,98.2,148.9,90.7,142,91c-2,0.1-4-0.6-6-0.8c-3.6-0.4-5.8-2.3-6.6-5.8c-0.3-1.5-1-2.1-2.6-1.5c-3.6,
                1.3-7.2,1.3-10.8-0.1 c-1.8-0.7-2.6-0.1-3,1.8c-0.6,3.2-2.6,5-5.9,5.5c-1.5,0.2-3.1,0.5-4.6,0.6c-8.9,0.4-12.8,5.2-14.2,12.5c-0.5,2.4-0.3,
                5-0.2,7.5 c0.1,3.4,1.1,4.4,4.6,4.4C102.2,115.1,111.6,115.1,121,115.1z" />
       
        <!-- unknown -->
        <g *ngIf="label.gender == 'U'" ><path fill="#DFE8CB"
            d="M 122.6 11.5 C 166.230495237 11.5 201.6 46.8695047634 201.6 90.5 C 201.6 134.130495237 166.230495237 169.5 122.6 169.5 C 78.9695047634 169.5 43.6 134.130495237 43.6 90.5 C 43.6 46.8695047634 78.9695047634 11.5 122.6 11.5 Z" />
            <path class="profile-icon"
                d="M125,32.8c4.7,0.7,9.1,3.9,11.9,7.7c4,5.2,5.6,12,5.2,18.5c-0.3,5-1.8,10.2-5,14.2c-0.6,0.8-1.3,1.6-2,2.4
                    c-3.5,3.7-1.9,9,3,10.1c1.9,0.4,3.9,0.8,5.9,0.8c10.9,0.2,16.7,10,17,20c0.1,2.2-0.2,4.6-1.2,6.7c-1.1,2.4-2.8,4.1-5.3,4.1
                    c-10.9,0-21.8,0-32.7,0c-5.3,0-10.6,0.1-15.9,0.1c-2.7,0-5.5,0-8.2,0c-2.5,0-5.3,0.4-7.8-0.2c-1-0.2-1.8-0.6-2.6-1.3
                    c-3-2.5-3.1-7.8-2.8-11.5c0.3-4.1,1.7-8.3,4.3-11.6c3-3.8,6.7-5.8,11.4-6.2c2.2-0.2,4.4-0.5,6.5-0.8c2.3-0.3,4.2-1.3,4.9-3.8
                    c0.7-2.5,0.9-4.7-1.1-7c-6.9-7.6-9.3-20.1-5.3-29.6C108.1,37.7,116.3,31.5,125,32.8z
                    M114.3,79.2c-2.6,7.6-4,8.7-12.3,9.3
                    c-0.5,0-1,0-1.6,0.1c-4.7,0.5-8.8,2.6-11.2,6.7c-2.8,4.8-3.6,10.9-2.5,16.3c0.2,1,0.5,2.1,1.3,2.8c1.2,1,2.7,1,4.2,1
                    c1.9,0,3.8,0,5.7,0c5.3,0,10.5,0,15.8,0c9.2,0,18.4,0,27.6,0c3.7,0,7.4,0,11,0c2.9,0,5.2-0.9,5.8-4c2.1-10.3-2.6-22.3-14.2-22.9
                    c-6.2-0.3-12.2-0.9-13-8.9c0,0.1-4.3,1-4.5,1.1c-2.6,0.4-5.3,0.5-7.9-0.2C118.2,80.3,114.3,79.2,114.3,79.2z" />
            <path fill="#DFE8CB"
                d="M 122.6 34.9 C 132.099297697 34.9 139.8 44.6601924537 139.8 56.7 C 139.8 68.7398075463 132.099297697 78.5 122.6 78.5 C 113.100702303 78.5 105.4 68.7398075463 105.4 56.7 C 105.4 44.6601924537 113.100702303 34.9 122.6 34.9 Z" />
        </g>
        
      <g transform="translate(42, 13)" class="element-over-hand" (click)="addImage(label)">
        <image [attr.href]="label.profileImage + timeTag" class="profile-image" width="163" height="163" (error)="updateFaultUrl()"/>
        <clipPath id="clipping" >
            <circle cx="110" cy="110" r="80" />
          </clipPath>
      </g> 
      
        <!-- plus button -->        
        <path class="element-over-hand" (click)="expandWheel(label)" fill="#C2C7AE"
            d="M25,101.5v-7.8h-7.9v-5.4H25v-7.8h5.3v7.8h7.9v5.4h-7.9v7.8H25z" />
            
        <!-- Camaera button -->
        <!-- <g class="element-over-hand" (click)="openMultiMedia(label)" style="opacity: 0.7;"> 
            <path fill="#D3DBC0" d="M192.8,126.6c-13,25.6-39.6,43.2-70.2,43.2c-30.7,0-57.2-17.5-70.2-43.2H192.8z" />
            <path class="button-camera"
                d="M124.1,158.1c-3.2,0-6.4,0-9.6,0 c-1.4,0-2.3-0.8-2.9-2c-0.3-0.6-0.4-1.2-0.4-1.9c0-4,0-8,0-12.1c0-1.8,1.3-3.3,3-3.5c0.3,0,0.6-0.1,0.8-0.1c0.7,0,1.5,0,2.2,0 c0.2,0,0.2-0.1,0.3-0.2c0.3-0.9,0.7-1.7,1.3-2.4c0.7-0.8,1.5-1.2,2.6-1.2c1.9,0,3.8,0,5.7,0c0.8,0,1.4,0.3,2,0.8 c0.9,0.7,1.4,1.7,1.8,2.8c0.1,0.3,0.2,0.3,0.4,0.3c0.7,0,1.5,0,2.2,0c1.7,0,3.2,1.2,3.6,2.8c0.1,0.3,0.1,0.6,0.1,0.9 c0,4,0,8.1,0,12.1c0,1.7-1.2,3.2-2.8,3.6c-0.2,0.1-0.5,0.1-0.7,0.1C130.4,158.1,127.3,158.1,124.1,158.1z M124.1,156.5 C124.1,156.5,124.1,156.5,124.1,156.5c3.1,0,6.3,0,9.4,0c0.1,0,0.3,0,0.4,0c0.9-0.2,1.5-1,1.5-2c0-1.1,0-2.1,0-3.2c0-3,0-5.9,0-8.9 c0-1.3-1-2.1-2.1-2.1c-1,0-2.1,0-3.1,0c-0.5,0-0.8-0.2-0.9-0.7c-0.1-0.2-0.1-0.4-0.2-0.7c-0.3-0.8-0.6-1.5-1.2-2.1 c-0.3-0.3-0.7-0.4-1.1-0.4c-1.8,0-3.6,0-5.4,0c-0.8,0-1.4,0.3-1.8,1c-0.5,0.7-0.7,1.4-0.9,2.2c-0.1,0.5-0.4,0.7-0.9,0.7 c-1.1,0-2.1,0-3.2,0c-1,0-1.7,0.5-1.9,1.5c0,0.2-0.1,0.3-0.1,0.5c0,4.1,0,8.1,0,12.2c0,1.3,0.8,2.1,2.1,2.1 C117.9,156.5,121,156.5,124.1,156.5z" />
            <path class="button-camera"
                d="M118.6,148.3c0-3.1,2.5-5.5,5.5-5.5 c3.1,0,5.5,2.5,5.5,5.6c0,3.1-2.5,5.5-5.6,5.5C121.1,153.9,118.6,151.4,118.6,148.3z M120.2,148.3c0,2.2,1.7,3.9,3.9,3.9 c2.1,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C122,144.4,120.2,146.2,120.2,148.3z" />
        </g> -->
       
        <!-- Label name -->
        <!-- male -->
        <text id="index_{{label.displayIndex}}" *ngIf="label.gender == 'M'" transform="translate(230,105)"
        class='text-male male-color element-over-hand' height="10" (click)="changeRootPerson(label)">
        <!-- Todo: FInd a way to print TSpans without setting Y Axix -->
        <tspan  x="0" [attr.y]="splitDisplayName[0].spany" >
            {{splitDisplayName[0]?.name}}
        </tspan>
        <tspan *ngIf="splitDisplayName[1]?.name" x="0" [attr.y]="splitDisplayName[1].spany">
            {{splitDisplayName[1]?.name}}
        </tspan>
        </text>
        <!-- female -->
        <text id="index_{{label.displayIndex}}" *ngIf="label.gender == 'F'" transform="translate(230,105)"
            class='text-female female-color element-over-hand' (click)="changeRootPerson(label)">
            <!-- Todo: FInd a way to print TSpans without setting Y Axix -->
            <tspan  x="0" [attr.y]="splitDisplayName[0].spany" >
                {{splitDisplayName[0]?.name}}
            </tspan>    
            <tspan *ngIf="splitDisplayName[1]" x="0" [attr.y]="splitDisplayName[1].spany">
                {{splitDisplayName[1]?.name}}
            </tspan>
        </text>

        <!-- Unknown --> 
        <text id="index_{{label.displayIndex}}" *ngIf="label.gender == 'U'" transform="translate(230,105)"
            class='text-unknown unknown-color element-over-hand' (click)="changeRootPerson(label)">
            <!-- Todo: FInd a way to print TSpans without setting Y Axix -->
            <tspan  x="0" [attr.y]="splitDisplayName[0].spany" >
                {{splitDisplayName[0]?.name}}
            </tspan>    
            <tspan *ngIf="splitDisplayName[1]" x="0" [attr.y]="splitDisplayName[1].spany">
                {{splitDisplayName[1]?.name}}
            </tspan>
        </text>

        <!-- Edit buttons -->        
        <image class="element-over-hand button-edit" height="40" width="40" href="assets/tree-editor/btn_edit.svg" (click)="editMember(label)" />
    </g>

    <!-- 1,2,3 level labels disable -->
    <g *ngIf="i < 7 && label.status=='disable'" [attr.id]="label.displayIndex" height="1000" width="1000"
        transform="translate(25,-5) scale(1 1)" class="element-over-hand" (click)="addParent(i, label)">
        <g *ngIf="label.displayIndex == 2.02" transform="translate(-240,74) scale(1 1)" class="custom-cursor-context-menu no-pointer-events">  
            <image x="304" y="0" height="175" width="175" href="assets/tree-editor/level_3/leave_3_top_grey.svg" />
        </g> 
        <g *ngIf="label.displayIndex == 2.03" transform="translate(-250,-146) scale(1 1)" class="custom-cursor-context-menu no-pointer-events">  
            <image x="313" y="0" height="175" width="175" href="assets/tree-editor/level_3/leave_3_bottom_grey.svg" />
        </g> 
        <g *ngIf="label.displayIndex == 2" transform="translate(-226,74) scale(0.95 0.95)" class="custom-cursor-context-menu no-pointer-events">  
            <image x="304" y="0" height="175" width="175" href="assets/tree-editor/level_2/leave_2_top_grey.svg" />
        </g> 
        <g *ngIf="label.displayIndex == 2.01" transform="translate(-237,-146) scale(0.95 0.95)" class="custom-cursor-context-menu no-pointer-events">  
            <image x="313" y="0" height="175" width="175" href="assets/tree-editor/level_2/leave_2_bottom_grey.svg" />
        </g> 
        <g *ngIf="label.displayIndex == 1" transform="translate(0,75) scale(2.2 2.2)" class="custom-cursor-context-menu no-pointer-events">  
            <image x="0" y="0" height="175" width="90" href="assets/tree-editor/level_1/leave_1_top_grey.svg" />
        </g> 
        <g *ngIf="label.displayIndex == 1.01" transform="translate(0,-365) scale(2.2 2.2)" class="custom-cursor-context-menu no-pointer-events">  
            <image x="0" y="0" height="175" width="90" href="assets/tree-editor/level_1/leave_1_bottom_grey.svg" />
        </g> 
        <image height="105" width="303" href="assets/tree-editor/lbl_level_1_disable.svg"  class="custom-cursor-context-menu no-pointer-events"/>
        <text transform="translate(25,62)" fill="#c1c2bd" font-family="Arial" font-size="35">+</text>
        <text *ngIf="i%2 == 0" class="text-disable" transform="translate(63,55)">{{"lbl_add_mother" | translate}}</text>
        <text *ngIf="i%2 !== 0" class="text-disable" transform="translate(63,55)">{{"lbl_add_father" | translate}}</text>
    </g>

</svg>
