<div class="list-content" [ngStyle]="{'height' : (items.length==0)? 'auto' : '100%'}"> 
    <div class = "list-container">
        <div class="item no-record" *ngIf="items.length==0">
            <div>
                <span>{{"lbl_no_family_project"|translate}}</span>
            </div>
        </div>
      <div *ngFor="let item of getSortedMediaItems() trackBy: trackMedia" [ngClass]="{'disabled' : item.isDisabled}" [ngStyle] = "{'pointer-events': 'pointer'}">
        <div [ngClass] = "item.isSelected ? 'item-selected' : 'item'" [ngStyle]="{'opacity' : (item.isDisabled )? '0' : '1'}">
        <div style="background-color: #A3CC88;" >  
              <img class="item-image" [src]="item.url" loading="lazy" (error)='onItemError($event)'>
          </div>
          <div class="item-content">
              <span *ngIf="item.caption" class="label-m-grey text-ellipsis-1" [ngClass]="{'text-disabled' : item.isDisabled}" [ngStyle]="{'opacity' : (item.isDisabled )? '0.5' : '1'}">{{item.caption}}</span>
              <span *ngIf="item.type" class="label-xs-grey text-ellipsis-1" [ngClass]="{'text-disabled' : item.isDisabled}" [ngStyle]="{'opacity' : (item.isDisabled )? '0.5' : '1'}">{{item.type}}</span>
              <span *ngIf="item.size" class="label-xs-grey text-ellipsis-1" [ngClass]="{'text-disabled' : item.isDisabled}" [ngStyle]="{'opacity' : (item.isDisabled )? '0.5' : '1'}">{{item.size}}</span>
          </div>
          <div class="item-options">
            <img *ngIf="item.isSelected" class="menu-edit-icon-1" src="assets/images/icons/photo-selected.svg">
              <app-drop-menu *ngIf="item.dropMenu != null && (item.isEditEnable)" [menu]="item.dropMenu" (action)="clickedAction($event)"></app-drop-menu>            
          </div>
        </div>  
          <div *ngIf="item.isDisabled" [ngClass] = "item.isSelected ? 'item-selected' : 'item'" style="margin-top: -80px; background-color: #ffffff;" [ngStyle]="{'opacity' : (item.isDisabled )? '1' : '0'}">
            <div style="background-color: #ffffff;" >  
                <img class="item-image" [src]="loadingImg" loading="lazy" (error)='onItemError($event)'>
            </div>
            <div class="item-content">
                <span *ngIf="item.caption && item.mediaType==1" class="label-m-grey text-ellipsis-1" [ngClass]="{'text-disabled' : item.isDisabled}" style="overflow: unset;">{{"lbl_importing_file" | translate}} {{item.caption}}</span>
                <span *ngIf="item.caption && item.mediaType==2" class="label-m-grey text-ellipsis-1" [ngClass]="{'text-disabled' : item.isDisabled}" style="overflow: unset;">{{"lbl_exporting_file" | translate}} {{item.caption}}</span>
                <span class="label-m-grey text-ellipsis-1 processing-message" [ngClass]="{'text-disabled' : item.isDisabled}" style="overflow: unset;">({{"lbl_processing_message" | translate}})</span>
            </div>
            <div class="item-options">
              <img *ngIf="item.isSelected" class="menu-edit-icon-1" src="assets/images/icons/photo-selected.svg">
              <app-drop-menu *ngIf="item.dropMenu != null && (item.isEditEnable)" [menu]="item.dropMenu" (action)="clickedAction($event)"></app-drop-menu>            
            </div>
          </div>
      </div>
    </div>
  </div>