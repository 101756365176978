import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FamilyBase } from 'src/app/components/familyeditor/models/family-base';
import { ApiBaseService } from '../api-base.service';
import { DefaultFamilyRequest, FamilyComponentModel } from 'src/app/components/familyeditor/models/family-component-model';

@Injectable({
  providedIn: 'root'
})
export class FamilyApiService {

  constructor(private apiBaseService: ApiBaseService) {

  }

  //#region CRUDs

  // update family
  update(family: FamilyComponentModel): Observable<any> {
    return this.apiBaseService.update('/api/family', null, family);
  }

  // create family
  create(family: FamilyComponentModel): Observable<any> {
    return this.apiBaseService.create('/api/family', null, family);
  }

  setAsDefaultFamily(defaultFamilyRequest: DefaultFamilyRequest): Observable<any> {
    return this.apiBaseService.update('/api/family/set-default', null, defaultFamilyRequest);
  }

  // #endregion


  //#region Gets
  // get family
  getCompositeById<T>(familyId: number): Observable<T> {
    return this.apiBaseService.get('/api/family/' + familyId + '/composite', null, null);
  }

  // get family on base
  getBaseById<T>(familyId: number): Observable<T> {
    return this.apiBaseService.get('/api/family/' + familyId, null, null);
  }

  // get parent and spouse families
  getFamiltyWithRelationsById<T>(memberId: number): Observable<T> {
    return this.apiBaseService.get('/api/family/member/' + memberId + '/all-relations', null, null);
  }

  getParentsFamiliesInfo<T>(memberId: number): Observable<T> {
    return this.apiBaseService.get('/api/family/member/' + memberId + '/parent-relations', null, null);
  }

  getSpouseFamiliesInfo<T>(memberId: number): Observable<T> {
    return this.apiBaseService.get('/api/family/member/' + memberId + '/spouse-relations', null, null);
  }

  getExistingDefaultFamilies<T>(familyId: number): Observable<T> {
    return this.apiBaseService.get('/api/family/' + familyId + '/default-family-exist', null, null);
  }

  getAscendantsIds<T>(familyId: number): Observable<T> {
    return this.apiBaseService.get('/api/family/' + familyId + '/ascendents', null, null);
  }

  getDescendantsIds<T>(memberId: number): Observable<T> {
    return this.apiBaseService.get('/api/family/member/ ' + memberId + '/decendents', null, null);
  }

  getDefaultFamilyByIndividualId<T>(individualId: number): Observable<T> {
    return this.apiBaseService.get('/api/family/default/' + individualId, null, null);
  }
  //#endregion

  //update family children
  updateChildRelationship(model: FamilyBase): Observable<any> {
    return this.apiBaseService.update('/api/family/update-child-relationship', null, model);
  }
}
