/*
* Font utility for measuring width of font in a line and dividing to lines based on the given width
*/
import { Injectable } from "@angular/core";
@Injectable({
  providedIn : 'root'
})

export class ReportFont {
    fontLengths : Map<number, any>;
    
    constructor(){
      this.fontLengths = new Map();
    }

    init(fontsizes){   
      var keys = Object.keys(fontsizes);
      for(var x=0;x < keys.length; x++ )
      {
        this.fontLengths.set(parseInt(keys[x]),fontsizes[keys[x]]);        
      };
    }

    initSize(fontSize :number, fontLength){
        if ( !this.fontLengths ){
          this.fontLengths = new Map();
        }
        this.fontLengths.set(fontSize,fontLength);
    }

    getLength(fontSize :number, str){        
        var length = 0;
        for(var x=0;x< str.length;x++){          
          length += this.fontLengths.get(fontSize)[str.charCodeAt(x)-32];
        }    
        return length;
    }

    getString(str: string, fontSize: number, lenght: number, noOfLines : number, breakWords : boolean){      
      str = str.toString().trim(); //some scenarios str coming as an object and gave errors when str.trim()
      var retStr = [];
      var textCharacters = Array.from(str);
      var textCharacterPos = 0;
      var tempStr = "";

      // dynamic var
      var lineCount         = 1;      // line counter
      var isNewLine         = false;   // Move to new line
      var lastSpacePos      = 0;     // last space pos in sentence
      var lastSpacePosTspan = 0;     // last space pos in current line
    
      while( textCharacterPos < textCharacters.length){
    
        if ( isNewLine ){                    
          retStr.push(tempStr);
          tempStr = "",
          isNewLine = false;      
          lineCount++;
        }
    
        // Check the last space pos
        if ( textCharacters[textCharacterPos] == ' '){
          lastSpacePos = textCharacterPos;
          lastSpacePosTspan = tempStr.length;
        }
        
        tempStr += textCharacters[textCharacterPos];
        textCharacterPos++;
    
        // Check the width of the box
        if ( this.getLength(fontSize, tempStr) > lenght ){      
          
          if ( lineCount >= noOfLines){
            //put ellipsis and exit  
            tempStr = this.getAdjustedText(tempStr, fontSize, lenght);
            retStr.push(tempStr);
            return retStr;
          }  

          // last space position is 0 on the tempstr mean the length cannot be statisfied return current
          if ( lastSpacePosTspan == 0){
            tempStr = this.getAdjustedText(tempStr, fontSize, lenght);   
            retStr.push(tempStr);
            return retStr;
          }
          // reset back to the last space          
          textCharacterPos = lastSpacePos+1;  
          
          tempStr = tempStr.slice(0,lastSpacePosTspan);
          lastSpacePosTspan = 0;
          isNewLine = true;
        } // end width check
      }  // end while
      retStr.push(tempStr);
      return retStr;
    }

  // Adjust the text to fit the width with ellipsis
  getAdjustedText(str: string, fontSize: number, length: number): string {
    const ellipsis = "...";
    const ellipsisLength = this.getLength(fontSize, ellipsis);

    while (this.getLength(fontSize, str) + ellipsisLength > length) {
      str = str.slice(0, str.length - 1);
    }

    return str + ellipsis;
  }
}