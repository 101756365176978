import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, EventEmitter, Output } from '@angular/core';
import { reference } from '@popperjs/core';
import { IndividualViewBase } from 'src/app/common/models/individual/individual-view-base';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { DialogBaseComponent } from '../../dialog/dialog-base/dialog-base.component';
import { DropMenu } from '../../drop-menu/models/DropMenu';
import { MediaGalleryItem } from '../../media-gallery/models/media-gallery-item';
import { IndividualApiService } from 'src/app/services/API/individual-api.service';
import { IndividualEventBase } from 'src/app/common/models/individual/individual-event-base';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { DropMenuAction, FamilyIndividuals, PopupOpenMode, EditorMode, NotifierEvents } from 'src/app/common/enums/enums';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { FamilyIndividual } from 'src/app/components/familyeditor/models/family-individual';
import { PopupOptions } from 'src/app/common/helpers/menulist/popup-options';
import { DropMenuItem } from '../../drop-menu/models/DropMenuItem';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';

@Component({
  selector: 'app-individuals',
  templateUrl: './individuals.component.html',
  styleUrls: ['./individuals.component.scss']
})

export class IndividualsComponent extends DialogBaseComponent implements OnInit {

  individuals: IndividualViewBase[];
  mediaItems: MediaGalleryItem[];
  searchPhrase: string = "";
  title: string;
  relationshipMode: FamilyIndividuals;
  selectedIndividual: IndividualViewBase;
  selectedIndividualCaption: string;

  constructor(private ds: DialogService,
    private ref1: ChangeDetectorRef,
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private individualApiService: IndividualApiService,
    private notifierService : NotifierV2Service) {
    super(ds, ref1);
  }

  ngOnInit(): void {   
    this.data.options = PopupOptions.options.find(t => t.mode == (this.data?.mode ?? PopupOpenMode.Popup)) || PopupOptions?.options[0];
    this.data.options.primaryTabName = "lbl_persons_list";
    this.getIndividualList();
    this.mapUI(this.data);
  }

  getIndividualList() {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.individualApiService.getIndividualList(1, 1).subscribe((response: ResponseModel<IndividualViewBase[]>) => {
      this.individuals = response.data;
      this.mediaItems = this.individuals.map(i => ({
        id: 0, reference: i.id, caption: i.displayName, url: i.profileImageUrl == null ?
          (i.gender == "M" ? "assets/images/Male.png" : i.gender == "F" ? "assets/images/Female.png" : "assets/images/unknown.svg") : i.profileImageUrl, isSelected: false, isEditEnable: false, dropMenu: this.createDropMenu(i)
      }));
    }, (err) => {
      throw new CustomError("IndividualComponent => getIndividualList() : " + err, 911, false);
    }).add(() => {
      this.loadingService.hide(processId);
    });
  }

  createDropMenu(ref: IndividualViewBase) {
    let dropMenu = new DropMenu();
    dropMenu.type = 1;
    dropMenu.ref = ref;
    dropMenu.menuItems = [
      <DropMenuItem>{ action: DropMenuAction.Edit, isEnabled: true, isVisible: true }
    ];
    return dropMenu;
  }  

  dropMenuAction(event: any) {         
    if (event.action == DropMenuAction.Edit) {     
      let data = {
        id : event.reference.id,
        editorMode : EditorMode.IndividualEditor, 
        isUpdateHistory: true
      }
      this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, event.reference);
      this.notifierService.setCurrentEditor(`/editor/individualeditor`,  data, null);
      this.close();       
    }
  }

  mapUI(data: any) {
    this.title = data.title;
    this.relationshipMode = data.relationshipMode;
  }

  addIndividual() {
    let selectedindividual = this.mediaItems.find(s => s.isSelected === true);
    let individual = this.individuals?.find(p => p.id === selectedindividual?.reference ?? 0) ?? { id: 0, profileImageUrl: "", gender: "", displayName: "" };

    if (this.relationshipMode == FamilyIndividuals.Parent) {
      this.complete({ data: individual });
    }
    else {
      this.dialogService.open("Relationship", {
        id: 0,
        title: this.title,
        relationshipMode: FamilyIndividuals.Child,
        individualInfo: {
          individualId: individual.id,
          profileImageUrl: individual.profileImageUrl == null ? 
          (individual.gender == "M" ? "assets/images/Male.png" : individual.gender == "F" ? "assets/images/Female.png" : "assets/images/unknown.svg") : individual.profileImageUrl,
          displayName: individual.displayName
        }
      }).subscribe(response => {
        if (!response.data) {
          return null;
        }

        //Create new child object
        let newChild = new FamilyIndividual();
        newChild.id = individual.id;
        newChild.relationShipType = response.data.relationShipType;
        newChild.profileImageUrl = individual.profileImageUrl == null ?
          (individual.gender == "M" ? "assets/images/Male.png" : individual.gender == "F" ? "assets/images/Female.png" : "assets/images/unknown.svg") : individual.profileImageUrl;
        newChild.isDefault = response.data.isDefault;
        newChild.displayName = individual.displayName;
        newChild.birthEvent = response.data.birthEvent;
        this.complete({ data: newChild });
      })
    }
  }

  onSearchChange() {
  }

  setEnablity() {
    let isEnabled = this.mediaItems?.find(s => s.isSelected === true)?.isSelected ?? false;
    this.selectedIndividualCaption = this.mediaItems?.find(s => s.isSelected === true)?.caption;
    return isEnabled;
  }

  isShowMode(mode): number {
    if ( !this.data.showMode ) this.data.showMode = [mode];
    return this.data.showMode.includes(mode);
  }
}
