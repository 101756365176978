import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConstatnts } from '../common/constants/app-contstants';
import { EditorMode, NotifierEvents } from '../common/enums/enums';
import { EditorReferenceInfo } from '../common/models/editors/editor-reference-info';
import { RootMemberResponse } from '../common/models/RootMember';
import { ComponentBase } from '../components/common/base/component-base';
import { FamilyApiService } from './API/family-api.service';
import { IndividualApiService } from './API/individual-api.service';
import { HistoryService } from './history.service';
import { ResponseModel } from '../common/models/responseModel';
import { RootFamily } from '../common/models/RootFamily';

@Injectable({
  providedIn: 'root'
})
export class NotifierV2Service {

  // Set the current editor when editor changed
  currentEditorMode : EditorMode;
  currentTitleTranslateCode : string;

  componentsList: ComponentBase[] = [];
  componentName: string;
  
  rm = AppConstatnts.routerModes;
  hideSearchFor: any[] = [this.rm.FamilyEditor,
  this.rm.IndividualEditor,
  this.rm.Multimedia,
  this.rm.Individuals,
  this.rm.Place,
  this.rm.Source];

  constructor(
    private router: Router,
    private historyService: HistoryService,
    private individualApiService: IndividualApiService,
    private familyApiService: FamilyApiService,
    private translateService : TranslateService) {
    this.currentTitleTranslateCode = 'resources.lbl_tree_editor';
    this.initEditors();
  }

  //#region notifier service basics

  public registerComponent(component: ComponentBase) {
    this.componentsList.push(component);
  }

  public removeComponent(component: ComponentBase) {
    var index = this.componentsList.indexOf(component);
    if (index > -1) {
      this.componentsList.splice(index, 1);
    }
  }

  // setCurrentState  
  setCurrentEditor(newEditorName: string, componentParameters: any, stateParameters: any) {
    this.router.navigate([newEditorName, componentParameters.id, true]);
  }

  /**
   *  Subheader - editors management & general editor changes 
   */

  /* Initiatlize listeing to editor changes and update the current editor */
  initEditors(){
    this.router.events.subscribe( (evt)=>{
      if ( evt instanceof NavigationStart){
        if ( !this.router.navigated ){
          console.log("Refreshed", this.currentEditorMode);
        }
      }
      if ( evt instanceof NavigationEnd){

        let eURL = AppConstatnts.editorURLs.find( eURL => evt.url.indexOf( eURL.url ) >= 0);        
        if ( eURL ){
          this.currentEditorMode = eURL.mode;
        }       

        let routeData = AppConstatnts.routers.find( e => evt.url.indexOf( e.url ) >= 0 );
        if ( routeData ){
          this.currentTitleTranslateCode = routeData.translate;
        }        
      }      
    });
  }

  /* reference is usually an ID, individual id, family id..etc */
  openEditor(mode: EditorMode, reference: EditorReferenceInfo) {
    //TODO: Refactor to "memberEditor/:id" required parameter https://stackoverflow.com/questions/44864303/send-data-through-routing-paths-in-angular
    this.router.navigate([AppConstatnts.routerModes[EditorMode[mode]], reference.id, reference.isBreadcrumbUpdate]);
    //this.router.navigate([AppConstatnts.routerModes[EditorMode[mode]], { data: JSON.stringify(reference)}])change @EM-10562
  }

  /* Get the current editor */
  getCurrentEditor(): EditorMode{
    return this.currentEditorMode;
  }

  getCurrentTitle():string{
    //console.log(this.currentTitleTranslateCode);
    return this.translateService.instant(this.currentTitleTranslateCode);
  }
  
  async getDefaultFamily(notifierEvent: NotifierEvents, data: any) {
    //get default family by family id scenarios
    if (notifierEvent === NotifierEvents.RootFamilyChanged || notifierEvent === NotifierEvents.DefaultFamilyChanged || notifierEvent === NotifierEvents.RootFamilyUpdated) {
      const response = await this.familyApiService.getBaseById(data.id ?? data.familyId).toPromise();
      return response;
    }

    //get default family by individual id scenarios
    else if (notifierEvent === NotifierEvents.RootMemberChanged || notifierEvent === NotifierEvents.RootMemberUpdated) {
      const response = await this.familyApiService.getDefaultFamilyByIndividualId(data.id ?? data.Id).toPromise();
      return response;
    }
  }

  async getNextRootMemberData() {
    const rootPersonList = this.historyService.getIndividualList();
    const listLength = rootPersonList.length;
    if (listLength > 0) {
      const selectedPerson = rootPersonList[listLength - 1];
      return selectedPerson;
    } else {
      //Set First Person to root member
      this.individualApiService.getFirstPerson().subscribe(async (response: any) => {
        await response;
        return response.data;
      });
    }
  }

  /**
   * When changing root member or root family,
   * have to update the history from historyService
   * RootMemberDeleted should be evaluated first and then update the history
   */
  async updateEvent(notifierEvent: NotifierEvents, data: any) {
    let path = "";

    if (notifierEvent === NotifierEvents.RootMemberDeleted) {
      const selectedPersonId = this.getCurrentRootMemberId();
      this.historyService.removeIndividual(data);

      if (data.toString() === selectedPersonId.toString()) {
        data = await this.getNextRootMemberData();
        notifierEvent = NotifierEvents.RootMemberChanged;
      }
      path = "/report/treeeditor";
    }

    //check if the default family is needed and set default family as the root family of the data
    if (NotifierEvents.isDefaultFamilyRequired(notifierEvent)) {
      await this.getDefaultFamily(notifierEvent, data).then((res: ResponseModel<RootFamily>) => {
        data.rootFamily = res?.data;
      });
    }

    // Handling root member change
    if (notifierEvent === NotifierEvents.RootMemberChanged) {
      this.historyService.addSelectedIndividual(this.createRootMember(data));
      this.historyService.addSelectedFamily(data.rootFamily);
    }

    if (notifierEvent === NotifierEvents.RootMemberUpdated) {
      this.historyService.addSelectedIndividual(this.createRootMember(data));
      this.historyService.addSelectedFamily(data.rootFamily);
    }

    // Handling Root Family change
    if (notifierEvent === NotifierEvents.RootFamilyChanged) {
      this.historyService.addSelectedFamily(data.rootFamily);
    }

    // Handling Default Family change
    if (notifierEvent === NotifierEvents.DefaultFamilyChanged) {
      this.historyService.addSelectedFamily(data.rootFamily);
    }

    // Handling Family details change
    if (notifierEvent === NotifierEvents.RootFamilyUpdated) {
      this.historyService.addSelectedFamily(data.rootFamily);
    }

    let notifyEvents: NotifierEvents[] = [];
    notifyEvents.push(notifierEvent);
    this.notifyObservers(notifyEvents);

    return path;
  }

  public canChange(): boolean {
    let canChange = true;
    this.componentsList.forEach(component => {
      if (!component.canChange())
        canChange = false;
    });

    return canChange;
  }

  //call each component notify() if the component.expectedUpdates matches the notifierEvent
  public notifyObservers(event: NotifierEvents[]) {
    this.componentsList.forEach(component => {
      if (component.expectedUpdates && component.expectedUpdates.filter(s => event.find(t => t === s)) !== undefined) {
        component.notify();
      }
    });
  }

  //#endregion

  //#region History Service Integrations.

  public getCurrentRootMemberId(avoidDefault: boolean = false) {
    let selectedIndividualId = this.historyService.getSelectedIndividual()?.Id;
    if(avoidDefault)
      return selectedIndividualId;
    return selectedIndividualId ?? 1;
  }

  public getCurrentRootFamilyId(avoidDefault: boolean = false) {
    let selectedFamilyid = this.historyService.getSelectedFamily()?.id;
    if(avoidDefault)
      return selectedFamilyid;
    return selectedFamilyid ?? 1;
  }

  public addToHistory(data: any) {
    if (data.editorMode === EditorMode.FamilyEditor) {
      this.addFamilyHistory(data.id);
    }
    if (data.editorMode === EditorMode.IndividualEditor) {
      this.addIndividualHistory(data.id);
    }
  }


  addFamilyHistory(familyId: any) {
    if (familyId > 0) {
      this.familyApiService.getBaseById(familyId).subscribe((familyData: any) => {
        this.historyService.addSelectedFamily(familyData.data);
        this.updateEvent(NotifierEvents.RootFamilyChanged, familyData.data);
      });
    }
  }

  addIndividualHistory(individualId: any) {
    this.individualApiService.getIndividualById(individualId).toPromise().then((individualData: any) => { //2023/01/04 - async added, unless data not set correctly due to latancy of the api
      this.addSelectedIndividualAndFamilyInfo(individualData.data);
      this.updateEvent(NotifierEvents.RootMemberChanged, individualData.data);
    });
  }

  private addSelectedIndividualAndFamilyInfo(individual: any) {
    let rootMember = this.createRootMember(individual);
    this.historyService.addSelectedIndividual(rootMember);
    this.historyService.addSelectedFamily(individual.apiResponse);
  }
  
  private createRootMember(data: any) {
    let rootMember = new RootMemberResponse();

    rootMember.DisplayName = data.displayName ?? data.DisplayName;
    rootMember.FirstName = data.givenName?? data.FirstName;
    rootMember.LastName = data.surname?? data.LastName;
    rootMember.Id = data.Id ?? data.id;
    rootMember.defaultFamilyId = data?.defaultFamilyId;
    rootMember.husbandDisplayName = data?.husbandDisplayName;
    rootMember.wifeDisplayName = data?.wifeDisplayName;

    return rootMember;
  }

  public getCurrentRootMember() {
    return this.historyService.getSelectedIndividual();   
  }
  //#endregion
}
