import { Component, ElementRef, OnInit, AfterViewChecked } from "@angular/core";
import { RootMember } from 'src/app/common/models/RootMember';
import { NotifierV2Service } from "src/app/services/notifier-v2.service";
import { DialogService } from "src/app/services/UI/dialog.service";
import { ComponentBase } from '../../../common/base/component-base';
import { ReportOptions } from "../../common/reportOptions/reportOptions";
import { Subject } from "rxjs";
import { NotifierEvents } from "src/app/common/enums/enums";
import { IndividualRequestModel } from "src/app/common/models/individual/individual-request-model";
import { ChildBase } from "src/app/common/models/child/child-base";
import { ResponseModel } from "src/app/common/models/responseModel";
import { IndividualBaseViewModel } from "src/app/common/models/IndividualViewModel";
import { AddParentDialogResponseModel } from "src/app/common/models/parent/add-parent-dialog-response-model";
import { EditorMode } from "src/app/common/enums/enums";
import { TranslateHandler } from "src/app/common/helpers/translate-handler";
import { MessageDialogService } from "src/app/components/common/message-dialog/services/message-dialog.service";
import { IndividualApiService } from "src/app/services/API/individual-api.service";

@Component({
  selector: "app-base",
  templateUrl: "./base.component.html",
  styleUrls: ["./base.component.scss"],
})
export class BaseComponent extends ComponentBase implements OnInit, AfterViewChecked {

  dynamicThemeUrl: any;
  commonUrl: any;
  reportName: any;
  rootMember: RootMember;
  memberId: number;

  reportTranslationObj = {
    translateReportAttribute: "",
    viewBoxReportAttribute: "",
    hide: true,
    viewableSVGAreaWidth: 0,
    viewableSVGAreaHeight: 0,
  };
  chartBasics: any;
  config: any;
  showReport = false;
  hideToolTip = true;
  protected rootMemberValue$: Subject<boolean> = new Subject<boolean>();
  rootMemberChanges$ = this.rootMemberValue$.asObservable();
  reportTitle: string;

  constructor(
    private hostElement             : ElementRef,
    protected dialogService         : DialogService,
    protected notifierService       : NotifierV2Service,
    protected translateHandler?     : TranslateHandler,
    protected messageDialogService? : MessageDialogService,
    protected individualApiService? : IndividualApiService) {
    super(notifierService);
  }

  ngOnInit(): void { }

  ngAfterViewChecked(): void {
    if (this.reportTranslationObj.hide) {
      let reportArea: any = this.hostElement.nativeElement.querySelector(
        "#reportArea"
      );
      if (reportArea && reportArea.getBBox().width) {
        this.reportAfterViewInitialization();
      }
    }
  }
  // Open report option dialog 
  getReportOptionsSubscription(reportOptions) {
    return this.dialogService.setHalf().open("ReportOptionPopup", reportOptions);
  }

  // evaluate ts map object and dynamically assign values to relavant fields in config  
  mapConfig(dataMap){
    for(const [key,value] of dataMap.entries()){
      this.config.options[key] = value;
    }
  }

  reportAfterViewInitialization() { }

  // This is to show report options. This method has been overrideen
  reportZoomToExtend() { }

  //It is used to open "add mother or add father" popup
  addParent(parentGender: string, child: ChildBase): Promise<AddParentDialogResponseModel> {
    const title     = this.translateHandler.translate(parentGender === "F" ? 'lbl_mother_of' : 'lbl_father_of', [child.name]);
    const subTitle  = this.translateHandler.translate(parentGender === "F" ? 'lbl_mothers_name' : 'lbl_fathers_name', [child.name]);

    return new Promise((resolve, reject) => {
      this.messageDialogService.openIndividualDialog(title, subTitle, child.familyId, child.id,1).toPromise().then(response => {
        if (response) {
          const individualRequestModel                = new IndividualRequestModel();
          individualRequestModel.gender               = parentGender;
          individualRequestModel.memberName           = response.name;
          individualRequestModel.relatedFamilyId      = child.familyId;
          individualRequestModel.relatedMemberId      = child.id;

          this.individualApiService.createParent(individualRequestModel).subscribe((member: ResponseModel<IndividualBaseViewModel>) => {
            if (member) {
              const responseObj = new AddParentDialogResponseModel();
              responseObj.parent = member.data;
              responseObj.familyId = response.familyId;
              responseObj.isMemberNavigate = response?.isMemberNavigate;

              resolve(responseObj);
            }
          });
        } else
          resolve(null);
      }).catch(error => {
        reject(error);
      });
    });
  }

    openEditor(id: number, mode: EditorMode) {
      const data = {
        id: id,
        editorMode: mode,
        isUpdateHistory: false
      };
      this.notifierService.setCurrentEditor(`/editor/individualeditor`, data, null);
    }

  //This is used to create rootMember when get changed. This method has been overridden
  createRootMember(data: any) { }

  //This is to change root member.
  //Should set rootmember from report side before call this method.
  changeRootMember() {
    try {
      this.hideTooltip();
      this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, this.rootMember);
      this.rootMemberValue$.next(true);
    } catch (error) {
      console.log("Change root member error : " + error);
    }
  }

  // hide tooltip
  hideTooltip() {
    this.hideToolTip = true;
  }

  // Initialize ReportOptionsPopup option fields
  initReportOptions(){}

  // Re-init report after ReportOptions changed
  onOptionsValueChanged(changedData: ReportOptions){}

  /**
   * Display tooltip and position it on the clicked place of the report
   * @param eventXPosition X position of mouse click
   * @param eventYPosition Y position of mouse click
   * @param id selected memeber id
   */
  openToolTip(eventXPosition: number, eventYPosition: number, id: number){
    this.memberId = id;
    this.hideToolTip = false;
    var tooltip = this.hostElement.nativeElement.querySelector("#tooltip");
    tooltip.style.left = eventXPosition - 149 + 'px';
    tooltip.style.top = eventYPosition - 64 + 'px';
  }

  openIndividualEditor(){
    this.hideTooltip();
    this.notifierService.updateEvent(NotifierEvents.RootMemberChanged, this.rootMember);
    this.notifierService.setCurrentEditor(`/editor/individualeditor`, { id: this.memberId }, null);
  }
}
