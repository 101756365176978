<svg>
    <!-- This label shows when there is data -->
    <g *ngIf="label.status == 'active'" [attr.id]="label.displayIndex" transform="translate(0,-90)" >
            <!-- Enabled Leaves -->
        <g *ngIf="i%2 == 0" transform="translate(-60,49) scale(0.6 0.6)" class="custom-cursor-context-menu no-pointer-events">  
            <image height="115" width="115" href="assets/tree-editor/level_5/leave_5_bottom_color.svg" />
        </g> 

        <g *ngIf="i%2 == 1" transform="translate(-62,90) scale(0.6 0.6)" class="custom-cursor-context-menu no-pointer-events">  
            <image height="115" width="115" href="assets/tree-editor/level_5/leave_5_top_color.svg" />
        </g> 
        <image *ngIf="label.gender == 'M'" x="0" y="80" height="47" width="210" href="assets/tree-editor/level_5/lbl_level_5_male.svg" />
        <image *ngIf="label.gender == 'F'" x="0" y="80" height="47" width="210" href="assets/tree-editor/level_5/lbl_level_5_female.svg" />
        <image *ngIf="label.gender == 'U'" x="0" y="80" height="47" width="210" href="assets/tree-editor/level_5/lbl_level_5_uknown.svg" />
        <text *ngIf="label.gender == 'M'" transform="translate(20,110)"
        class='element-over-hand text-male male-color' (click)=changeRootPerson(label)>
        {{splitDisplayName[0]?.name}}</text>
        <text *ngIf="label.gender == 'F'" transform="translate(20,110)"
        class='element-over-hand text-female female-color' (click)=changeRootPerson(label)>
        {{splitDisplayName[0]?.name}}</text>
        <text *ngIf="label.gender == 'U'" transform="translate(20,110)"
        class='element-over-hand text-unknown unknown-color' (click)=changeRootPerson(label)>
        {{splitDisplayName[0]?.name}}</text>

    </g>

    <!-- This label shows when there is no data(Grey out label) --> 
    <g class="element-over-hand" *ngIf="label.status === 'disable'" [attr.id]="label.displayIndex" transform="translate(0,-90)" (click)="addParent(i, label)" >
        <!-- Disabled Leaves -->
        <g *ngIf="i%2 == 0" transform="translate(-60,49) scale(0.6 0.6)" class="custom-cursor-context-menu no-pointer-events">  
            <image height="115" width="115" href="assets/tree-editor/level_5/leave_5_bottom_grey.svg" />
        </g> 

        <g *ngIf="i%2 !== 0" transform="translate(-62,90) scale(0.6 0.6)" class="custom-cursor-context-menu no-pointer-events">  
            <image height="115" width="115" href="assets/tree-editor/level_5/leave_5_top_grey.svg" />
        </g> 
        <image x="0" y="80" height="47" width="210" href="assets/tree-editor/level_5/lbl_level_5_disable.svg" class="custom-cursor-context-menu no-pointer-events"/>
        <g>
        <text transform="translate(20,113)" fill="#c1c2bd" font-family="Arial" font-size="20" >+</text>
        <text *ngIf="i%2 == 0" transform="translate(42,110)" class="text-disable">{{"lbl_add_mother" | translate}}</text>
        <text *ngIf="i%2 !== 0" transform="translate(42,110)" class="text-disable">{{"lbl_add_father" | translate}}</text>
        </g>
    </g>

</svg>