import { Component, OnInit,OnDestroy } from '@angular/core';
import { DropMenuAction, ReportType, ReportStatus } from 'src/app/common/enums/enums';
import { DropMenu } from '../../common/drop-menu/models/DropMenu';
import { DropMenuItem } from '../../common/drop-menu/models/DropMenuItem';
import { MediaGalleryItem } from '../../common/media-gallery/models/media-gallery-item';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { ReportApiService } from 'src/app/services/API/report-api.service';
import { ReportBaseModel } from 'src/app/common/models/report/report-base-model';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { ParentCommunicatorService } from 'src/app/services/parent-communicator.service';
import { ParentMessageType } from 'src/app/common/models/ParentMessage';
import { MessageDialogService } from '../../common/message-dialog/services/message-dialog.service';
import { Subscription, interval } from 'rxjs';
import { TimeHelper } from 'src/app/common/helpers/time-helper';


@Component({
  selector: 'app-report-manager-main',
  templateUrl: './report-manager-main.component.html',
  styleUrls: ['./report-manager-main.component.scss']
})
export class ReportManagerMainComponent implements OnInit,OnDestroy {
  dropMenu: DropMenu;
  searchPhrase: string = "";
  reports : ReportBaseModel[] = [];
  private reportListSubscription: Subscription;

  constructor(private loadingService : LoadingService,
              private reportApiService : ReportApiService,
              private translateHandler : TranslateHandler,
              private parentCommunicatorService : ParentCommunicatorService,
              private timeHelper:TimeHelper,
              private messageDialogService : MessageDialogService) { 

              }
  mediaListItems : MediaGalleryItem[] = []; 
  ngOnInit(): void {
    this.showReportList();
    this.createDropMenu(null);   
    // Auto refresh function
    this.reportListSubscription = interval(5000).subscribe((val) => {
      this.getReportData(null);
    });
  }
  
  ngOnDestroy(): void {
    if (this.reportListSubscription) {
      this.reportListSubscription.unsubscribe();
    }
  }

  showReportList() {
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.getReportData(processId);
  }

  getReportData(processId : string){
    this.reportApiService.getReports().subscribe((response: ResponseModel<ReportBaseModel[]>) => {
      this.reports = response.data;
      // Map reports to mediaItems
      this.mediaListItems = this.reports.map(i => ({ id: 0, reference: i.id, 
                                                     caption: i.name, url:`/assets/images/report-manager/${ReportType[i.type]?.toLowerCase()}.svg`, 
                                                     isSelected: false,
                                                     isEditEnable:true, 
                                                     isDisabled: (i.status == ReportStatus.Pending || i.status == ReportStatus.Processing) ? true : false,
                                                     type : this.translateHandler.translate("lbl_" + ReportType[i.type]?.toLowerCase()),                                                     
                                                     size : ReportStatus.Success == i.status ? `${this.timeHelper.getDuration(i.createDate)} - ${this.getSizeWithExtension(i.size)}` : (i.status == ReportStatus.Pending || i.status == ReportStatus.Processing) ? this.translateHandler.translate("lbl_generating_file"): this.translateHandler.translate("lbl_failed"),
                                                     dropMenu: this.createDropMenu(i) }));                                                          
    }, (err) => {
      throw new CustomError("ReportManagerMainComponent => getReportList() : " + err, 911, false);
    }).add(()=>{     
      this.loadingService.hide(processId);
    });
  }


  createDropMenu(ref: ReportBaseModel) {
    let dropMenu = new DropMenu();
    dropMenu.type = (ref == null || ref.status == ReportStatus.Pending || ref.status == ReportStatus.Processing) ? 0: 1;
    dropMenu.ref = ref;
    dropMenu.menuItems = [
    <DropMenuItem>{ action: DropMenuAction.Delete, isEnabled: true, isVisible: true },
    <DropMenuItem>{ action: DropMenuAction.Download,  isEnabled: (ref == null || ref.status == ReportStatus.Failed) ? false : true, isVisible: true }
    ];
    return dropMenu;
  }

  dropMenuAction(event: any) {
    if (event.action == DropMenuAction.Delete) {
      var message = this.translateHandler.translate("cnf_delete_report_confirmation_message"); // Need to delete confirmation for the report
        this.messageDialogService.openDeleteConfirmation(message).subscribe((res: boolean) => {         
          if (res) {
            this.deleteReport(event.reference.id);
          }
        })
    }
  }

  deleteReport(reportId : string){
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);
    this.reportApiService.deleteReport(reportId).subscribe((response: ResponseModel<boolean>) => {
      if (response.data) {            
         let deleteObj = this.mediaListItems.find(s => s.reference == reportId);
         this.mediaListItems.splice(this.mediaListItems.indexOf(deleteObj), 1);
         this.loadingService.hide(processId);          
      }
    }, (err) => {
      throw new CustomError("ReportManagerMainComponent => Delete Report => dropMenuAction: " + err, 911, false);
    }).add(() => {
      this.loadingService.hide(processId);
    });
  }

  getSizeWithExtension(fileSize: number) {
    if (fileSize >= 1024)
      return `${(Math.floor(fileSize / 1024))} MB`
    else
      return `${(fileSize)} KB`

  }
  
  showNotImplemented() {
    this.parentCommunicatorService.send(
      ParentMessageType.ShowInformation,
      "not_implemented"
    );
  }

}
