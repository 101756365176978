import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Multimedia } from 'src/app/common/models/multimedia/multimedia';
import { MultimediaApiService } from 'src/app/services/API/multimedia-api.service';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { DomSanitizer } from '@angular/platform-browser';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { featureType, ImageOptions, PopupViewMode } from 'src/app/common/enums/enums';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { MessageDialogService } from '../../message-dialog/services/message-dialog.service';
import { ComponentBase } from '../../base/component-base';
import { NotifierV2Service } from 'src/app/services/notifier-v2.service';
import { CustomerFeaturesService } from 'src/app/services/API/customer-features.service';
import { CustomerFeature } from 'src/app/common/models/MemberAccess/CustomerFeature';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-image-colorizer',
  templateUrl: './image-colorizer.component.html',
  styleUrls: ['./image-colorizer.component.scss']
})
export class ImageColorizerComponent extends ComponentBase implements OnInit, OnDestroy {

  @Input() data;
  imageUrl: any = "assets/multimedia-module/colorize-default.png";
  coloredUrl : any;
  mediaFile: any;
  isPreview : boolean = true;
  errorMessage = "";
  colorizeStatus = false;
  isSaved: boolean = false;
  customerFeature: CustomerFeature;
  limit: number;
  originalMediaInfo: Multimedia;
  referenceMediaInfo : Multimedia;
  isAccessible = true;

  constructor(
    private mediaApiService: MultimediaApiService,
    private loadingService: LoadingService,
    private sanitizer: DomSanitizer,
    private translateHandler: TranslateHandler,
    private messageDialogService: MessageDialogService,
    private customerFeaturesService: CustomerFeaturesService, 
    notifierService: NotifierV2Service) {
      super(notifierService);
      this.customerFeature = new CustomerFeature();      
  }

  ngOnInit(): void {   
    this.loadImage(this.data.mediaInfo.id).then((response : ResponseModel<Multimedia>)=>{      
      this.originalMediaInfo = response.data;     
    });
    if(this.data.mediaInfo.referenceId != 0){
      this.loadImage(this.data.mediaInfo.referenceId).then((response : ResponseModel<Multimedia>)=>{      
        this.referenceMediaInfo = response.data;
        this.imageUrl = this.referenceMediaInfo.originalMediaUrl;
        this.isSaved = true;   
        this.isPreview = false;    
      });     
    }    
   
    this.checkAccessibility();
  }

  loadImage(id : number) : Promise<ResponseModel<Multimedia>>{
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId); 
    return new Promise((resolve,reject)=>{
      this.mediaApiService.getMultimediaById(id).subscribe((response: ResponseModel<Multimedia>) => {
         resolve(response)
      }, (err) => {
        return new CustomError("Multimedia Editor component => ngOnint() : " + err, 911, false)    
      }).add(() => {
        this.loadingService.hide(processId);
      });
    })   
  }


  add() {
    //add suffix to the colorized image filename
    this.isSaved = false;
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);    
  
    if (this.data.mediaInfo.referenceId == 0) {
      this.referenceMediaInfo = this.originalMediaInfo;
      let fileNames = this.originalMediaInfo.fileName.split('.');
      this.referenceMediaInfo.fileName = fileNames[0] + "_col" + '.' + fileNames[1];
      this.mediaFile.name = fileNames[0] + "_col" + '.' + fileNames[1];
      this.referenceMediaInfo.options = [ImageOptions.IsColorized];
      this.referenceMediaInfo.referenceId = this.originalMediaInfo.id;

      let formData = this.getFormDataInfo(this.referenceMediaInfo);
      //Create multimedia API
      this.mediaApiService.createMultimedia(formData).subscribe((response: ResponseModel<Multimedia>) => {
        this.referenceMediaInfo = response.data;
        this.data.mediaInfo.referenceId = this.referenceMediaInfo.id;
        this.originalMediaInfo.referenceId =this.referenceMediaInfo.id
        this.isSaved = true;        
        this.isDirty = false;
        this.loadingService.hide(processId);
      }, (err) => {
        this.loadingService.hide(processId);
        throw new CustomError("Multimedia Editor component => add() : " + err, 911, false);        
      })
    }
    else {
      this.referenceMediaInfo.referenceId = this.originalMediaInfo.id;
      this.mediaFile.name = this.referenceMediaInfo.fileName;
      let formData = this.getFormDataInfo(this.referenceMediaInfo);
      //Update multimedia API
      this.mediaApiService.updateMultiMedia(formData).subscribe((response: ResponseModel<Multimedia>) => {
        this.referenceMediaInfo = response.data;
        this.data.mediaInfo.referenceId = this.referenceMediaInfo.id;
        this.isSaved = true;       
        this.isDirty = false;
        this.loadingService.hide(processId);
      }, (err) => {
        this.loadingService.hide(processId);
        throw new CustomError("Multimedia Editor component => add() : " + err, 911, false);        
      });
      
    }
  }

  getFormDataInfo(mulitmedia : Multimedia) { // set formdata with colorized image file and data
    let formData = new FormData();
    formData.append('files', this.mediaFile, this.mediaFile.name);
    formData.append('basicInfo', JSON.stringify(mulitmedia));
    return formData;
  }

  colorize() {
    let optionObj = this.customerFeature.getOptions();
    if(this.customerFeature.allowedCount > (optionObj.limit ?? 0) || this.customerFeature.allowedCount == -1)
      {
      this.isDirty = false;
      this.colorizeStatus = false;
      this.errorMessage = "";
      let Imageurl = this.originalMediaInfo.originalMediaUrl;
      //map the Imageurl with API supported url type
      let urlObject: { [key: string]: string } = {
        "https://ftwebcore.embla.no": "http://192.168.100.8:4202",
        "http://dev.ftwebcore.embla.no": "http://192.168.100.8:4203",
        "http://localhost:5000": "http://192.168.100.8:4203",
      }
      //replace the Imageurl with API supported url
      for (let key in urlObject) {
        let value = urlObject[key];
        if (Imageurl.includes(key)) {
          Imageurl = Imageurl.replace(key, value);
        }
      }
  
      let apiData = {
        url: Imageurl,
        render_factor: 34
      } 
      let processId = GuidGenerator.generate();
      this.loadingService.show(processId);
      //Colorize API call
      this.mediaApiService.colorizeImage(apiData).subscribe((res) => {
        this.mediaFile = res;
        let unsafeImageUrl = URL.createObjectURL(res);
        this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
        this.loadingService.hide(processId);
        this.colorizeStatus = true;
        this.isDirty = true;
        this.isPreview = false;    
        //this.setColorizeLimit();       
      },
        (error) => {
          this.errorMessage = error.status.toString();
          console.error(error);
          this.loadingService.hide(processId);
          this.colorizeStatus = false;
        });
    }else{
      this.isAccessible = false;
    }    
  }

  setColorizeLimit() {
    let optionObj = this.customerFeature.getOptions();   
    optionObj.limit = optionObj.limit ?? 0
    if (optionObj.limit == 0) {
      optionObj.limit = optionObj.limit + 1;
      this.customerFeature.setOption(optionObj);
      this.customerFeaturesService.create(this.customerFeature).subscribe((response: ResponseModel<CustomerFeature>) => {      
        this.customerFeature = Object.assign(this.customerFeature,response.data);        
      },
        (error) => {
          this.errorMessage = error.status.toString();
          console.error(error);
        });
    } else {
      optionObj.limit = optionObj.limit + 1;
      this.customerFeature.setOption(optionObj);
      this.customerFeaturesService.update(this.customerFeature).subscribe((response: ResponseModel<CustomerFeature>) => {
       this.customerFeature = Object.assign(this.customerFeature,response.data);
      },
        (error) => {
          this.errorMessage = error.status.toString();
          console.error(error);
        });
    }
  }

  checkAccessibility(){
    this.customerFeaturesService.getFeatureAccessById(featureType.colorizer).subscribe((response: ResponseModel<CustomerFeature>) => {
     this.customerFeature = Object.assign(this.customerFeature,response.data);
     let optionObj = this.customerFeature.getOptions();
      this.limit = optionObj.limit ?? 0;
      if(!this.customerFeature.isAccessEnabled){
        //show upgrade message
        this.isAccessible = false;
        
      }else{
        if(this.customerFeature.allowedCount == -1){ // -1 => premium
          this.isAccessible = true;
        }
        else if(this.limit >= this.customerFeature.allowedCount){
          //show upgrade message
          this.isAccessible = false;
         }
      }  
    }, (err) => {
      throw new CustomError("Multimedia Editor component => ngOnint() : " + err, 911, false);
    });
  }

  download(){  
    window.open(this.referenceMediaInfo.originalMediaUrl, "_blank");
  }

  cancel() {
    if (this.isDirty) { // confirmation message
      let processId = GuidGenerator.generate();
      this.loadingService.show(processId);
      var message = this.translateHandler.translate("cnf_cancel_without_saving_colorized_photo");
      this.loadingService.hide(processId);
      this.messageDialogService.openUnSavedChangeConfirmation(message).subscribe((res: boolean) => {
        if (res) {
          this.data.viewMode = PopupViewMode.GalleryView;
        }
      });
    } else {
      this.data.viewMode = PopupViewMode.GalleryView;
    }
  }

  public canChange(): boolean {
    return !this.isDirty;
  }

  public goToWebShop(){
    window.open(environment.WEB_SHOP_URL, "_blank");
  }

  public goToGallery(){
    this.data.viewMode = PopupViewMode.GalleryView;
  }
}

