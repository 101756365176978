import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomError } from 'src/app/common/helpers/custom-error';
import { GuidGenerator } from 'src/app/common/helpers/guid-generator';
import { TranslateHandler } from 'src/app/common/helpers/translate-handler';
import { Multimedia } from 'src/app/common/models/multimedia/multimedia';
import { ResponseModel } from 'src/app/common/models/responseModel';
import { SourceBase } from 'src/app/common/models/source/source-base';
import { SourceComponentModel } from 'src/app/common/models/source/source-component-model';
import { SourceCitationViewModel } from 'src/app/common/models/sourceCitation/source-citation-view-model';
import { SourceAPIService } from 'src/app/services/API/source-api.service';
import { DialogService } from 'src/app/services/UI/dialog.service';
import { LoadingService } from 'src/app/services/UI/loading.service';
import { DropMenuAction, ReferenceType, MultimediaTypes, PopupViewMode } from 'src/app/common/enums/enums';
import { MessageDialogService } from '../../message-dialog/services/message-dialog.service';
import { EventViewBase } from '../../events/models/event-view-base';
import { EventApiService } from 'src/app/services/API/event-api.service';

@Component({
  selector: 'app-source-editor',
  templateUrl: './source-editor.component.html',
  styleUrls: ['./source-editor.component.scss']
})
export class SourceEditorComponent implements OnInit {
  constructor(
    private dialogService:DialogService, 
    private translateHandler: TranslateHandler,
    private loadingService: LoadingService,
    private sourceApiService: SourceAPIService,
    private messageDialogService: MessageDialogService,
    private eventApiService: EventApiService
  ) { }
  
  @Input() data;
  @Output() onCompleted = new EventEmitter<any>();

  titleTxt: any;
  sourceComponentModel: SourceComponentModel;
  eventList: EventViewBase[];
  defaultCarouselImage: string = "/assets/images/icons/source.svg";
  eventConfig: any;

  responseData : {sourceCitationInfo : SourceCitationViewModel , deleteSourcesIds : number[] } = new Object() as any; 
  
  ngOnInit(): void {
    this.sourceComponentModel = new SourceComponentModel();
    this.sourceComponentModel.multimedias = new Array<Multimedia>();
    this.responseData.deleteSourcesIds = new Array<number>(); 
    this.eventConfig = {
      message : this.translateHandler.translate('err_no_event_for_resource', [this.translateHandler.translate('lbl_source').toLowerCase()]),
      referenceType : ReferenceType.Source
    }

    if (this.data.response) {
      this.responseData = this.data.response;
    }

    this.titleTxt = this.translateHandler.translate('lbl_link_media', [this.translateHandler.translate("lbl_source").toLowerCase(), this.data.titleText]);

    if (this.data.editSourceId > 0) {
      // Call Get API
      let processId = GuidGenerator.generate();
      this.loadingService.show(processId);

      this.sourceApiService.getSourceById(this.data.editSourceId).subscribe((response: ResponseModel<SourceComponentModel>) => {
        this.sourceComponentModel = response.data;
        this.getEventForSource();

      }, (err) =>{
        throw new CustomError("SourceEditorComponent => getSourceById() : " + err, 911, false);
      }).add(()=>{     
        this.loadingService.hide(processId);
      });
    }
    else{
      this.sourceComponentModel.id = 0;
    }
  }

  getEventForSource(){
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);

    this.eventApiService.getEventForSourceById(this.data.editSourceId).subscribe((response: ResponseModel<EventViewBase[]>) => {
      this.eventList = response.data;
      // this.data.editorMode = 3;
     }, (err) =>{
      throw new CustomError("SourceEditorComponent => getEventForSource() : " + err, 911, false);
    }).add(()=>{ 
      this.loadingService.hide(processId); 
    });
  }

  carouselActionClicked($event) {
    let selectedMultimedia = $event.reference;
    let action = $event.action;

    if(action === DropMenuAction.Edit){
      this.addImage(selectedMultimedia.id, 2);
    }
    if(action === DropMenuAction.Unlink){   
      let unlinkMessage = this.translateHandler.translate("cnf_unlink_image_from_source_confirmation_message");
      this.messageDialogService.openUnlinkfirmation(unlinkMessage).subscribe((res: boolean) => {
        if (res) {
          this.unlinkImage(selectedMultimedia);
        }
      });
    }
    if(action === DropMenuAction.SetAsPrimary){
      this.setImageAsPrimary(selectedMultimedia);
    }
    if(action === DropMenuAction.Upload){
      this.addImage(0, 2);
    }
    if(action === DropMenuAction.Gallery){
      this.addImage(0, 1);
    }
  }

  addImage(mediaId: any, viewMode: any){
    this.dialogService.open("Multimedia",
      {
        id: this.sourceComponentModel.multimedias?.find(m => m.isPrimary)?.id ?? 0,
        reference: {
          id: this.sourceComponentModel.id, //Source Id
          type: ReferenceType.Source
        },
        title: this.translateHandler.translate('lbl_link_media', [this.translateHandler.translate("lbl_media"),this.translateHandler.translate("lbl_source")]), // media for place for + <name>        
        mediaType: [MultimediaTypes.PHOTO],
        viewMode: viewMode,
        editMediaId: mediaId,
        isStandAlone: false
      }
    ).subscribe((response) => {
      if(response.data){
        response.data.deleteMedias.forEach(element => {
          let index = this.sourceComponentModel.multimedias.findIndex(m=> m.id === element.id);
          if(index >= 0){
            this.sourceComponentModel.multimedias.splice(index, 1);
            this.setNextImageAsPrimary(index);
          }
        });
        if(response.data.newMedia){
          response.data.newMedia.isNewlyAdded = true;
          let mediaIndex = this.sourceComponentModel.multimedias?.findIndex(t => t.id == response.data.newMedia.id) ?? -1;
          !this.sourceComponentModel.multimedias? this.sourceComponentModel.multimedias = new Array<Multimedia>() : null;
          if (mediaIndex < 0)
            this.sourceComponentModel.multimedias.push(response.data.newMedia);
          else {              
            this.sourceComponentModel.multimedias[mediaIndex] = response.data.newMedia;             
          }
          // When adding new image to image carousel , set this as primary
          this.sourceComponentModel.multimedias.map(m => m.isPrimary = m.id === response.data.newMedia.id);
        }
      }
    });
  }

  unlinkImage(selectedMultimedia: Multimedia){
    if (selectedMultimedia != null) {
      let index = this.sourceComponentModel.multimedias.findIndex(i => i.id === selectedMultimedia.id);
      this.sourceComponentModel.multimedias.splice(index, 1);
      this.setNextImageAsPrimary(index);
    }
  }

  setImageAsPrimary(selectedMultimedia: Multimedia) {
    if (selectedMultimedia != null) {
      if (!selectedMultimedia.isPrimary) {
        this.sourceComponentModel.multimedias.map(m => m.isPrimary = m.id === selectedMultimedia.id);
      }
    }
  }

  setNextImageAsPrimary(index: number){
    let isPrimaryImage = this.sourceComponentModel.multimedias?.find(t => t.isPrimary == true) ?? null;
    if(!isPrimaryImage && this.sourceComponentModel.multimedias?.length > 0){
      if(this.sourceComponentModel.multimedias[index > 0 ? (index - 1): 0].isNonProfile){
        this.sourceComponentModel.multimedias.find(m=>m.mediaType == MultimediaTypes.PHOTO && !m.isNonProfile).isPrimary = true;
      }else{
        this.sourceComponentModel.multimedias[index > 0 ? (index - 1): 0].isPrimary = true;
      }
    }
  }

  addSource(){
    let processId = GuidGenerator.generate();
    this.loadingService.show(processId);

    if (this.data.editSourceId == 0) {
      // Create Source API
      this.sourceApiService.createSource(this.sourceComponentModel).subscribe((response: ResponseModel<SourceComponentModel>) => {
        this.loadingService.hide(processId);
        this.sourceComponentModel.id = response.data.id;
        this.completeAction();
      }, (err)=>{
        throw new CustomError("SourceEditorComponent => createSource() : " + err, 911, false);
        }).add(()=>{     
          this.loadingService.hide(processId);
      });
    }
    else{
      // Update Source API
      this.sourceApiService.updateSource(this.sourceComponentModel).subscribe((response: ResponseModel<SourceComponentModel>) => {
        this.completeAction();
      }, (err) => {
        throw new CustomError("SourceEditorComponent => updateSource() : " + err, 911, false);
      }).add(()=>{     
        this.loadingService.hide(processId);
      });
    }
  }

  addSourceCitation(){   
    this.responseData.sourceCitationInfo = new SourceCitationViewModel();
    this.responseData.sourceCitationInfo.sourceBase = new SourceBase();   
    //let source =  this.sourceComponentModel as SourceBase;
    //sourceCitation.sourceBase = new SourceBase();
    this.responseData.sourceCitationInfo.sourceBase =  this.sourceComponentModel as SourceBase;
    this.responseData.sourceCitationInfo.sourceBase.mediaUrl = this.sourceComponentModel?.multimedias.length > 0 ? this.sourceComponentModel?.multimedias?.find(el=>el.isPrimary==true).mediaUrl : this.defaultCarouselImage;
    this.dialogService.open("SourceCitation",{
      id: 0,
      responseData: this.responseData,
      title: this.translateHandler.translate('lbl_link_media', [this.translateHandler.translate("lbl_citation").toLowerCase(), this.data.titleText]) // Todo : Need to translate and set title
    }).subscribe( response => {      
      if(response.data){     
        response.data.eventId = this.data.reference.eventId;           
        this.onCompleted.next({data : response.data});        
     } 
    })
  }

  completeAction(){
    if(this.data.options.isSwitchToParent){
      this.addSourceCitation();        
    }else{
      this.data.viewMode = PopupViewMode.GalleryView;
      this.data.editSourceId = 0;
      this.onCompleted.unsubscribe();
    } 
  }

  cancel(){
    if(this.data.options.isSwitchToParent){
      this.onCompleted.next({});
    }   
    this.data.viewMode = PopupViewMode.GalleryView;
    this.data.editSourceId = 0;
    this.onCompleted.unsubscribe();    
  }
}
