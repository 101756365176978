<div class="ft-form">
  <div class="content">
          <div class="ft-form-row" style="margin-bottom: 0px;">
            <span class="title">{{title}}</span>
          </div>
          <span class="label-grey-base-normal" style="margin-bottom: 25px; position: relative;">
            <div style="position: absolute;" *ngIf="setEnablity()">{{"lbl_you_have_selected" | translate}} {{selectedIndividualCaption}}</div>
          </span>    
          <div class="ft-form-row">
            <div class="ft-form-row col1-v100">
              <div class="ft-form-input">
                <div class="icon-input-container">
                  <input class ="icon-input" placeholder="{{'ph_search_member' | translate}}"
                    type="text" [(ngModel)]="searchPhrase" (change)="onSearchChange()"  >
                  <div class="icon-input-wrap">                      
                      <img src="/assets/images/icons/search.svg" width= "30px" height="30px">                      
                  </div>                  
                </div>
              </div>
            </div>            
          </div>         
          <app-media-gallery style="height:calc(100% - 100px)" [items]="mediaItems" [searchPhrase]="searchPhrase" [isStandAlone]="true" (action)="dropMenuAction($event)" ></app-media-gallery>          
        </div>        
        <div class="footer">    
          <button *ngIf="data.options.isAddBtn" class = "ft-btn" [ngClass]="{'ft-btn-active':setEnablity(),'ft-btn-inactive':!setEnablity()}" [disabled]="!setEnablity()" (click)="addIndividual()">{{"lbl_add" | translate}}</button>
          <button *ngIf="data.options.isCloseBtn" class="ft-btn ft-btn-active-not-selected" (click)="close()">{{"lbl_cancel" | translate}}</button>
        </div>
      </div>
  