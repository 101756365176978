<div style="display: flex;flex-direction: row;width: 100%;height: 100%;">
    <div class="dialog-title-container">
      <div class="dialog-title-text">{{"lbl_tree_settings" | translate}}</div>
    </div>
    <div class="tab-container app-dark-bg">
            <div class="tabs">
                <a [class.active]="getViewMode() == 1" (click)="changeViewMode(1)">{{"lbl_family_tree_details"| translate}}</a>
                <a [class.active]="getViewMode() == 2" >{{"lbl_import"|translate}}</a>
                <a [class.active]="getViewMode() == 3" (click)="changeViewMode(3)">{{"lbl_export"| translate}}</a>
            </div>
            <div class="tab-forms app-light-bg">                
                <app-view-project-details-option *ngIf="getViewMode() === 1" [data]="data"></app-view-project-details-option>
                <app-family-project-export-option *ngIf="getViewMode() === 3" [data]="data"></app-family-project-export-option>
            </div>            
    </div>
</div>
  