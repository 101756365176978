//ng build --configuration="test"

export const environment = {
    VERSION : "10.2.0",
    production: false,
    API_URL_CORE: 'https://dev.api.ft.embla.no',    
    IFRAME_URL:'https://dev.ft.embla.no',
    LEGACY_API_URL: 'https://dev.ftv10.embla.no/Client',
    WEB_SHOP_URL: "https://dev.shop.embla.no/",
    WEB_SHOP_MEMBERSHIP_URL: "https://dev.shop.embla.no/{0}/medlemskap",
    WEB_SHOP_V10_MEMBERSHIP_URL: "https://dev.shop.embla.no/{0}/versjon10ogmedlemskap",
    EMBLA_NO_URL: "https://dev.embla.no",
    FT_HELP_URL: "http://dev.help.embla.no",
    FT_AUTH_URL: "https://dev.auth.embla.no/",
    LOADER_TIMEOUT_MINUTES: 3,
    RELOAD_FREQUENCE: 10,
    ACCEPTED_IMAGE_FILE_TYPE: ["jpg","jpeg","bmp","tiff","png"],
    ACCEPTED_AUDIO_FILE_TYPE: ["wav","mp3","mpeg"],
    ACCEPTED_VIDEO_FILE_TYPE: ["mp4","avi"],
    ACCEPTED_DOCUMENTS_FILE_TYPE: ["msword","rtf","txt","pdf","plain", "doc"],
    ACCEPTED_RESOLUTION: ["1920x1080","1680x1050","1368x768","1024x768","1280x800","1670x940"],
    ACCEPTED_IMAGE_FILE_SIZE: 5242880,
    ACCEPTED_VIDEO_FILE_SIZE: 15242880,
    CROP_SQURE_WIDTH: 260,
    DEFAULT_CULTURE: 'no',
    BREADCRUMB_SIZE: 3,
    HISTORY_LIST_SIZE: 10,
    CHROME_DOWNLOAD_URL: "https://www.google.com/chrome/",
    FAMILY_PROJECT_RELOAD_TIMEOUT: 5000, // 5 seconds
    BOOK_REPORT_DELAY_INFO_POPUP_TIMEOUT: 5000,
    TRIAL_EXPIRE_WARNING_RANGE: {
      "range_1": 30,
    },
    GEOCODE :{
      "url": "https://api.mapbox.com/geocoding/v5/mapbox.places/",
      "dataFormat": ".json",
      "mapboxAccessToken":"pk.eyJ1IjoiY2hhbmRpbWFsdyIsImEiOiJjbGYyMmNjazcwZWJ1M3NsajUzdm1hOGxsIn0.CY1vLBnEq-TOQcJ3qO__PA",
      "responseType": "Mapbox-Json",
      "googleAccessToken": "AIzaSyCTRZQGY2xZX1EoSoyo8eZz72JZE83uj_Q"
    },
    PLACE: {
      "openMapDelayTimeOut": 5500
    },
    BOOK_REPORT:{
      "dynamicPagePath":"D://Data-FT//Report//Test//Html",
      "templatePath":"D://Data-FT//Report//Test//Templates"
    },
    DOMAIN:'embla.no',
    DUPLICATE_VALIDATION_EVENTS: ["BIRTH", "DEATH","MARRIAGE"]
};
